import { jsxs, jsx } from 'react/jsx-runtime';
import { Warning24Filled, Dismiss24Filled, Checkmark24Filled, ArrowSync20Filled } from '@fluentui/react-icons';
import { useTheme, Typography } from '@mui/material';
import ProgressIndicatorArrow40 from '../icons/ProgressIndicatorArrow40.js';
import { Box } from './Box.js';

var DisposalRequestProgressStatus;
(function (DisposalRequestProgressStatus) {
    DisposalRequestProgressStatus["BLOCKED"] = "Blocked";
    DisposalRequestProgressStatus["READY"] = "Ready";
    DisposalRequestProgressStatus["IN_PROGRESS"] = "In Progress";
    DisposalRequestProgressStatus["PENDING"] = "Pending";
    DisposalRequestProgressStatus["COMPLETE"] = "Complete";
    DisposalRequestProgressStatus["REJECTED"] = "Rejected";
})(DisposalRequestProgressStatus || (DisposalRequestProgressStatus = {}));
const getStepIndicator = (status, withWarning, sequenceNumber, isBlocked, theme) => {
    switch (status) {
        case DisposalRequestProgressStatus.PENDING:
            return {
                display: sequenceNumber,
                backgroundColor: theme.palette.info[600],
                progressIndicatorArrowColor: theme.palette.info.light,
            };
        case DisposalRequestProgressStatus.READY:
            return {
                display: sequenceNumber,
                backgroundColor: withWarning
                    ? theme.palette.warning[400]
                    : theme.palette.primary[700],
                progressIndicatorArrowColor: theme.palette.info[600],
            };
        case DisposalRequestProgressStatus.IN_PROGRESS:
            return {
                display: jsx(ArrowSync20Filled, {}),
                backgroundColor: withWarning
                    ? theme.palette.warning[400]
                    : theme.palette.primary[700],
                progressIndicatorArrowColor: theme.palette.info.light,
            };
        case DisposalRequestProgressStatus.COMPLETE:
            return {
                display: jsx(Checkmark24Filled, {}),
                backgroundColor: withWarning
                    ? theme.palette.warning[400]
                    : theme.palette.primary[700],
                progressIndicatorArrowColor: theme.palette.info[600],
            };
        case DisposalRequestProgressStatus.REJECTED:
            return {
                display: jsx(Dismiss24Filled, {}),
                backgroundColor: theme.palette.warning[900],
                progressIndicatorArrowColor: theme.palette.info.light,
            };
        case DisposalRequestProgressStatus.BLOCKED:
            return {
                display: jsx(Warning24Filled, {}),
                backgroundColor: theme.palette.warning[900],
                progressIndicatorArrowColor: theme.palette.info.light,
            };
        default:
            return null;
    }
};
const DisposalRequestProgressIndicatorStatus = ({ progressStatus, sequenceNumber, label, withWarning, isBlocked, isLast = false, ...rest }) => {
    const theme = useTheme();
    const { display, backgroundColor, progressIndicatorArrowColor } = getStepIndicator(progressStatus, withWarning, sequenceNumber, isBlocked, theme);
    return (jsxs(Box, { direction: 'row', height: '3rem', alignItems: 'center', gap: 'medium', background: 'none', className: 'progress-indicator-status', children: [jsx(Box, { style: {
                    backgroundColor,
                    color: theme.palette.common.white,
                    borderRadius: 60,
                }, width: '3rem', height: '3rem', justifyContent: 'center', alignItems: 'center', ...rest, children: display }), label && (jsx(Typography, { variant: 'body2', sx: { color: theme.palette.info.dark }, width: isLast ? '4rem' : '3.375rem', children: label })), !isLast && (jsx(ProgressIndicatorArrow40, { style: { color: progressIndicatorArrowColor } }))] }));
};

export { DisposalRequestProgressIndicatorStatus, DisposalRequestProgressStatus };
