import { jsx } from 'react/jsx-runtime';
import { Tooltip, Button as Button$1 } from '@mui/material';
import { sizes } from '../../themes/sizes.js';

//create super component for mui button component to obfuscate library
const Button = ({ shape = 'default', variant = 'contained', size = 'medium', border, tooltip, tooltipPlacement, ...props }) => {
    const sx = {
        ...(border && { border: '0.125rem solid currentcolor !important' }),
        ...(shape === 'round' && {
            padding: 0,
            minWidth: 0,
            width: `${sizes.rounddimension[size]} !important`,
            height: `${sizes.rounddimension[size]} !important`,
            borderRadius: '50%',
        }),
        ...(shape === 'square' && {
            padding: 0,
            minWidth: 0,
            width: `${sizes.dimension[size]} !important`,
            '& span': { margin: '0 !important' },
        }),
        ...props.sx,
    };
    const _component = () => (jsx(Button$1, { variant: variant, size: size, ...props, sx: sx }));
    return tooltip ? (jsx(Tooltip, { title: tooltip, placement: tooltipPlacement, arrow: true, children: _component() })) : (_component());
};

export { Button };
