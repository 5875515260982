var TypeKind;
(function (TypeKind) {
    TypeKind["Primitive"] = "Primitive";
    TypeKind["Enum"] = "Enum";
    TypeKind["Entity"] = "Entity";
})(TypeKind || (TypeKind = {}));
var PrimitiveDataType;
(function (PrimitiveDataType) {
    PrimitiveDataType["String"] = "String";
    PrimitiveDataType["Boolean"] = "Boolean";
    PrimitiveDataType["Date"] = "Date";
    PrimitiveDataType["Double"] = "Double";
    PrimitiveDataType["Int64"] = "Int64";
    PrimitiveDataType["Int32"] = "Int32";
    PrimitiveDataType["DateTime"] = "DateTime";
})(PrimitiveDataType || (PrimitiveDataType = {}));
const isMultiline = (details) => details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.String &&
    details.IsText;
const isSingleLine = (details) => details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.String &&
    !details.IsText;
const isBoolean = (details) => details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.Boolean;
const isDate = (details) => details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.Date;
const isDateTime = (details) => details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.DateTime;
const isEnum = (details) => details?.TypeKind === TypeKind.Enum;
const isDouble = (details) => (details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.Double);
const isInteger = (details) => (details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.Int32) ||
    (details?.TypeKind === TypeKind.Primitive &&
        details?.PrimitiveDataType === PrimitiveDataType.Int64);
const isEntity = (details) => details?.TypeKind === TypeKind.Entity;
const getQueryProps = () => {
    const queryString = window.location.search.substring(1);
    if (queryString.includes('userObj=')) {
        const queryStringArray = queryString.split('=');
        const userObjString = queryStringArray[1];
        try {
            return userObjString
                ? JSON.parse(decodeURIComponent(userObjString))
                : null;
        }
        catch (error) {
            console.error('Error parsing JSON:', error);
            return null;
        }
    }
    return null;
};

export { PrimitiveDataType, TypeKind, getQueryProps, isBoolean, isDate, isDateTime, isDouble, isEntity, isEnum, isInteger, isMultiline, isSingleLine };
