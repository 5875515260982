import { jsxs, jsx } from 'react/jsx-runtime';
import { ChevronDownFilled } from '@fluentui/react-icons';
import { Menu, Grow, MenuItem } from '@mui/material';
import { memo, useState } from 'react';
import { Box } from '../../Box.js';
import { Button } from '../../button/Button.js';

const PageSizeDropdown = ({ options, onChange, pageSize = 30, }) => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    return (jsxs(Box, { background: 'none', direction: 'row', style: {
            flex: '0 1 min-content',
        }, "data-testid": 'page-size-dropdown', children: [jsxs(Button, { variant: 'text', endIcon: jsx(ChevronDownFilled, {}), color: 'primary', onClick: (e) => setMenuAnchor(e.currentTarget), style: {
                    whiteSpace: 'nowrap',
                }, children: ["Show ", pageSize, " results per page"] }), jsx(Menu, { open: Boolean(menuAnchor), anchorEl: menuAnchor, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'bottom', horizontal: 'left' }, onClose: () => {
                    setMenuAnchor(null);
                }, TransitionComponent: Grow, MenuListProps: { className: 'MuiMenu-list-flat' }, children: options?.map((option, index) => [
                    jsx(MenuItem, { onClick: () => {
                            setMenuAnchor(null);
                            onChange && onChange(option);
                        }, children: option }, index),
                ]) })] }));
};
const MemoizedPageSizeDropdown = memo(PageSizeDropdown);

export { MemoizedPageSizeDropdown, PageSizeDropdown };
