import { jsx } from 'react/jsx-runtime';
import { ChevronDownFilled } from '@fluentui/react-icons';
import { Select, OutlinedInput, MenuItem, Typography } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { Tooltip } from './Tooltip.js';

const FilterDropdown = ({ value, onChange, menuOptions, }) => {
    return (jsx(Select, { value: value, onChange: onChange, variant: 'outlined', input: jsx(OutlinedInput, { notched: false }), IconComponent: ChevronDownFilled, sx: {
            width: '12.75rem',
            margin: '0 1.75rem 0 0',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        }, MenuProps: { classes: { paper: 'filter-dropdown' } }, children: menuOptions?.map((option, index) => {
            const dataRef = useRef(null);
            const [hasTooltip, setHasTooltip] = useState();
            useEffect(() => {
                const containerWidth = dataRef.current?.clientWidth;
                const textWidth = dataRef.current?.scrollWidth;
                if (textWidth > containerWidth)
                    setHasTooltip(true);
                else
                    setHasTooltip(false);
            }, [option]);
            return option
                ? [
                    jsx(MenuItem, { value: option, children: jsx(Tooltip, { title: hasTooltip ? option : '', arrow: true, placement: 'top', children: jsx(Typography, { variant: 'body2', ref: dataRef, children: option }) }) }, index),
                ]
                : null;
        }) }));
};

export { FilterDropdown };
