import { useState, useEffect } from 'react';
import '../services/requestApi.js';
import '../services/userApi.js';
import { useGetBusinessTypePropertiesPanelValuesQuery } from '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { propGroupsMapping, displayPropsMapping, editablePropsMapping, transformPropertiesOfObject, createTree } from '../components/item/ItemBusinessTypeUtil.js';

const useBusinessTypePropertiesPanel = ({ item, treeGridProperties, }) => {
    const typeDefId = item?.TypeDefId;
    const itemId = item?.ID;
    const businessTypeId = item?.BusinessTypeId;
    const [businessTypeTreeGridData, setBusinessTypeTreeGridData] = useState([]);
    // Get the Business Object value.
    const { data } = useGetBusinessTypePropertiesPanelValuesQuery({ typeDefId, itemId }, { skip: !typeDefId || !itemId || !businessTypeId });
    useEffect(() => {
        if (!businessTypeId || !data || !treeGridProperties)
            return;
        if (treeGridProperties?.TypeData?.value && data?.BusinessObject) {
            treeGridProperties.TypeData.value.forEach((typeData) => {
                // Prop Groups
                if (typeData.PropLinkGroups && typeData.PropLinkGroups.value) {
                    propGroupsMapping[typeData.TreeDataTypeDef.ID] = typeData.PropLinkGroups.value;
                }
                else {
                    propGroupsMapping[typeData.TreeDataTypeDef.ID] = [];
                }
                // Display Props
                if (typeData.DisplayProps && typeData.DisplayProps.value) {
                    displayPropsMapping[typeData.TreeDataTypeDef.ID] = typeData.DisplayProps.value;
                }
                else {
                    displayPropsMapping[typeData.TreeDataTypeDef.ID] = [];
                }
                // Editable PropDefs (ordered by TypeKind and then Caption)
                const propsOrdered = [];
                if (typeData.EditablePropDefs &&
                    typeData.EditablePropDefs.value &&
                    typeData.EditablePropDefs.value.length > 0) {
                    const editablePropDefs = typeData.EditablePropDefs.value;
                    const primitiveProps = editablePropDefs.filter((prop) => prop.TypeKind === "Primitive");
                    primitiveProps.sort((a, b) => a.Caption.localeCompare(b.Caption));
                    propsOrdered.push(...primitiveProps);
                    const enumProps = editablePropDefs.filter((prop) => prop.TypeKind === "Enum");
                    enumProps.sort((a, b) => a.Caption.localeCompare(b.Caption));
                    propsOrdered.push(...enumProps);
                    const entityProps = editablePropDefs.filter((prop) => prop.TypeKind === "Entity");
                    entityProps.sort((a, b) => a.Caption.localeCompare(b.Caption));
                    propsOrdered.push(...entityProps);
                }
                editablePropsMapping[typeData.TreeDataTypeDef.ID] = propsOrdered;
            });
            const flatArray = [];
            transformPropertiesOfObject(flatArray, data.BusinessObject, 0);
            const treeGridData = createTree(flatArray);
            setBusinessTypeTreeGridData(treeGridData);
        }
    }, [data, businessTypeId, treeGridProperties]);
    return { businessTypeTreeGridData };
};

export { useBusinessTypePropertiesPanel };
