import 'react/jsx-runtime';
import SvgDisposal from '../icons/Disposal.js';
import SvgDisposalApproval from '../icons/DisposalApproval.js';
import SvgImc from '../icons/Imc.js';
import SvgAnalytics from '../icons/Analytics.js';
import SvgAnalyticsAdmin from '../icons/AnalyticsAdmin.js';
import SearchApp from '../icons/SearchApp.js';

// These need to be lowercase
const appLogos = {
    disposalapproval: SvgDisposalApproval,
    disposal: SvgDisposal,
    imc: SvgImc,
    analytics: SvgAnalytics,
    analyticsadmin: SvgAnalyticsAdmin,
    search: SearchApp
};
// gets the logo for the specified app
const getLogoForApp = (appName) => {
    if (appName == null) {
        return null;
    }
    let formattedAppName = appName.replace(/\s/g, '').toLowerCase();
    let logo = appLogos[formattedAppName];
    return logo;
};

export { appLogos, getLogoForApp };
