import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from './Box.js';
import { AppsSection } from './AppsSection.js';
import { AppLogo } from './AppLogo.js';
import '@mui/material';
import { CloseButton } from './button/CustomButton.js';
import { groupUserAppsByPurpose } from '../util/userapps.js';

const AppsPane = ({ title, inset = 0, style, onClose, user, width = '28.79rem', height = '93.5vh', ...rest }) => {
    const appsGroupedByPurpose = groupUserAppsByPurpose(user);
    return (jsxs(Box, { background: 'none', direction: 'column', height: height, width: width, justifyContent: 'space-around', alignItems: 'start', style: { padding: '0 0 5.125rem 0' }, children: [jsxs(Box, { gap: 'xlarge', direction: 'row', width: '22.5rem', background: 'none', alignItems: 'center', style: { padding: '1.4375rem 2.1875rem 1.4375rem 1.5rem' }, children: [jsx(Box, { background: 'none', justifyContent: 'center', alignItems: 'start', width: '16.625rem', style: { marginRight: '3.6rem' }, children: jsx(AppLogo, { colour: 'none', bannerColour: 'dark' }) }), jsx(CloseButton, { onClick: onClose })] }), jsx(Box, { background: 'none', direction: 'column', justifyContent: 'start', alignItems: 'start', style: { padding: '3.5rem 2rem' }, children: appsGroupedByPurpose.map((groupedByPurpose) => {
                    return (jsx(AppsSection, { title: groupedByPurpose.purpose, applications: groupedByPurpose.applications }, groupedByPurpose.purpose));
                }) })] }));
};

export { AppsPane };
