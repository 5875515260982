import { jsx, jsxs } from 'react/jsx-runtime';
import { List, Collapse } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import '../services/requestApi.js';
import '../services/userApi.js';
import { useGetBusinessCatalogNodeQuery } from '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { Box } from './Box.js';
import { CollapsibleGroupedItem } from './CollapsibleGroupedItem.js';
import { CollapsibleGroupedParentItem } from './CollapsibleGroupedParentItem.js';

const CollapsibleGroupedList = memo(({ handleSelect, optionList, groupBy, }) => {
    // Getting all the groups based on the groupBy property
    const [expandedGroups, setExpandedGroups] = useState(optionList?.map((option) => option[groupBy]));
    // TODO: update this, this is for getting the icon of a perspective class
    const { data: businessCatalogNode } = useGetBusinessCatalogNodeQuery({}, { skip: !groupBy });
    const businessCatalogNodeValues = businessCatalogNode?.catalogNode?.Children?.value;
    useEffect(() => {
        if (!optionList?.length)
            return;
        const groups = optionList?.map((option) => option[groupBy]);
        setExpandedGroups(groups);
    }, [optionList]);
    const handleGroupToggle = (group) => {
        setExpandedGroups((prevExpandedGroups) => prevExpandedGroups?.includes(group)
            ? prevExpandedGroups?.filter((item) => item !== group)
            : [...prevExpandedGroups, group]);
    };
    return (jsx(Box, { background: 'none', style: { overflow: 'auto' }, children: jsx(List, { sx: {
                padding: 0,
                width: '100%',
                overflow: 'auto',
                borderRadius: '0.5rem',
                height: 'calc(100% - 4rem)',
            }, children: Array.from(new Set(optionList?.map((option) => option?.[groupBy]))).map((groupName, index) => {
                return (jsxs("div", { children: [jsx(CollapsibleGroupedParentItem, { groupName: groupName, expandedGroups: expandedGroups, handleGroupToggle: handleGroupToggle, businessCatalogNodeValues: businessCatalogNodeValues }), jsx(Collapse, { in: expandedGroups?.includes(groupName), children: jsx(List, { children: optionList
                                    ?.filter((option) => option?.[groupBy] === groupName)
                                    ?.map((filteredOption, idx) => (jsx(CollapsibleGroupedItem, { idx: idx, filteredOption: filteredOption, handleSelect: () => handleSelect(filteredOption) }, `${groupName}-${idx}`))) }) })] }, `${groupName}-${index}`));
            }) }) }));
});

export { CollapsibleGroupedList };
