import { jsxs, jsx } from 'react/jsx-runtime';
import { Typography } from '@mui/material';
import { useState, useEffect, Fragment } from 'react';

/**
 * MultilineTypography - wrapper for Mui Typography to support text with newline characters
 * @param children text to display
 * @extends Typography
 */
const MultilineTypography = ({ children = '', ...props }) => {
    const [_children, setChildren] = useState(children);
    useEffect(() => {
        setChildren(children?.split('\n').map((c, i) => (jsxs(Fragment, { children: [i !== 0 && jsx("br", {}), c] }, i))) ?? '');
    }, [children]);
    return jsx(Typography, { ...props, children: _children });
};

export { MultilineTypography, MultilineTypography as default };
