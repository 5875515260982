import { jsx } from 'react/jsx-runtime';
import { memo, useRef, useEffect } from 'react';
import { Box } from '../../../Box.js';
import { useCustomTypeActions } from '../../../../hooks/useCustomTypeActions.js';
import { MemoizedCustomTypeTreeGrid } from '../../../CustomTypeTreeGrid.js';

const CustomTypeField = memo(({ onChange, propertyValue, businessObject, propertyDetails, treeGridProperties, }) => {
    const treeGridRef = useRef(null);
    const { treeGridData, handleAdd, handleDelete, handleActionComplete } = useCustomTypeActions({
        onChange,
        propertyValue,
        businessObject,
        propertyDetails,
        treeGridProperties,
    });
    useEffect(() => {
        treeGridRef.current.dataSource = treeGridData;
        treeGridRef.current.refresh();
        // Save the tree grid data in the local storage.
        // There's an issue on the structure of the tree grid data after editing
        // Just a workaround to fix the issue but please feel free to update the code.
        localStorage.setItem('customTypeTreeGridData', JSON.stringify(treeGridData));
        return () => localStorage.removeItem('customTypeTreeGridData');
    }, [treeGridData]);
    return (jsx(Box, { padding: 'large', background: 'none', height: '100%', style: {
            padding: 0,
        }, children: jsx(MemoizedCustomTypeTreeGrid, { ref: treeGridRef, treeGridData: treeGridData, propertyDetails: propertyDetails, handleAdd: handleAdd, handleDelete: handleDelete, handleActionComplete: handleActionComplete }) }));
});

export { CustomTypeField };
