import { useGetBusinessTypePropertiesQuery, useGetBusinessTypePropertyValuesQuery } from '../services/itemApi.js';
import { useIcon } from './useIcon.js';

const useBusinessType = ({ item }) => {
    const businessTypeId = item?.BusinessTypeId;
    const businessObjectId = item?.BusinessObjectId;
    const businessTypeImageId = item?.BusinessType?._ImageId;
    //   Get Business Type Properties
    const { data: businessTypeProperties } = useGetBusinessTypePropertiesQuery({
        businessTypeId,
    }, { skip: !businessTypeId });
    //   Get Business Type Property Values
    const { data: businessTypePropertyValues } = useGetBusinessTypePropertyValuesQuery({
        businessTypeId,
        businessObjectId,
    }, { skip: !businessTypeId || !businessObjectId });
    //   Get Business Type Icon
    const { iconDetails } = useIcon({
        imageId: businessTypeImageId,
    });
    return {
        businessTypeProperties,
        businessTypePropertyValues,
        businessTypeIconDetails: iconDetails,
    };
};

export { useBusinessType };
