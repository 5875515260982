import { createTheme } from '@mui/material';
import { sizes } from './sizes.js';

const palette = {
    aqua: {
        50: '#D9F3F3',
        100: '#96F6F1',
        200: '#00EFEA',
        300: '#00E5E5',
        400: '#00DCE0',
        500: '#00D4DE',
        600: '#00C3CB',
        700: '#00ADB0',
        800: '#008486',
        900: '#066667',
    },
    juice: {
        50: '#FFF3E0',
        100: '#FFE0B2',
        200: '#FFCD80',
        300: '#FFB84D',
        400: '#FFA826',
        500: '#FF9700',
        600: '#F88D00',
        700: '#F67D00',
        800: '#EF6D00',
        900: '#E65200',
        903: '#B03F00',
    },
    lapis: {
        50: '#EFE5FD',
        100: '#CEC5F4',
        200: '#AC9EEE',
        300: '#8876E8',
        400: '#6959E3',
        500: '#443CDD',
        600: '#3538D7',
        700: '#1530CE',
        800: '#002BC7',
        900: '#001FBD',
    },
    grape: {
        50: '#F0E5FC',
        100: '#D7C0F7',
        200: '#BD95F2',
        300: '#A167EE',
        400: '#8B40EA',
        500: '#7200E5',
        600: '#6700D7',
        700: '#5600D7',
        800: '#4400D2',
        900: '#1600CB',
    },
    navy: {
        50: '#E5E5EB',
        100: '#B9BDCF',
        200: '#8393AE',
        300: '#626A8F',
        400: '#454D7A',
        500: '#273267',
        600: '#212C5F',
        700: '#1A2455',
        800: '#131B49',
        900: '#0A0B33',
    },
    bley: {
        100: '#F4F6FB',
        400: '#E7EBF5',
        500: '#D9DDE8',
        600: '#9EA7BF',
        700: '#697186',
        800: '#4D556B',
    },
    dark: {
        50: '#FDFDFD',
        100: '#F8F8F8',
        200: '#F3F3F3',
        300: '#EEEEEE',
        400: '#CFCFCF',
        500: '#B2B2B2',
        600: '#888888',
        700: '#737373',
        800: '#535353',
        900: '#303030',
    },
    background: {
        200: '#F4F6FB',
        400: '#E7EBF5',
    },
    default: '#0A0B33',
    primary: '#008486',
    secondary: '#8B40EA',
    tertiary: '#EF6D00',
    info: '#697186',
    infolight: '#F4F6FB',
    divider: '#E0E0E0',
    purewhite: '#FFFFFF',
    pureblack: '#000000',
    white: '#FAFAFC',
};
const themeLightOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: palette.aqua[800],
            contrastText: palette.purewhite,
            ...palette.aqua,
        },
        secondary: {
            main: palette.grape[400],
            contrastText: palette.purewhite,
            ...palette.grape,
        },
        background: {
            default: palette.bley[400],
            paper: palette.bley[100],
            ...palette.bley,
        },
        warning: {
            main: palette.juice[800],
            ...palette.juice,
        },
        info: {
            main: palette.bley[700],
            light: palette.bley[500],
            dark: palette.navy[900],
            ...palette.bley,
        },
        common: {
            white: palette.purewhite,
            black: palette.pureblack,
        },
    },
    typography: {
        fontFamily: 'Avenir Medium',
        h1: {
            letterSpacing: '-0.005rem',
            fontSize: '1.5rem',
            fontWeight: 500,
            lineHeight: '2rem',
            color: palette.navy[900],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        h2: {
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: '1.75rem',
            color: palette.navy[900],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        h3: {
            fontSize: '1.125rem',
            fontWeight: 500,
            lineHeight: '1.5625rem',
            color: palette.navy[900],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '1.375rem',
            color: palette.navy[900],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        h5: {
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: '1.1375rem',
            color: palette.navy[900],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        h6: {
            fontSize: '0.75rem',
            fontWeight: 500,
            lineHeight: '1rem',
            color: palette.navy[900],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        subtitle1: {
            fontSize: '1rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        subtitle2: {
            fontSize: '1rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        body1: {
            fontFamily: 'Avenir Roman',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.375rem',
            color: palette.bley[700],
        },
        body2: {
            fontFamily: 'Avenir Book',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.1875rem',
            color: palette.navy[300],
        },
        button: {
            fontFamily: 'Avenir Heavy',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: '1.1875rem',
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        height: sizes.dimension.small,
                        '& > svg': { fontSize: sizes.icon.small },
                        '& > span > svg': { fontSize: sizes.icon.small },
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        height: sizes.dimension.medium,
                        '& > svg': { fontSize: sizes.icon.medium },
                        '& > span > svg': { fontSize: sizes.icon.small },
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        height: sizes.dimension.large,
                        padding: '0 1.5rem',
                        '& > svg': { fontSize: sizes.icon.large },
                        '& > span > svg': { fontSize: sizes.icon.medium },
                    },
                },
                {
                    props: { size: 'xlarge' },
                    style: {
                        fontSize: '1.125rem',
                        lineHeight: 1.75,
                        padding: '0 1.75rem',
                        height: sizes.dimension.xlarge,
                        '& > svg': { fontSize: sizes.icon.xlarge },
                        '& > span > svg': { fontSize: sizes.icon.large },
                    },
                },
                {
                    props: { size: 'xxlarge' },
                    style: {
                        fontSize: '1.25rem',
                        lineHeight: 1.75,
                        padding: '0 2.125rem',
                        height: sizes.dimension.xxlarge,
                        '& > svg': { fontSize: sizes.icon.xxlarge },
                        '& > span > svg': { fontSize: sizes.icon.xlarge },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '0.5rem',
                    boxShadow: 'none',
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiIconButton: {
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        width: sizes.rounddimension.small,
                        height: sizes.rounddimension.small,
                        '& > svg': { fontSize: sizes.icon.small },
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        width: sizes.rounddimension.medium,
                        height: sizes.rounddimension.medium,
                        '& > svg': { fontSize: sizes.icon.medium },
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        width: sizes.rounddimension.large,
                        height: sizes.rounddimension.large,
                        '& > svg': { fontSize: sizes.icon.large },
                    },
                },
                {
                    props: { size: 'xlarge' },
                    style: {
                        width: sizes.rounddimension.xlarge,
                        height: sizes.rounddimension.xlarge,
                        '& > svg': { fontSize: sizes.icon.xlarge, strokeWidth: '0.125rem' },
                    },
                },
                {
                    props: { size: 'xxlarge' },
                    style: {
                        width: sizes.rounddimension.xxlarge,
                        height: sizes.rounddimension.xxlarge,
                        '& > svg': { fontSize: sizes.icon.xxlarge, strokeWidth: '0.15rem' },
                    },
                },
                {
                    props: { color: 'white' },
                    style: {
                        color: palette.purewhite,
                        '&:hover': {
                            //there's no nice way to do this using the palette colour
                            background: 'rgba(255,255,255,0.2)',
                        },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    padding: 0,
                    background: 'none',
                    boxShadow: 'none',
                    overflow: 'visible !important',
                    '&.filter-dropdown': {
                        maxWidth: 'calc(100% / 4)',
                        marginTop: '0.625rem',
                        '&:after': {
                            position: 'absolute',
                            left: 'calc(100% - 36px)',
                            top: '-14px',
                            width: 0,
                            height: 0,
                            content: '""',
                            borderLeft: '10px solid transparent',
                            borderRight: '10px solid transparent',
                            borderBottom: `20px solid ${palette.purewhite}`,
                            zIndex: '0',
                        },
                        '& .MuiList-root': {
                            maxHeight: '13.5rem',
                            overflow: 'auto',
                        },
                        '& .MuiButtonBase-root': {
                            height: 44,
                            zIndex: '1',
                            padding: 12,
                            '&.Mui-selected': {
                                backgroundColor: 'unset',
                                '&:hover': {
                                    color: palette.purewhite,
                                    backgroundColor: palette.aqua[800],
                                },
                            },
                            '& p': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                            '&:hover': {
                                '& p': {
                                    color: palette.purewhite,
                                },
                            },
                        },
                        '& .MuiButtonBase-root:not(:last-child)': {
                            borderBottom: `1px solid ${palette.bley[400]}`,
                        },
                    },
                },
                list: {
                    position: 'relative',
                    borderRadius: '0.5rem',
                    backgroundColor: palette.purewhite,
                    boxShadow: '0px 0px 74px rgba(0, 0, 0, 0.15)',
                    padding: 0,
                    minWidth: '11rem',
                    '&>*:first-of-type': { borderRadius: '0.5rem 0.5rem 0 0' },
                    '&>li:last-child': { borderRadius: '0 0 0.5rem 0.5rem' },
                    //have to use classname as MuiMenu doesn't support variants as yet
                    '&.MuiMenu-list-withArrow': {
                        '&::before': {
                            content: '""',
                            borderBottom: `1rem solid ${palette.purewhite}`,
                            borderLeft: '0.5rem solid transparent',
                            borderRight: '0.5rem solid transparent',
                            position: 'absolute',
                            right: '1rem',
                            top: '-0.75rem',
                        },
                        transform: 'translate(1.5rem,2rem) !important',
                    },
                    '&.MuiMenu-list-flat': {
                        boxShadow: 'none',
                        border: `0.1rem solid ${palette.bley[500]}`,
                    },
                    '&.MuiMenu-list-flat-pane': {
                        '&::before': {
                            content: '""',
                            boxShadow: 'none',
                            border: `0.1rem solid ${palette.bley[500]}`,
                            position: 'absolute',
                            right: '0rem',
                            top: '-3rem',
                        },
                        transform: 'translate(-1rem,-1rem) !important',
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: '1.25rem',
                    color: palette.navy[300],
                    '&:hover': {
                        backgroundColor: palette.primary,
                        color: palette.purewhite,
                    },
                },
            },
        },
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'menu' },
                    style: { borderColor: palette.divider, margin: '0 !important' },
                },
            ],
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'body3' },
                    style: {
                        fontFamily: 'Avenir Roman',
                        fontSize: '0.75rem',
                        fontWeight: 400,
                        lineHeight: '1rem',
                        color: palette.bley[700],
                    },
                },
                {
                    props: { variant: 'columnHeader' },
                    style: {
                        fontSize: '0.75rem',
                        fontWeight: 800,
                        lineHeight: '1rem',
                        letterSpacing: '0.032rem',
                        textTransform: 'uppercase',
                        color: palette.bley[700],
                    },
                },
            ],
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-rounded': {
                        backgroundColor: palette.purewhite,
                        borderRadius: '1rem',
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    gap: '0.75rem',
                    height: '4.75rem',
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    background: palette.bley[100],
                    fontFamily: 'Avenir Medium',
                    fontSize: '1.25rem',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    color: palette.navy[900],
                    padding: '0 2rem',
                    marginBottom: '1.5rem',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '1rem 2rem',
                    background: palette.purewhite,
                    '& .MuiTypography-root > p': {
                        marginTop: 0,
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '1rem 2rem 2rem 2rem',
                    justifyContent: 'flex-start',
                    background: palette.purewhite,
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    background: 'rgba(10, 11, 51, 0.5)',
                    '&.MuiBackdrop-invisible': {
                        background: 'transparent',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: { minHeight: 0 },
                indicator: { height: '0.1875rem', borderRadius: '3.125rem' },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: 'Avenir Medium',
                    fontSize: '1rem',
                    fontWeight: 400,
                    color: palette.bley[700],
                    textTransform: 'none',
                    padding: '0.25rem 0rem',
                    minHeight: 0,
                    minWidth: 0,
                    marginRight: '1.5rem',
                    '&.Mui-selected': {
                        color: palette.navy[900],
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center',
                    borderRadius: '0.75rem',
                    boxShadow: '0 0 0.3rem 0 rgba(0, 0, 0, 0.25)',
                    padding: '0 1.5rem 0 0.5rem',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: palette.bley[700],
                    fontSize: '0.875rem',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: palette.navy[700],
                    color: palette.purewhite,
                    fontFamily: 'Avenir Medium',
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                    borderColor: palette.purewhite,
                    padding: '0.5rem',
                    maxWidth: '31rem',
                    maxHeight: '15rem',
                    '& .tooltip-title': {
                        '-webkit-line-clamp': '13',
                        '-webkit-box-orient': 'vertical',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                },
                arrow: {
                    '&:before': {
                        backgroundColor: palette.navy[700],
                        borderColor: palette.purewhite,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontFamily: 'Avenir Book !important',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    color: `${palette.navy[900]} !important`,
                    '& .MuiInputBase-root': {
                        fontFamily: 'Avenir Book !important',
                        height: '100%',
                        borderRadius: '0.5rem',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: palette.aqua[600],
                        },
                        '&.Mui-typing': {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: palette.bley[800],
                                filter: 'unset',
                            },
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderWidth: 1,
                            borderColor: palette.aqua[800],
                            filter: 'drop-shadow(0px 0px 4px #00C3CB)',
                        },
                        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: palette.juice[900],
                            filter: 'unset',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: palette.bley[800],
                        },
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: palette.bley[100],
                    },
                    '&:active': {
                        backgroundColor: palette.bley[100],
                    },
                },
            },
        },
        MuiChip: {
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        textTransform: 'uppercase',
                        fontSize: '0.625rem !important',
                        fontWeight: '500',
                        fontFamily: 'Avenir Medium',
                        height: '1.375rem',
                    },
                },
                {
                    props: { size: 'small', color: 'default' },
                    style: {
                        backgroundColor: palette.bley[400],
                        color: palette.bley[800],
                    },
                },
                {
                    props: { size: 'small', color: 'info' },
                    style: {
                        backgroundColor: palette.bley[600],
                        color: palette.purewhite,
                    },
                },
                {
                    props: { size: 'small', color: 'primary' },
                    style: {
                        backgroundColor: palette.aqua[50],
                        color: palette.aqua[900],
                    },
                },
                {
                    props: { size: 'small', color: 'warning' },
                    style: {
                        backgroundColor: palette.juice[50],
                        color: palette.juice[900],
                    },
                },
                {
                    props: { size: 'small', color: 'secondary' },
                    style: {
                        backgroundColor: palette.grape[50],
                        color: palette.grape[900],
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        minHeight: '2rem',
                    },
                },
            ],
            styleOverrides: {
                root: {
                    borderRadius: '0.25rem',
                    fontFamily: 'Avenir Book',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: '1.1875rem',
                    borderColor: palette.bley[500],
                    padding: '0 0.5rem',
                    '& > .MuiChip-icon, & > .MuiAvatar-root, & > img:first-of-type': {
                        minWidth: '1.5rem',
                        maxWidth: '1.5rem',
                        margin: '0 0.5rem 0 0',
                    },
                    '& > .MuiChip-label': {
                        padding: 0,
                    },
                    '& .MuiTypography-body2': {
                        color: palette.bley[800],
                    },
                    '& .MuiTypography-body3': {
                        color: palette.bley[700],
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingLeft: 0,
                    color: palette.aqua[800],
                    fontFamily: 'Avenir Heavy',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    '& p': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                },
                icon: {
                    color: palette.aqua[800],
                    width: '1.25rem',
                    height: '1.25rem',
                },
            },
        },
    },
};
const lightTheme = createTheme(themeLightOptions);

export { lightTheme, palette, themeLightOptions };
