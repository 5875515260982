import { jsxs, jsx } from 'react/jsx-runtime';
import { Dialog, DialogTitle, Divider, DialogContent } from '@mui/material';
import { Box } from '../Box.js';
import { MultilineTypography } from '../MultilineTypography.js';
import { CloseButton } from '../button/CustomButton.js';
import { TextIconButton } from '../button/TextIconButton.js';

const DetailDialog = ({ title, open, icon, children, height, width, onClose, onAction, }) => (jsxs(Dialog, { open: open, PaperProps: {
        sx: {
            minWidth: '30rem',
            maxWidth: '54rem',
            minHeight: '20rem',
            maxHeight: '40rem',
            ...(height && { height: `${height}rem` }),
            ...(width && { width: `${width}rem` }),
        },
    }, children: [jsxs(DialogTitle, { sx: {
                background: 'none',
                justifyContent: 'space-between',
                marginBottom: 0,
                paddingLeft: '1.5rem',
            }, children: [(icon || title) && (jsx(TextIconButton, { startIcon: icon, onClick: onAction && (() => onAction()), text: title, textVariant: 'h2', color: 'info' })), jsx(Box, { background: 'none' }), jsx(CloseButton, { size: 'small', onClick: onClose && (() => onClose()) })] }), jsx(Divider, { sx: { margin: '0 2rem 1rem 2rem' } }), jsx(DialogContent, { sx: { whiteSpace: 'pre-wrap', margin: '1rem 2rem 2rem', padding: 0 }, children: typeof children === 'string' ? (jsx(MultilineTypography, { variant: 'body1', children: children })) : (children) })] }));

export { DetailDialog, DetailDialog as default };
