import { publicClientApplication } from './AuthProvider.js';
import { loginRequest } from './config.js';

async function prepareHeaders(headers) {
    const accounts = publicClientApplication.getAllAccounts();
    publicClientApplication.setActiveAccount(accounts[0]);
    const silentRequest = {
        ...loginRequest,
        account: accounts[0],
    };
    const authResult = await publicClientApplication.acquireTokenSilent(silentRequest);
    if (authResult.accessToken) {
        headers.set('Authorization', `Bearer ${authResult.accessToken}`);
    }
    return headers;
}

export { prepareHeaders };
