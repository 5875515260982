import { jsxs, jsx } from 'react/jsx-runtime';
import { Edit20Regular } from '@fluentui/react-icons';
import { useTheme, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material';
import dayjs, { isDayjs } from 'dayjs';
import { useState } from 'react';
import { Box } from '../Box.js';
import { Button } from '../button/Button.js';
import './components/item/BusinessTypeField.js';
import { DateTimeField } from './components/item/DateTimeField.js';
import '@syncfusion/ej2-react-calendars';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import 'lodash';
import '../item/utils.js';

const EditDateTimeDialog = ({ open, title, onSave, onClose, isLoading = false, fieldLabel, propertyName, propertyValue, }) => {
    const theme = useTheme();
    const [value, setValue] = useState(!!propertyValue ? dayjs(propertyValue).format('MM/DD/YYYY HH:mm') : null);
    const isValidValue = !!isDayjs(value) ? value?.isValid() : true;
    const onChange = (date) => setValue(date);
    const isDisabledSaveButton = dayjs(propertyValue).startOf('day').isSame(value) ||
        value === propertyValue ||
        !isValidValue ||
        isLoading;
    const handleSave = () => {
        const formattedDate = isDayjs(value)
            ? value?.format('YYYY-MM-DDTHH:mm:ss')
            : value;
        onSave && onSave(formattedDate, propertyName);
    };
    return (jsxs(Dialog, { open: open, PaperProps: {
            sx: {
                width: '100%',
                height: '100%',
                maxWidth: '47.5rem',
                maxHeight: '20.5rem',
            },
        }, children: [jsxs(DialogTitle, { children: [jsx(Edit20Regular, {}), title] }), jsx(DialogContent, { children: jsxs(Box, { background: 'none', style: { maxWidth: '21.75rem' }, className: 'datepicker', children: [jsx(Typography, { mb: 1, variant: 'body1', sx: { color: theme.palette.info.dark }, children: fieldLabel }), jsx(DateTimeField, { value: value, onChange: onChange })] }) }), jsxs(DialogActions, { children: [jsx(Button, { variant: 'contained', disabled: isDisabledSaveButton, onClick: handleSave, children: "Save" }), jsx(Button, { variant: 'outlined', onClick: onClose, children: "Cancel" })] })] }));
};

export { EditDateTimeDialog };
