import { jsx } from 'react/jsx-runtime';
import { Person20Regular } from '@fluentui/react-icons';
import { useTheme } from '@mui/material';
import { Chip } from './Chip.js';

const UserChip = ({ user, onClick = undefined, sx, ...props }) => {
    const theme = useTheme();
    return (jsx(Chip, { ...props, color: 'default', variant: 'outlined', icon: jsx(Person20Regular, { color: theme.palette.info[700] }), title: user?.name ?? '', onClick: onClick && (() => onClick(user)), sx: {
            width: '100%',
            maxWidth: 'fit-content',
            '& > .MuiChip-icon': {
                margin: '0 0.25rem 0 0',
                minWidth: '1.25rem',
            },
            ...sx
        } }));
};

export { UserChip };
