import { jsxs, jsx } from 'react/jsx-runtime';
import { useTheme, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { CloseButton } from '../button/CustomButton.js';
import { useAppSelector } from '../../store/store.js';
import 'react-redux';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import BreadcrumbsWithMenu from '../BreadcrumvsWithMenuProps.js';
import { palette } from '../../themes/light.theme.js';

const FullModalDialog = ({ title, open, icon, children, onClose, onAction, }) => {
    const theme = useTheme();
    const levels = useAppSelector((state) => state.itemModal.navigation.levels);
    return (jsxs(Dialog, { fullScreen: true, open: open, sx: { margin: '1.5rem' }, children: [jsxs(DialogTitle, { sx: {
                    background: theme.palette.info.dark,
                    height: '3.25rem',
                    padding: '0 1rem 0 1.5rem',
                    justifyContent: levels?.length > 1 ? 'space-between' : 'flex-end',
                    marginBottom: 0,
                }, children: [jsx(BreadcrumbsWithMenu, { startIcon: icon, text: title, onClick: onAction, crumbs: levels, color: palette.purewhite }), jsx(CloseButton, { color: 'white', onClick: onClose && (() => onClose()) })] }), jsx(DialogContent, { sx: {
                    padding: '0!important',
                    overflow: 'hidden',
                    display: 'flex',
                    flex: 1,
                }, children: children })] }));
};

export { FullModalDialog, FullModalDialog as default };
