import { jsx, jsxs } from 'react/jsx-runtime';
import { Warning24Regular, CheckmarkCircle24Regular, Info24Regular } from '@fluentui/react-icons';
import { useTheme, Snackbar, Alert as Alert$1, Typography } from '@mui/material';
import { Box } from '../Box.js';

const Alert = ({ message, title, open = false, position = { horizontal: 'center', vertical: 'bottom' }, alertType = 'info', icon, autoHideDuration, onClose, ...props }) => {
    const theme = useTheme();
    let color = theme.palette.text.primary;
    let lightColor = theme.palette.text.primary;
    if (alertType === 'warning') {
        color = theme.palette.warning[903];
        lightColor = theme.palette.warning[100];
        icon = (jsx(Warning24Regular, { color: color, style: {
                margin: '0 0.5rem 0 0.5rem',
            } }));
    }
    else if (alertType === 'success') {
        color = theme.palette.primary[900];
        lightColor = theme.palette.primary[50];
        icon = (jsx(CheckmarkCircle24Regular, { color: color, style: {
                margin: '0 0.5rem 0 0.5rem',
            } }));
    }
    else if (alertType === 'info') {
        color = theme.palette.info[800];
        lightColor = theme.palette.info[100];
        icon = (jsx(Info24Regular, { color: color, style: {
                margin: '0 0.5rem 0 0.5rem',
            } }));
    }
    return (jsx(Snackbar, { open: open, anchorOrigin: position, sx: {
            alignContent: 'center',
        }, autoHideDuration: autoHideDuration ?? null, onClose: onClose ?? (() => { }), ...props, children: jsx(Alert$1, { color: alertType, icon: icon, onClose: onClose ?? (() => { }), children: jsxs(Box, { background: 'none', style: {
                    borderRight: `2px solid ${lightColor}`,
                    padding: '0.2rem 1rem 0.2rem 0.2rem',
                }, children: [!!title && (jsx(Typography, { variant: 'h4', sx: {
                            fontFamily: 'Avenir black!important',
                            color: { color },
                        }, children: title })), jsx(Typography, { variant: 'body1', sx: {
                            color: { color },
                        }, children: message })] }) }) }));
};

export { Alert };
