import { loginRequest } from '../auth/config.js';
import 'react/jsx-runtime';
import { config } from '../config.js';
import { publicClientApplication } from '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'react';
import { DisposalRequestResponseStatus } from '../types/requestTypes.js';
import '../types/itemTypes.js';

const getImage = async (imageId) => {
    const token = await acquireToken();
    const BASE_URL = `${config.API_BASE_URL}/Enc.Image?`;
    const BASE_IMG_URL = `${config.REACT_APP_ENC_IMC_URL}/`;
    try {
        if (!!imageId) {
            //TODO: Move to RTKQuery
            const response = await fetch(`${BASE_URL}$filter=ID eq ${imageId}`, {
                headers: {
                    Authorization: `bearer ${token}`,
                },
            });
            let data = await response.json();
            const url = data?.value[0]?.Url ?? '';
            if (url !== '') {
                const imageUrl = `${BASE_IMG_URL}${url}`;
                return imageUrl;
            }
        }
    }
    catch (error) {
        console.error('failed to fetch image');
        return '';
    }
};
const acquireToken = async () => {
    const accounts = publicClientApplication.getAllAccounts();
    publicClientApplication.setActiveAccount(accounts[0]);
    const silentRequest = {
        ...loginRequest,
        account: accounts[0],
    };
    const authResult = await publicClientApplication.acquireTokenSilent(silentRequest);
    return authResult.accessToken;
};
const isUserInPending = (user, approvals = []) => {
    // Checking if user has fully loaded
    if (!user?.username)
        return false;
    const normalizedUserMail = user.Mail.toLowerCase();
    return approvals.some((approval) => approval.Status === DisposalRequestResponseStatus.Pending &&
        approval.Approver?.Mail?.toLowerCase() === normalizedUserMail);
};
const getExceptionLabel = (property) => {
    switch (property) {
        case 'RetentionConflict':
            return 'Retention Conflict';
        case 'RelatedItemRetentionConflict':
            return 'Retention Conflict';
        case 'RejectedInOtherRequest':
            return 'Rejected In Other Requests';
        case 'FailedToDestroy':
            return 'Failed To Destroy';
        default:
            return '';
    }
};

export { acquireToken, getExceptionLabel, getImage, isUserInPending };
