import { jsx } from 'react/jsx-runtime';
import { memo } from 'react';
import { Box } from '../../Box.js';
import '@mui/material';
import '../../../config.js';
import '../../../types/requestTypes.js';
import '../../../types/itemTypes.js';
import '../../../auth/config.js';
import '../../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../../themes/light.theme.js';
import '../../../store/store.js';
import 'redux-persist/integration/react';
import '../../../store/slices/applicationSlice.js';
import '../../../store/slices/downloadFileSlice.js';
import '../../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../../constants/messages.js';
import '@pdftron/webviewer';
import '../../../services/requestApi.js';
import '../../../services/userApi.js';
import '../../../services/itemApi.js';
import '../../../services/requestDetailsApi.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../../store/thunks/downloadFileThunk.js';
import '@fluentui/react-icons';
import '../../chip/ItemManagementStatusChip.js';
import '../../chip/PerspectiveClassChip.js';
import { RetentionClassChip } from '../../chip/RetentionClassChip.js';

const RetentionClassTemplate = ({ classifications, }) => {
    return (jsx(Box, { background: 'none', alignItems: 'start', children: (classifications ?? []).map((rClass, i) => (jsx(RetentionClassChip, { classification: rClass, border: false }, i))) }));
};
const MemoizedRetentionClassTemplate = memo(RetentionClassTemplate);

export { MemoizedRetentionClassTemplate, RetentionClassTemplate };
