import { DatePicker, DateTimePicker } from '@syncfusion/ej2-react-calendars';
import { DropDownList, AutoComplete } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBox } from '@syncfusion/ej2-react-inputs';
import { useState, useEffect, useMemo } from 'react';
import { getEnumValues, getPerspectiveClassValues } from '../components/dialog/components/item/customTypeUtils.js';
import { isDate, isEnum, isDouble, isBoolean, isInteger, isDateTime, isEntity } from '../components/item/utils.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import { useGetEnumsQuery, useGetPerspectiveClassesQuery } from '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { Field } from './useItemPropertiesPanel.js';
import { useSearch } from './useSearch.js';

const useCustomTypeTreeGrid = ({ treeGridData, treeGridRef, propertyDetails, handleAdd, handleDelete, }) => {
    // REACT HOOKS
    const [isAllowAdding, setIsAllowAdding] = useState(false);
    const [isAllowDeleting, setIsAllowDeleting] = useState(false);
    const [isAllowEditing, setIsAllowEditing] = useState(false);
    // update the toolbar buttons state on page load.
    useEffect(() => {
        updateToolbarButtonsState(null);
    }, []);
    // Services
    const { data: enums } = useGetEnumsQuery({});
    const { data: _perspectiveClasses } = useGetPerspectiveClassesQuery({});
    // Tree grid search functionality
    const { handleSearch } = useSearch({
        treeGridData,
        gridRef: treeGridRef,
    });
    // Component Variables
    const isCollection = propertyDetails?.IsCollection;
    let clickTimeout;
    let clickCount = 0;
    let elem;
    let textField;
    let enumField;
    let doubleField;
    let booleanField;
    let integerField;
    let datePickerField;
    let dateTimePickerField;
    let perspectiveClassField;
    let updatedPerspectiveClass = null;
    let selectedField = '';
    const editOptions = {
        allowAdding: true,
        allowDeleting: true,
        allowEditing: true,
        mode: 'Cell',
        newRowPosition: 'Below',
    };
    const editTemplate = useMemo(() => {
        return {
            create: () => {
                elem = document.createElement('input');
                return elem;
            },
            read: (args) => {
                switch (selectedField) {
                    case Field.PerspectiveClass:
                        return updatedPerspectiveClass;
                    case Field.Boolean:
                        return booleanField?.value;
                    default:
                        return args?.value;
                }
            },
            write: (args) => {
                selectedField = '';
                const { rowData } = args;
                const { fieldDetails } = rowData;
                const isDateType = isDate(fieldDetails);
                const isEnumType = isEnum(fieldDetails);
                const isDoubleType = isDouble(fieldDetails);
                const isBooleanType = isBoolean(fieldDetails);
                const isIntegerType = isInteger(fieldDetails);
                const isDateTimeType = isDateTime(fieldDetails);
                const isPerspectiveClassType = isEntity(fieldDetails) &&
                    fieldDetails?.PropTypeDef?.Name?.indexOf('IT_') === 0 &&
                    fieldDetails?.PropTypeDef?.Category?.InternalName === 'Inheritance';
                if (isDateType) {
                    const value = args.rowData[args.column.field];
                    datePickerField = new DatePicker({
                        value,
                        format: 'yyyy-MM-dd',
                        showTodayButton: false,
                        showClearButton: false,
                        cssClass: 'custom-type-datepicker',
                        allowEdit: false,
                    });
                    datePickerField.appendTo(elem);
                }
                else if (isDateTimeType) {
                    const value = args.rowData[args.column.field];
                    dateTimePickerField = new DateTimePicker({
                        value,
                        format: 'yyyy-MM-ddT00:00:00',
                        showTodayButton: false,
                        showClearButton: false,
                        cssClass: 'custom-type-datepicker',
                        allowEdit: false,
                    });
                    dateTimePickerField.appendTo(elem);
                }
                else if (isEnumType) {
                    const value = args.rowData[args.column.field];
                    const { PropTypeDef } = fieldDetails;
                    const enumValues = getEnumValues(enums, PropTypeDef?.Caption);
                    const sortOrder = 'Ascending';
                    enumField = new DropDownList({
                        value,
                        fields: { text: 'Caption', value: 'name' },
                        dataSource: enumValues,
                        cssClass: 'custom-type-dropdown',
                        sortOrder,
                        popupHeight: '245px',
                    });
                    enumField.appendTo(elem);
                }
                else if (isIntegerType) {
                    const value = args.rowData[args.column.field];
                    integerField = new NumericTextBox({
                        value,
                        format: '####',
                        cssClass: 'custom-type-numeric',
                    });
                    integerField.appendTo(elem);
                }
                else if (isDoubleType) {
                    const value = args.rowData[args.column.field];
                    doubleField = new NumericTextBox({
                        value,
                        format: '####.00',
                        decimals: 2,
                        cssClass: 'custom-type-numeric',
                    });
                    doubleField.appendTo(elem);
                }
                else if (isBooleanType) {
                    const value = args.rowData[args.column.field];
                    selectedField = Field.Boolean;
                    booleanField = new DropDownList({
                        value: value?.toString(),
                        fields: { text: 'label', value: 'value' },
                        dataSource: [
                            { label: 'Yes', value: 'true' },
                            { label: 'No', value: 'false' },
                        ],
                        cssClass: 'custom-type-dropdown',
                    });
                    booleanField.appendTo(elem);
                }
                else if (isPerspectiveClassType) {
                    let value = args.rowData[args.column.field];
                    selectedField = Field.PerspectiveClass;
                    const propTypeDefId = fieldDetails?.PropTypeDefId;
                    const perspectiveClasses = getPerspectiveClassValues(_perspectiveClasses, propTypeDefId, '');
                    if (value)
                        updatedPerspectiveClass = value;
                    else {
                        value = {
                            ID: null,
                            TypeDefId: null,
                            Active: true,
                        };
                    }
                    perspectiveClassField = new DropDownList({
                        value: value?.ID,
                        dataSource: perspectiveClasses,
                        fields: {
                            text: '_Display',
                            value: 'ID',
                        },
                        cssClass: 'custom-type-dropdown',
                        select: (res) => {
                            perspectiveClasses.forEach((val) => {
                                if (val.ID === res?.itemData?.ID ||
                                    (!val.ID && !res?.itemData?.ID))
                                    updatedPerspectiveClass = val;
                            });
                        },
                    });
                    perspectiveClassField.appendTo(elem);
                }
                else {
                    // TODO: update the component to TextBox, currently, there's an issue on inline editing.
                    const value = args.rowData[args.column.field];
                    textField = new AutoComplete({
                        value,
                        showClearButton: false,
                        zIndex: -1000,
                    });
                    textField.appendTo(elem);
                }
            },
        };
    }, []);
    // Function to handle single click
    const handleSingleRowClick = () => {
        const _selectedRow = treeGridRef?.current?.getSelectedRecords()?.[0];
        // Perform single click action here
        if (!_selectedRow)
            updateToolbarButtonsState(_selectedRow);
    };
    const load = () => {
        const instance = document.getElementById('item-custom-type-tree-grid').ej2_instances[0];
        if (instance) {
            instance.element.addEventListener('click', function (e) {
                if (e.target.classList.contains('e-rowcell') ||
                    e.target.classList.contains('e-treecell') ||
                    e.target.classList.contains('editable-cell') ||
                    e.target.classList.contains('MuiTypography-body2')) {
                    clickCount++;
                    // If it's the first click, set a timeout to detect double click
                    if (clickCount === 1) {
                        clickTimeout = setTimeout(() => {
                            // If the timeout completes, it's a single click
                            handleSingleRowClick();
                            // Reset the click count
                            clickCount = 0;
                        }, 300); // You can adjust the timeout duration (in milliseconds)
                    }
                    else if (clickCount === 2) {
                        // If it's the second click before the timeout, it's a double click
                        clearTimeout(clickTimeout);
                        const isEdit = treeGridRef.current.grid.isEdit;
                        if (!isEdit)
                            setTimeout(() => {
                                handleSingleRowClick();
                            }, 300);
                        // Reset the click count
                        clickCount = 0;
                    }
                }
            });
        }
    };
    const updateToolbarButtonsState = (row) => {
        const _selectedRow = row;
        const _isAllowAdding = ((!_selectedRow && isCollection) ||
            _selectedRow?.fieldDetails?.IsCollection) ??
            false;
        const _isAllowDeleting = (!!_selectedRow?.fieldDetails
            ? !!_selectedRow?.childRecords?.length &&
                !!_selectedRow?.fieldDetails?.IsCollection
            : !!_selectedRow?.childRecords?.length) ?? false;
        const _isAllowEditing = (!!_selectedRow &&
            !_selectedRow?.fieldDetails?.IsCollection &&
            !_selectedRow?.childRecords?.length) ??
            false;
        // this is to prevent inline editing when the field is a collection
        treeGridRef.current.editSettings.allowEditing = _isAllowEditing;
        setIsAllowAdding(_isAllowAdding);
        setIsAllowDeleting(_isAllowDeleting);
        setIsAllowEditing(_isAllowEditing);
    };
    const handleEdit = () => {
        const _selectedRow = treeGridRef.current.getSelectedRowIndexes();
        const rowIndex = _selectedRow[0];
        treeGridRef.current.editCell(rowIndex, 'value');
    };
    const handleRowSelecting = (row) => {
        updateToolbarButtonsState(row.data);
    };
    const onClickAddButton = () => {
        if (!isAllowAdding)
            return;
        const _selectedRow = treeGridRef.current.getSelectedRecords()?.[0];
        !!handleAdd && handleAdd(_selectedRow);
    };
    const onClickDeleteButton = () => {
        if (!isAllowDeleting)
            return;
        const _selectedRow = treeGridRef.current.getSelectedRecords()?.[0];
        !!handleDelete && handleDelete(_selectedRow);
        updateToolbarButtonsState(null);
    };
    const onActionBegin = (args) => {
        if (args.requestType === 'sorting') {
            const storedTreeGridData = JSON.parse(localStorage.getItem('customTypeTreeGridData'));
            treeGridRef.current.dataSource = storedTreeGridData;
            updateToolbarButtonsState(null);
        }
    };
    const onSearch = (value) => {
        handleSearch(value);
        updateToolbarButtonsState(null);
    };
    return {
        treeGridRef,
        editOptions,
        isAllowAdding,
        isAllowEditing,
        isAllowDeleting,
        editTemplate,
        load,
        onSearch,
        handleEdit,
        onActionBegin,
        onClickAddButton,
        handleRowSelecting,
        onClickDeleteButton,
    };
};

export { useCustomTypeTreeGrid };
