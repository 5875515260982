import { useMsal } from '@azure/msal-react';
import { useState, useEffect } from 'react';
import { MsalAccountToUser, MsalAccountToAnalyticsUser } from '../types/userTypes.js';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import { loginRequest } from './config.js';

const useMsalLogout = () => {
    const { instance } = useMsal();
    const logoutRequest = {
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: '/logout', // Redirects to home page after logout
    };
    function onLogout() {
        instance.logoutRedirect(logoutRequest);
    }
    return onLogout;
};
function useLogin() {
    const { instance } = useMsal();
    function onLogin() {
        instance.loginRedirect(loginRequest);
    }
    return { onLogin };
}
const useMsalUser = () => {
    const { instance } = useMsal();
    const [user, setUser] = useState({});
    useEffect(() => {
        if (instance)
            setUser(MsalAccountToUser(instance.getActiveAccount() ?? instance.getAllAccounts()?.[0]));
    }, [instance]);
    return user;
};
const useMsalAnalyticsUser = () => {
    const { instance } = useMsal();
    const [user, setUser] = useState({});
    useEffect(() => {
        if (instance)
            setUser(MsalAccountToAnalyticsUser(instance.getActiveAccount() ?? instance.getAllAccounts()?.[0]));
    }, [instance]);
    return user;
};

export { useLogin, useMsalAnalyticsUser, useMsalLogout, useMsalUser };
