import { jsx } from 'react/jsx-runtime';
import Zendesk from 'react-zendesk';
import { useEffect } from 'react';
import { useUser } from '../hooks/useUser.js';
import { config } from '../config.js';

const ZenDeskSupport = ({ zendeskKey, setting, }) => {
    const user = useUser();
    useEffect(() => {
        setting = {
            chat: {
                connectionOnPageLoad: false,
            },
        };
    }, []);
    const zendeskLoadEvent = () => {
        if (window.zE) {
            window.zE('webWidget', 'hide');
            window.zE('webWidget:on', 'close', function () {
                window.zE('webWidget', 'hide');
            });
            window.zE('webWidget', 'prefill', {
                name: { value: user?.name, readOnly: true },
                email: { value: user?.username, readOnly: true },
            });
        }
    };
    return (jsx("div", { children: jsx(Zendesk, { defer: true, zendeskKey: config.REACT_APP_ENC_ZENDESK_KEY, ...setting, onLoaded: zendeskLoadEvent }) }));
};

export { ZenDeskSupport };
