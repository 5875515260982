import { jsx, jsxs } from 'react/jsx-runtime';
import { Box } from '../../../Box.js';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { OptionSelect } from '../../../OptionSelect.js';

const StyledImage = styled('img') ``;
const PerspectiveClassField = ({ iconDetails, handleDelete, handleSelect, perspectiveClasses, selectedPerspectiveClass, }) => {
    return (jsx(Box, { width: '29rem', background: 'none', children: jsx(OptionSelect, { handleSelect: handleSelect, handleDelete: handleDelete, options: perspectiveClasses, placeholder: '- Select a perspective class -', selectedOption: selectedPerspectiveClass ? (jsxs(Box, { background: 'none', alignItems: 'center', direction: 'row', children: [iconDetails ? (jsx(StyledImage, { alt: iconDetails?.AltText, style: { height: '1.5rem' }, src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}` })) : null, jsx(Typography, { variant: 'body2', ml: 1, children: selectedPerspectiveClass?.Name })] })) : null }) }));
};

export { PerspectiveClassField };
