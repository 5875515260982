import { jsx } from 'react/jsx-runtime';
import { New20Regular, PeopleQueue20Regular, CheckmarkSquare20Regular, DismissSquare20Regular, ArrowClockwiseDashes20Regular, Delete20Regular, ErrorCircle20Regular } from '@fluentui/react-icons';
import { DisposalRequestStatus, DisposalRequestStatusCaption } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import { Chip } from './Chip.js';

const DisposalRequestStatusChip = ({ status, border = false, ...props }) => {
    // can make these global if needed
    const _icon = {
        [DisposalRequestStatus.New]: jsx(New20Regular, {}),
        [DisposalRequestStatus.InApproval]: jsx(PeopleQueue20Regular, {}),
        [DisposalRequestStatus.Approved]: jsx(CheckmarkSquare20Regular, {}),
        [DisposalRequestStatus.Rejected]: jsx(DismissSquare20Regular, {}),
        [DisposalRequestStatus.InProgress]: jsx(ArrowClockwiseDashes20Regular, {}),
        [DisposalRequestStatus.Complete]: jsx(Delete20Regular, {}),
        [DisposalRequestStatus.InProgressOrComplete]: jsx(Delete20Regular, {}),
        [DisposalRequestStatus.Failed]: jsx(ErrorCircle20Regular, {}),
        [DisposalRequestStatus.ItemDestroyInProgress]: jsx(ArrowClockwiseDashes20Regular, {}),
        [DisposalRequestStatus.ItemDestroyComplete]: jsx(CheckmarkSquare20Regular, {}),
    };
    const _color = {
        [DisposalRequestStatus.New]: 'default',
        [DisposalRequestStatus.InApproval]: 'default',
        [DisposalRequestStatus.Approved]: 'primary',
        [DisposalRequestStatus.Rejected]: 'warning',
        [DisposalRequestStatus.InProgress]: 'default',
        [DisposalRequestStatus.Complete]: 'default',
        [DisposalRequestStatus.InProgressOrComplete]: 'default',
        [DisposalRequestStatus.Failed]: 'error',
        [DisposalRequestStatus.ItemDestroyInProgress]: 'default',
        [DisposalRequestStatus.ItemDestroyComplete]: 'default',
    };
    return (jsx(Chip, { ...props, border: border, title: DisposalRequestStatusCaption[status], icon: _icon[status], color: _color[status], sx: {
            '& > .MuiChip-icon': {
                margin: '0 0.25rem 0 0',
                minWidth: '1.25rem',
            },
        } }));
};

export { DisposalRequestStatusChip };
