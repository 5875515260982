import { jsxs, jsx } from 'react/jsx-runtime';
import SvgEncompaasLogo from '../../images/EncompaasLogo.js';

//create super component for Page with background image
const Page = ({ children, backgroundImage = false, background = 'default', padding = 'none', className, ...props }) => {
    let _className = `ecs-page ecs-background-${background} ecs-padding-${padding} `;
    //can apply custom className
    _className = _className + (className ?? '');
    return (jsxs("div", { ...props, className: _className, style: props.style, children: [children, backgroundImage && jsx(SvgEncompaasLogo, { className: 'ecs-page-background-image' })] }));
};

export { Page };
