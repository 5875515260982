import { createSlice } from '@reduxjs/toolkit';

const itemInitialState = {
    navigation: {
        levels: [],
    },
};
const itemModalSlice = createSlice({
    name: 'itemModal',
    initialState: itemInitialState,
    reducers: {
        setCurrentItem: (state, action) => {
            if (action.payload == undefined) {
                state.navigation.levels = [];
            }
            else {
                const foundNavigatedIndex = state.navigation.levels.findIndex((x) => x.id == action.payload.id);
                const wasNavigatedTo = foundNavigatedIndex != -1;
                if (wasNavigatedTo) {
                    const shrinkBreadcrumb = state.navigation.levels.splice(0, foundNavigatedIndex + 1);
                    state.navigation.levels = shrinkBreadcrumb;
                    state.navigation.levels[foundNavigatedIndex] = action.payload;
                }
                else {
                    const expandBreadcrumb = [...state.navigation.levels, action.payload];
                    state.navigation.levels = expandBreadcrumb;
                }
            }
        },
        updateItem: (state, action) => {
            const foundNavigatedIndex = state.navigation.levels.findIndex((x) => x.id == action.payload.id);
            state.navigation.levels[foundNavigatedIndex] = action.payload;
        },
    },
});
const { setCurrentItem, updateItem } = itemModalSlice.actions;
var itemModalReducer = itemModalSlice.reducer;

export { itemModalReducer as default, itemInitialState, itemModalSlice, setCurrentItem, updateItem };
