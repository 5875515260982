import { jsxs, jsx } from 'react/jsx-runtime';
import { useTheme, Typography, Menu, Grow, Divider, MenuItem } from '@mui/material';
import { Children, useState } from 'react';
import { AppLogo } from './AppLogo.js';
import { Box } from './Box.js';
import { Button } from './button/Button.js';
import { AboutButton, GridButton } from './button/CustomButton.js';
import { UserTile } from './UserTile.js';
import { AppsPane } from './AppsPane.js';

const AppBar = ({ title, actions, preactions, height, showLogo = true, background = 'default', }) => {
    return (jsxs(Box, { background: background, direction: 'row', alignItems: 'center', height: height ?? 6, gap: 'small', style: { padding: '0 1.5rem 0 0.75rem' }, children: [preactions && (jsx(Box, { direction: 'row', background: 'none', alignItems: 'center', justifyContent: 'start', style: { paddingLeft: '1rem', overflow: 'unset', flex: 0 }, children: Children.map(preactions, (a, i) => a) })), showLogo && jsx(AppLogo, { title: title }), actions && (jsx(Box, { direction: 'row', background: 'none', alignItems: 'center', justifyContent: 'end', gap: 'small', style: { flex: 1, overflow: 'unset' }, children: Children.map(actions, (a, i) => a) }))] }));
};
const AppBarWithActions = ({ user, helpOptions, onHelpOption, onLogout, onAppsOption, actions, color = 'secondary', ...props }) => {
    const [profileAnchor, setProfileAnchor] = useState(null);
    const [helpAnchor, setHelpAnchor] = useState(null);
    const [appsAnchor, setAppsAnchor] = useState(null);
    useTheme();
    const _actions = [...[actions]];
    const _preactions = [];
    // handles the apps pane close
    const handleAppPaneClose = () => {
        setAppsAnchor(null);
    };
    // checks whether the apps button can be shown
    const canShowAppsButton = () => {
        if (user == null || user.ConfiguredApps == null) {
            return false;
        }
        const configuredApps = user.ConfiguredApps;
        let authorisedApps = 0;
        for (let index in configuredApps) {
            let userApp = configuredApps[index];
            if (userApp.IsAuthorised) {
                ++authorisedApps;
            }
            // apps button is shown when there are more than one authorised apps for the user
            if (authorisedApps > 1) {
                return true;
            }
        }
        return false;
    };
    if (helpOptions)
        _actions.push(jsx(AboutButton, { onClick: (e) => setHelpAnchor(e.currentTarget) }));
    if (user)
        _actions.push(jsx(Button, { shape: 'round', variant: 'contained', size: 'large', border: true, color: color, onClick: (e) => setProfileAnchor(e.currentTarget), children: jsx(Typography, { variant: 'h4', sx: { color: 'currentColor' }, children: user?.initials ?? user?.name?.[0] ?? 'U' }) }));
    if (canShowAppsButton()) {
        _preactions.push(jsx(GridButton, { size: 'medium', onClick: (e) => {
                setAppsAnchor(e.currentTarget);
            } }));
    }
    return (jsxs("div", { children: [jsx(AppBar, { preactions: _preactions, actions: _actions, ...props }), jsx(Menu, { open: Boolean(appsAnchor), anchorEl: appsAnchor, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'right' }, onClose: () => {
                    setAppsAnchor(null);
                }, TransitionComponent: Grow, MenuListProps: { className: 'MuiMenu-list-flat-pane' }, children: appsAnchor && jsx(AppsPane, { onClose: handleAppPaneClose, user: user }) }), jsxs(Menu, { open: Boolean(profileAnchor), anchorEl: profileAnchor, anchorOrigin: { vertical: 'center', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'right' }, onClose: () => {
                    setProfileAnchor(null);
                }, TransitionComponent: Grow, MenuListProps: { className: 'MuiMenu-list-withArrow' }, children: [jsx(UserTile, { user: user, background: 'light', avatarBackground: 'tertiary' }), onLogout && jsx(Divider, { variant: 'menu' }), onLogout && (jsx(MenuItem, { onClick: () => {
                            setProfileAnchor(null);
                            onLogout();
                        }, children: "Sign Out" }))] }), jsx(Menu, { open: Boolean(helpAnchor), anchorEl: helpAnchor, anchorOrigin: { vertical: 'center', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'right' }, onClose: () => {
                    setHelpAnchor(null);
                }, TransitionComponent: Grow, MenuListProps: { className: 'MuiMenu-list-withArrow' }, children: helpOptions?.map((option, index) => [
                    index !== 0 ? jsx(Divider, { variant: 'menu' }) : null,
                    jsx(MenuItem, { onClick: () => {
                            setHelpAnchor(null);
                            onHelpOption && onHelpOption(option);
                        }, children: option }, index),
                ]) })] }));
};

export { AppBar, AppBarWithActions };
