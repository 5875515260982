import { jsx, jsxs } from 'react/jsx-runtime';
import { Typography } from '@mui/material';
import { Info48Filled } from '@fluentui/react-icons';
import { Box } from '../Box.js';
import { palette } from '../../themes/light.theme.js';

const ItemEmptyState = ({ repositoryUrl, }) => {
    return (jsx(Box, { background: 'none', alignItems: 'center', justifyContent: 'center', children: jsxs(Box, { rounded: 'all', height: 'auto', alignItems: 'center', justifyContent: 'center', style: {
                border: '1px solid #DCE1EF',
                backgroundColor: '#F4F6FB',
                padding: '3.188rem 3.938rem 2.938rem',
            }, children: [jsx(Info48Filled, { style: {
                        marginBottom: '2.25rem',
                        width: '2.5rem',
                        height: '2.5rem',
                        color: '#9EA7BF',
                    } }), jsx(Typography, { variant: 'h2', sx: {
                        width: '18.188rem',
                        textAlign: 'center',
                        marginBottom: '1rem',
                        whiteSpace: 'break-spaces',
                    }, children: "This item does not contain previewable content" }, 'no-item-previewable'), jsxs(Typography, { variant: 'body1', sx: {
                        width: '18.188rem',
                        textAlign: 'center',
                    }, children: [jsx(Typography, { component: 'a', variant: 'body1', href: repositoryUrl, target: '_blank', sx: {
                                color: palette.aqua[800],
                                textDecoration: 'none',
                            }, children: "Click here" }), ' ', "to attempt to open the information in the source repository."] }, 'no-item-desc')] }) }));
};

export { ItemEmptyState };
