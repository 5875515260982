import { useGetItemCountQuery, useGetDeferredItemCountQuery, useGetAllExceptionsItemCountQuery, useGetRetentionConflictItemCountQuery, useGetRejectedInOtherRequestItemCountQuery } from '../services/itemApi.js';

const useItemGridFilterCount = ({ disposalId }) => {
    //Gets the total number of items on disposal request
    const { data: all } = useGetItemCountQuery({
        disposalId
    });
    const { data: deferredItems } = useGetDeferredItemCountQuery({
        disposalId
    });
    //Gets the total number of items on disposal request with exceptions
    const { data: allExceptions } = useGetAllExceptionsItemCountQuery({
        disposalId
    });
    //Gets the total number of items on disposal request with actioning required exceptions
    const { data: retentionConflict } = useGetRetentionConflictItemCountQuery({
        disposalId
    });
    //Gets the total number of items on disposal request with actioning required exceptions
    const { data: rejectedInOtherRequest } = useGetRejectedInOtherRequestItemCountQuery({
        disposalId
    });
    return { all, deferredItems, allExceptions, retentionConflict, rejectedInOtherRequest };
};

export { useItemGridFilterCount };
