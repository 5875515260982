import { useRef, useEffect, useCallback } from 'react';

const useSearch = ({ treeGridData, gridRef, menuItem }) => {
    const searchTermRef = useRef('');
    useEffect(() => {
        // this is to trigger search when changing menu item from the dropdown
        if (treeGridData?.length && searchTermRef.current) {
            getSearchResult(searchTermRef.current);
        }
    }, [menuItem, treeGridData]);
    const getSearchResult = useCallback((value) => {
        const filteredData = searchTree(treeGridData, value) ?? [];
        gridRef.current.dataSource = filteredData;
    }, [treeGridData]);
    const handleSearch = useCallback((value) => {
        if (!gridRef.current || !treeGridData?.length)
            return;
        searchTermRef.current = value;
        getSearchResult(value);
    }, [treeGridData, gridRef]);
    // Function to search tree data.
    const searchTree = (data, value) => {
        // if the search term is empty return the data from the selected filter
        // this is to display the selected property data.
        if (!value)
            return treeGridData;
        const result = [];
        data?.forEach((node) => {
            let _value = null;
            if (node?.value && typeof node?.value === "boolean") {
                _value = node?.value == true ? 'Yes' : 'No';
            }
            // check the value or property name if it is matched to the search term
            // Search fields are propertyName and value (this is based on the PBI description).
            if ((typeof node?.value === "object" && node?.value?._Display?.toLowerCase().includes(value?.toLowerCase())) ||
                (typeof node?.value === "string" && node?.value?.toLowerCase().includes(value?.toLowerCase())) ||
                (typeof node?.value === "number" && node?.value?.toString().includes(value?.toLowerCase())) ||
                (typeof node?.value === "boolean" && _value?.toLowerCase().includes(value?.toLowerCase())) ||
                node?.propertyName?.toLowerCase().includes(value?.toLowerCase()))
                result.push(node);
            // check if the item has children/items, then perform the search.
            else if (node?.Items) {
                // call recursive function here to get the search item inside of Item property.
                const childResults = searchTree(node.Items, value);
                // if the recursion has returned value push it to the result array
                if (childResults.length) {
                    const clonedNode = { ...node };
                    clonedNode.Items = childResults;
                    result.push(clonedNode);
                }
            }
        });
        return result;
    };
    return { handleSearch };
};

export { useSearch };
