import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import '../auth/config.js';
import 'react/jsx-runtime';
import { config } from '../config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'react';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import { prepareHeaders } from '../auth/prepareHeaders.js';

const ENDPOINT = 'Item';
const BASE_IMG_URL = `${config.REACT_APP_ENC_IMC_URL}/`;
const itemApi = createApi({
    reducerPath: 'itemApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.API_BASE_URL,
        prepareHeaders,
    }),
    tagTypes: ['ItemDetails', 'BusinessType', 'BusinessTypeProperties', 'PropLinks', 'ItemImage', 'BTPropertiesTab', 'ITPropertiesTab'],
    endpoints: (builder) => ({
        // QUERIES
        getItemDetailsFullDetails: builder.query({
            query: ({ itemId }) => {
                return {
                    url: `${ENDPOINT}('${itemId}')?$properties=FullDetails`,
                    method: 'GET',
                };
            },
            providesTags: ['ItemDetails'],
        }),
        getPropLinks: builder.query({
            query: ({ itemType, itemId }) => {
                return {
                    url: `${config.API_BASE_URL}/${itemType}('${itemId}')?$properties=FullDetails&$meta=true`,
                    method: 'GET',
                };
            },
            providesTags: ['PropLinks'],
        }),
        getItemImage: builder.query({
            query: ({ itemId }) => {
                return {
                    //url: `${config.API_BASE_URL}/TypeDef?$filter=Name eq '${itemType}' and App/Name eq 'Enc'&$expand=PropLinks($expand=PropDef($select=*;$filter=IsRefProp eq true))`,
                    url: `${config.API_BASE_URL}/Enc.Image?$filter=ID eq ${itemId}`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                const url = response.value[0]?.Url ?? '';
                if (url !== '') {
                    return `${BASE_IMG_URL}${url}`;
                }
                return '';
            },
            providesTags: ['ItemImage'],
        }),
        getCollectionItemsCount: builder.query({
            query: ({ collectionId }) => {
                const BASE_URL = `${config.API_BASE_URL}/Enc.Item?`;
                return {
                    url: `${BASE_URL}$count=true&$top=0&$filter=(MemberOf eq ${collectionId}) or (AttachmentOfId eq ${collectionId}) and Status ne 'None'`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                return { count: response['@odata.count'] };
            },
        }),
        getItemDetailsMemberOf: builder.query({
            query: ({ itemId }) => {
                return {
                    url: `${ENDPOINT}('${itemId}')/?$expand=MemberOf($select=ID)`,
                    method: 'GET',
                };
            },
            providesTags: ['ItemDetails'],
        }),
        getItemDisposalDetails: builder.query({
            query: ({ itemId }) => {
                return {
                    url: `${ENDPOINT}('${itemId}')?$expand=DisposalDetails($expand=DisposalRequest($select=*;$expand=ApproverResponses($expand=Approver)))`,
                    method: 'GET',
                };
            },
        }),
        getItemDetailsApplicableClassifications: builder.query({
            query: ({ itemId }) => {
                return {
                    url: `${ENDPOINT}('${itemId}')/?$expand=ApplicableClassifications($count=true)`,
                    method: 'GET',
                };
            },
            providesTags: ['ItemDetails'],
        }),
        getBusinessTypeProperties: builder.query({
            query: ({ businessTypeId }) => {
                return {
                    url: `${businessTypeId}/GetEditablePropDefs?$properties=FullDetails`,
                    method: 'POST',
                };
            },
            providesTags: ['BusinessType'],
        }),
        getBusinessTypePropertyValues: builder.query({
            query: ({ businessTypeId, businessObjectId }) => {
                return {
                    url: `${businessTypeId}('${businessObjectId}')?$properties=FullDetails`,
                    method: 'GET',
                };
            },
            providesTags: ['BusinessTypeProperties'],
        }),
        getIcon: builder.query({
            query: ({ imageId }) => {
                return {
                    url: `Enc.Image?$filter=ID eq '${imageId}'`,
                    method: 'GET',
                };
            },
        }),
        getIconByTypeDefId: builder.query({
            query: ({ typeDefId }) => {
                return {
                    url: `TypeDef/?$filter= ID eq '${typeDefId}'`,
                    method: 'GET',
                };
            },
        }),
        getBusinessTypePropertiesPanelValues: builder.query({
            query: ({ typeDefId, itemId }) => {
                return {
                    url: `${typeDefId}('${itemId}')?$expand=BusinessObject($properties=SearchRules)`,
                    method: 'GET',
                };
            },
            providesTags: ['BusinessTypeProperties', 'BTPropertiesTab'],
        }),
        getItemTypeProperties: builder.query({
            query: ({ typeDefId, itemId }) => {
                return {
                    url: `${typeDefId}('${itemId}')?$properties=FullDetails&$meta=true`,
                    method: 'GET',
                };
            },
            providesTags: ['ItemDetails']
        }),
        getTreeGridData: builder.query({
            query: ({ businessTypeId }) => {
                return {
                    url: 'IMC.TreeGridData/GetTreeGridData?$preserverefs=false',
                    method: 'POST',
                    body: { businessTypeId },
                };
            },
            providesTags: ['BusinessTypeProperties'],
        }),
        getBusinessTypes: builder.query({
            query: () => {
                return {
                    url: `TypeDef?$filter=IsBusinessType eq true and Active eq true and Name ne 'BusinessObject'&$top=100&$orderBy=_Display&$expand=Category($select=InternalName)`,
                    method: 'GET',
                };
            },
            providesTags: ['BusinessType'],
        }),
        getEnums: builder.query({
            query: () => {
                return {
                    url: `TypeDef?$top=10000&$filter=TypeKind eq 'Enum'&$select=all&$expand=Members($select=all;$top=1000)`,
                    method: 'GET',
                };
            },
        }),
        getPerspectiveClasses: builder.query({
            query: () => {
                return {
                    url: '/BusinessClass?$filter=Active eq true&$top=1000000&$usesql=true',
                    method: 'GET',
                };
            },
        }),
        getPerspectiveClass: builder.query({
            query: ({ perspectiveClassId }) => {
                return {
                    url: `/BusinessClass('${perspectiveClassId}')`,
                    method: 'GET',
                };
            },
        }),
        getClassifications: builder.query({
            query: () => {
                return {
                    url: '/Classification?$top=1000000&$usesql=true',
                    method: 'GET',
                };
            },
        }),
        getBusinessCatalogNode: builder.query({
            query: () => {
                return {
                    url: '/IMC.BusinessCatalogNode/GetCatalogTree?$preserverefs=false',
                    method: 'POST',
                };
            },
        }),
        getItemCount: builder.query({
            query: ({ disposalId }) => {
                return {
                    url: `${ENDPOINT}?$count=true&$select=ID&$filter=DisposalDetails/Any(dd: dd/DisposalRequestId eq '${disposalId}') and Status ne 'None'`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                const getODataCount = (data) => {
                    let totalCount = 0;
                    const getODataCountRecursively = (obj) => {
                        if (typeof obj === 'object' && obj !== null) {
                            if ('@odata.count' in obj) {
                                totalCount += parseInt(obj['@odata.count']);
                            }
                            for (const key in obj) {
                                getODataCountRecursively(obj[key]);
                            }
                        }
                    };
                    getODataCountRecursively(data);
                    return totalCount;
                };
                return { count: getODataCount(response) };
            },
            providesTags: ['ItemDetails'],
        }),
        getDeferredItemCount: builder.query({
            query: ({ disposalId }) => {
                return {
                    url: `${ENDPOINT}?$count=true&$select=ID&$filter=DisposalDetails/Any(dd: dd/DisposalRequestId_IsDeferred eq '${disposalId}_true') and Status ne 'None'`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                const getODataCount = (data) => {
                    let totalCount = 0;
                    const getODataCountRecursively = (obj) => {
                        if (typeof obj === 'object' && obj !== null) {
                            if ('@odata.count' in obj) {
                                totalCount += parseInt(obj['@odata.count']);
                            }
                            for (const key in obj) {
                                getODataCountRecursively(obj[key]);
                            }
                        }
                    };
                    getODataCountRecursively(data);
                    return totalCount;
                };
                return { count: getODataCount(response) };
            },
            providesTags: ['ItemDetails'],
        }),
        getAllExceptionsItemCount: builder.query({
            query: ({ disposalId }) => {
                return {
                    url: `${ENDPOINT}?$count=true&$filter=DisposalRequests/Any(dr: dr/ID eq '${disposalId}') and (DisposalDetails/Any(dd: dd/RetentionConflict eq true or dd/RejectedInOtherRequest eq true))&$top=1&$select=ID`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                return { count: response['@odata.count'] };
            },
            providesTags: ['ItemDetails'],
        }),
        getRetentionConflictItemCount: builder.query({
            query: ({ disposalId }) => {
                return {
                    url: `${ENDPOINT}?$count=true&$filter=DisposalRequests/Any(dr: dr/ID eq '${disposalId}') and DisposalDetails/Any(dd: dd/RetentionConflict eq true)&$top=1&$select=ID`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                return { count: response['@odata.count'] };
            },
            providesTags: ['ItemDetails'],
        }),
        getRejectedInOtherRequestItemCount: builder.query({
            query: ({ disposalId }) => {
                return {
                    url: `${ENDPOINT}?$count=true&$filter=DisposalRequests/Any(dr: dr/ID eq '${disposalId}') and DisposalDetails/Any(dd: dd/RejectedInOtherRequest eq true)&$top=1&$select=ID`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                return { count: response['@odata.count'] };
            },
            providesTags: ['ItemDetails'],
        }),
        getItemCategories: builder.query({
            query: () => {
                return {
                    url: 'Category?ds=5&$top=100&$orderBy=_Display',
                    method: 'GET',
                };
            },
        }),
        getEmailCopy: builder.query({
            query: ({ itemId }) => {
                return {
                    url: `EmailCopy?$select=all&$expand=ParentFolder($select=DisplayName),Mailbox($expand=Owner;$select=all),Email($expand=From;$select=all)&$filter=EmailID eq '${itemId}'&$top=5`,
                    method: 'GET',
                };
            },
        }),
        getFailureDetail: builder.query({
            query: () => {
                return {
                    url: `TypeDef?$filter=TypeKind eq 'Enum' AND Name eq 'FailureDetail' And AppId eq 1&$expand=Members($select=Name,Value,Caption;$top=1000)&$count=true`,
                    method: 'GET'
                };
            }
        }),
        //MUTATIONS
        removeItemsFromDisposalRequest: builder.mutation({
            query: ({ disposalId, body }) => {
                return {
                    url: `/DisposalRequest('${disposalId}')/RemoveItems`,
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                };
            },
        }),
        saveItemDetails: builder.mutation({
            query: ({ ID, TypeDefId, formDetails }) => ({
                url: `/${TypeDefId}('${ID}')`,
                method: 'PATCH',
                body: { ...formDetails },
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['BTPropertiesTab', 'ItemDetails']
        }),
    }),
});
const { 
// QUERIES
useGetIconQuery, useGetEnumsQuery, useGetEmailCopyQuery, useGetPropLinksQuery, useGetItemImageQuery, useGetItemCountQuery, useGetTreeGridDataQuery, useGetFailureDetailQuery, useGetBusinessTypesQuery, useGetItemCategoriesQuery, useGetClassificationsQuery, useGetIconByTypeDefIdQuery, useGetPerspectiveClassQuery, useGetDeferredItemCountQuery, useGetItemTypePropertiesQuery, useGetPerspectiveClassesQuery, useGetBusinessCatalogNodeQuery, useGetItemDetailsMemberOfQuery, useGetItemDisposalDetailsQuery, useGetCollectionItemsCountQuery, useGetItemDetailsFullDetailsQuery, useGetBusinessTypePropertiesQuery, useGetAllExceptionsItemCountQuery, useGetRetentionConflictItemCountQuery, useGetBusinessTypePropertyValuesQuery, useGetRejectedInOtherRequestItemCountQuery, useGetBusinessTypePropertiesPanelValuesQuery, useGetItemDetailsApplicableClassificationsQuery, 
// MUTATIONS
useRemoveItemsFromDisposalRequestMutation, useSaveItemDetailsMutation, } = itemApi;

export { itemApi, useGetAllExceptionsItemCountQuery, useGetBusinessCatalogNodeQuery, useGetBusinessTypePropertiesPanelValuesQuery, useGetBusinessTypePropertiesQuery, useGetBusinessTypePropertyValuesQuery, useGetBusinessTypesQuery, useGetClassificationsQuery, useGetCollectionItemsCountQuery, useGetDeferredItemCountQuery, useGetEmailCopyQuery, useGetEnumsQuery, useGetFailureDetailQuery, useGetIconByTypeDefIdQuery, useGetIconQuery, useGetItemCategoriesQuery, useGetItemCountQuery, useGetItemDetailsApplicableClassificationsQuery, useGetItemDetailsFullDetailsQuery, useGetItemDetailsMemberOfQuery, useGetItemDisposalDetailsQuery, useGetItemImageQuery, useGetItemTypePropertiesQuery, useGetPerspectiveClassQuery, useGetPerspectiveClassesQuery, useGetPropLinksQuery, useGetRejectedInOtherRequestItemCountQuery, useGetRetentionConflictItemCountQuery, useGetTreeGridDataQuery, useRemoveItemsFromDisposalRequestMutation, useSaveItemDetailsMutation };
