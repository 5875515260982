import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import '../auth/config.js';
import 'react/jsx-runtime';
import { config } from '../config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'react';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import { prepareHeaders } from '../auth/prepareHeaders.js';

const ENDPOINT = 'User';
const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.API_BASE_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getCurrentUser: builder.query({
            query: () => {
                return {
                    url: 'Me',
                    method: 'GET',
                };
            },
            transformResponse: (data) => {
                return {
                    ...data,
                    ConfiguredApps: data.ConfiguredApps
                        ? data.ConfiguredApps.value.map((app) => ({ ...app, ...app.App }))
                        : [],
                };
            },
        }),
        getAnalyticsUser: builder.query({
            query: () => {
                return {
                    url: 'User/GetAnalyticsUserDetails',
                    method: 'POST',
                };
            },
            transformResponse: (data) => {
                return {
                    ...data,
                    access_token: data.accessToken,
                    client_token: data.clientToken,
                };
            },
        }),
        getUser: builder.query({
            query: (user) => {
                const selectQuery = 'ID,DisplayName,Mail,Surname,GivenName';
                return {
                    url: `${ENDPOINT}?$select=${selectQuery}}&$filter=AuthIdentity eq '${user.username.toLowerCase()}'`,
                    method: 'GET',
                };
            },
            //return the first user in the list (should only be 1)
            transformResponse: ({ value: userList }) => userList?.[0],
        }),
        getUserServiceAccess: builder.query({
            query: () => {
                return {
                    url: 'UserServiceAccess',
                    method: 'GET',
                };
            },
        }),
    }),
});
const { useGetCurrentUserQuery, useGetAnalyticsUserQuery, useGetUserQuery, useGetUserServiceAccessQuery } = userApi;

export { useGetAnalyticsUserQuery, useGetCurrentUserQuery, useGetUserQuery, useGetUserServiceAccessQuery, userApi };
