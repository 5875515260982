import { useSensors, useSensor, PointerSensor } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { debounce } from 'lodash';
import { useRef, useState, useEffect, useCallback } from 'react';

const useOptionMultiSelect = ({ options, propertyValue = [], handleSearch, getSelectedOptions, shouldReplaceRetentionClasses }) => {
    const searchTermRef = useRef();
    const [optionList, setOptionList] = useState(options ?? []);
    const [showOptionList, setShowOptionList] = useState(false);
    const [isValidOptionList, setIsOptionListValid] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState(propertyValue ?? []);
    const filteredData = options?.filter((obj) => !selectedOptions.some((selectedObj) => selectedObj.ID === obj.ID));
    const sensors = useSensors(useSensor(PointerSensor, {
        activationConstraint: { delay: 150, tolerance: 5 },
    }));
    useEffect(() => {
        if (!options?.length)
            return;
        const searchTerm = searchTermRef.current;
        // This is to retain the current search item from the list
        if (searchTerm) {
            const searchResult = performSearch(searchTerm);
            setOptionList(searchResult);
            return;
        }
        setOptionList(filteredData);
    }, [selectedOptions, options]);
    const onSearch = useCallback(debounce((event) => {
        // perform default search
        const searchTerm = event.target.value;
        const searchResult = performSearch(searchTerm);
        setOptionList(searchResult);
        /**
         * Returns search term and search result (optional).
         *
         * @param {string} searchTerm term to search.
         * @param {T[]} searchResult search result based on the given search term.
         */
        handleSearch && handleSearch(searchTerm, searchResult);
    }, 500), [options, filteredData]);
    const performSearch = useCallback((searchTerm) => {
        searchTermRef.current = searchTerm;
        const searchResult = filteredData?.filter((option) => {
            const formattedOption = option?._Display.toLowerCase();
            const formattedSearchTerm = searchTerm.toLowerCase();
            return formattedOption.includes(formattedSearchTerm);
        });
        return searchResult;
    }, [options, filteredData]);
    const handleOnDelete = useCallback((index, approver) => {
        const array = [...selectedOptions];
        if (index !== -1) {
            array.splice(index, 1);
            setSelectedOptions(array);
            setOptionList([...optionList, approver]);
            // this will return the selected options.
            getSelectedOptions && getSelectedOptions(array);
            if (!array?.length)
                setIsOptionListValid(false);
        }
    }, [selectedOptions, optionList]);
    const handleOnSelect = useCallback((option) => {
        const newOptionList = optionList.filter((item) => item !== option);
        setOptionList(newOptionList);
        if (shouldReplaceRetentionClasses) {
            const newSelectedOptions = [...selectedOptions, option].filter((option) => !propertyValue.includes(option));
            setSelectedOptions(newSelectedOptions);
        }
        else
            setSelectedOptions((state) => [...new Set([...state, option])]);
        setIsOptionListValid(true);
        // this will return the selected options.
        getSelectedOptions && getSelectedOptions([...selectedOptions, option]);
    }, [selectedOptions, optionList, shouldReplaceRetentionClasses]);
    const handleDragEnd = useCallback(({ active, over }) => {
        if (active.id !== over.id) {
            setSelectedOptions((option) => {
                const oldIndex = option.findIndex((item) => item.ID === active.id);
                const newIndex = option.findIndex((item) => item.ID === over.id);
                return arrayMove(selectedOptions, oldIndex, newIndex);
            });
        }
    }, [selectedOptions]);
    return {
        sensors,
        onSearch,
        optionList,
        showOptionList,
        selectedOptions,
        isValidOptionList,
        handleDragEnd,
        handleOnSelect,
        handleOnDelete,
        setShowOptionList,
    };
};

export { useOptionMultiSelect };
