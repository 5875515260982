import '../auth/config.js';
import 'react/jsx-runtime';
import '../config.js';
import '../auth/AuthProvider.js';
import { useMsalAnalyticsUser, useMsalUser } from '../auth/hooks.js';
import { useState, useEffect } from 'react';
import '../services/requestApi.js';
import { useGetAnalyticsUserQuery, useGetCurrentUserQuery } from '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';

function useUser(isAnalyticsUser) {
    if (isAnalyticsUser) {
        const msalUser = useMsalAnalyticsUser();
        const [user, setAnalyticsUser] = useState();
        const { data: analyticsUser, isError, isFetching } = useGetAnalyticsUserQuery(undefined, {
            skip: !msalUser?.localId,
        });
        useEffect(() => {
            setAnalyticsUser({ ...msalUser, ...analyticsUser, isUserApiError: isError, isUserApiFetching: isFetching });
        }, [msalUser, analyticsUser, isError, isFetching]);
        return user;
    }
    else {
        const msalUser = useMsalUser();
        const [user, setUser] = useState();
        const { data: apiUser, isError, isFetching } = useGetCurrentUserQuery(undefined, {
            skip: !msalUser?.localId,
        });
        useEffect(() => {
            setUser({ ...msalUser, ...apiUser, isUserApiError: isError, isUserApiFetching: isFetching });
        }, [msalUser, apiUser, isError, isFetching]);
        return user;
    }
}
function useIMCUser(imcUser) {
    const [user, setUser] = useState();
    const { data: imcApiUser, isError: imcIsError, isFetching: imcIsFetching } = useGetCurrentUserQuery(imcUser, {
        skip: !imcUser?.localId,
    });
    useEffect(() => {
        if (imcUser !== user?.imcUser ||
            imcApiUser !== user?.imcApiUser ||
            imcIsError !== user?.isUserApiError ||
            imcIsFetching !== user?.isUserApiFetching) {
            setUser(prevUser => ({
                ...prevUser,
                ...imcUser,
                ...imcApiUser,
                isUserApiError: imcIsError,
                isUserApiFetching: imcIsFetching,
            }));
        }
    }, [imcIsError, imcIsFetching]);
    return user;
}

export { useIMCUser, useUser };
