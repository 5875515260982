import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { Checkmark20Filled, Add16Regular, Delete16Regular, Edit16Regular } from '@fluentui/react-icons';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Edit, Toolbar, Resize, Freeze } from '@syncfusion/ej2-react-treegrid';
import { forwardRef, useMemo, memo } from 'react';
import { useCustomTypeTreeGrid } from '../hooks/useCustomTypeTreeGrid.js';
import '../auth/config.js';
import '../config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import { formatCustomTypeDate } from '../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '@mui/material';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import { Box } from './Box.js';
import { TextIconButton } from './button/TextIconButton.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '@pdftron/webviewer';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { isPerspectiveClass } from './item/ItemPropertiesUtil.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import './chip/ItemManagementStatusChip.js';
import { PerspectiveClassChip } from './chip/PerspectiveClassChip.js';
import { isBoolean, isDate, isDateTime } from './item/utils.js';
import { SearchField } from './SearchField.js';

const CustomTypeTreeGrid = forwardRef(({ propertyDetails, treeGridData, handleAdd, handleDelete, handleActionComplete, }, treeGridRef) => {
    const { editOptions, isAllowAdding, isAllowEditing, isAllowDeleting, editTemplate, load, onSearch, handleEdit, onActionBegin, onClickAddButton, handleRowSelecting, onClickDeleteButton, } = useCustomTypeTreeGrid({
        treeGridData,
        treeGridRef,
        propertyDetails,
        handleAdd,
        handleDelete,
    });
    const hoverAttributes = { class: 'editable-column' };
    const memoizedValueColumnTemplate = useMemo(() => {
        return (item) => {
            const isEditable = !item?.fieldDetails?.IsCollection && !item?.childRecords?.length;
            // Render perspective class
            if (item.value && isPerspectiveClass(item.value))
                return (jsx("div", { className: isEditable ? 'editable-cell' : '', children: jsx(PerspectiveClassChip, { businessClass: item.value }) }));
            // Render boolean
            else if ((item.value !== null || item.value !== '') &&
                isBoolean(item?.fieldDetails)) {
                let booleanValue = '';
                if (item.value === true || item.value === 'true')
                    booleanValue = 'Yes';
                else if (item.value === false || item.value === 'false')
                    booleanValue = 'No';
                return (jsx("div", { className: isEditable ? 'editable-cell' : '', children: booleanValue }));
            }
            // Render date
            else if (item.value &&
                (isDate(item?.fieldDetails) || isDateTime(item?.fieldDetails)))
                return (jsx("div", { className: isEditable ? 'editable-cell' : '', children: formatCustomTypeDate(item.value, isDateTime(item?.fieldDetails)) }));
            return (jsx("div", { className: isEditable ? 'editable-cell' : '', children: item?.value }));
        };
    }, []);
    const memoizedImportantColumnTemplate = useMemo(() => {
        return (item) => {
            switch (item.important) {
                case 'Yes':
                    return (jsx(Box, { background: 'none', children: jsx(Checkmark20Filled, {}) }));
                case 'No':
                    return '';
                default:
                    return item.important;
            }
        };
    }, []);
    return (jsxs(Fragment, { children: [jsxs(Box, { direction: 'row', background: 'none', alignItems: 'center', justifyContent: 'space-between', style: { marginBottom: 0, paddingTop: 0, minHeight: '5.5rem' }, children: [jsx(SearchField, { height: '3rem', width: '17.188rem', onHandleSearch: onSearch }), jsxs(Box, { background: 'none', direction: 'row', justifyContent: 'end', children: [jsx(TextIconButton, { text: 'Add', startIcon: jsx(Add16Regular, {}), sx: { padding: '0.5rem 1.5rem' }, onClick: onClickAddButton, disabled: !isAllowAdding }), jsx(TextIconButton, { text: 'Delete', startIcon: jsx(Delete16Regular, {}), sx: { padding: '0.5rem 1.5rem' }, onClick: onClickDeleteButton, disabled: !isAllowDeleting }), jsx(TextIconButton, { text: 'Edit', startIcon: jsx(Edit16Regular, {}), sx: { padding: '0.5rem 1.5rem' }, onClick: handleEdit, disabled: !isAllowEditing })] })] }), jsx(Box, { background: 'none', children: jsxs(TreeGridComponent, { height: '100%', enablePersistence: true, ref: treeGridRef, allowSorting: true, treeColumnIndex: 0, childMapping: 'Items', allowResizing: true, editSettings: editOptions, className: 'item-custom-type-tree-grid', id: 'item-custom-type-tree-grid', load: load, actionBegin: onActionBegin, rowSelecting: handleRowSelecting, actionComplete: handleActionComplete, children: [jsxs(ColumnsDirective, { children: [jsx(ColumnDirective, { width: '448', field: 'propertyName', allowEditing: false, headerText: 'PROPERTY NAME' }), jsx(ColumnDirective, { width: '448', field: 'value', edit: editTemplate, headerText: 'PROPERTY VALUE', template: memoizedValueColumnTemplate, type: 'string', customAttributes: hoverAttributes }), jsx(ColumnDirective, { field: 'important', allowEditing: false, headerText: 'IMPORTANT', template: memoizedImportantColumnTemplate })] }), jsx(Inject, { services: [Sort, Edit, Toolbar, Resize, Freeze] })] }) })] }));
});
const MemoizedCustomTypeTreeGrid = memo(CustomTypeTreeGrid);

export { CustomTypeTreeGrid, MemoizedCustomTypeTreeGrid };
