import { createSlice } from '@reduxjs/toolkit';
import { downloadFileThunk } from '../thunks/downloadFileThunk.js';

const initialState = {
    blob: null,
    loading: false,
    error: null,
};
const downloadFileSlice = createSlice({
    name: 'downloadFile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(downloadFileThunk.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
            .addCase(downloadFileThunk.fulfilled, (state, action) => {
            state.loading = false;
            state.blob = action.payload;
        })
            .addCase(downloadFileThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});
var downloadFileReducer = downloadFileSlice.reducer;

export { downloadFileReducer as default };
