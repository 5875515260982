const MsalAccountToUser = (account) => {
    return {
        localId: account?.localAccountId,
        name: account?.name,
        username: account?.username,
        initials: getInitials(account?.name),
    };
};
const MsalAccountToAnalyticsUser = (account) => {
    return {
        localId: account?.localAccountId,
        name: account?.name,
        username: account?.username,
        initials: getInitials(account?.name),
    };
};
const getInitials = (name) => name
    ?.toUpperCase()
    .split(' ')
    .map((n) => n.substring(0, 1))
    .join('');

export { MsalAccountToAnalyticsUser, MsalAccountToUser, getInitials };
