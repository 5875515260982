import { jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { Chip } from './Chip.js';
import { getImage } from '../../util/data-utils.js';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '@mui/material';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import { ImageWithFallback } from '../grid/components/ImageWithFallback.js';

const BusinessTypeChip = ({ imageId, ...props }) => {
    //TODO: replace this with RTKQuery API call so that it is cached.
    const [businessImage, setBusinessImage] = useState();
    useEffect(() => {
        (async () => {
            setBusinessImage(await getImage(imageId));
        })();
    }, []);
    return (jsx(Chip, { title: props.title, icon: jsx(ImageWithFallback, { src: businessImage }), border: false, subTitle: props.subTitle }));
};

export { BusinessTypeChip };
