import { jsx, jsxs } from 'react/jsx-runtime';
import { memo, useRef, useState, useEffect, useCallback } from 'react';
import { Box } from './Box.js';
import { useTheme, TextField, List } from '@mui/material';
import { ChevronUp20Regular, ChevronDown20Regular } from '@fluentui/react-icons';
import { Button } from './button/Button.js';
import { DeleteButton } from './button/CustomButton.js';
import { OptionSelectItem } from './OptionSelectItem.js';
import { Typography } from './Typography.js';

const OptionSelect = memo(({ options, itemIcon, placeholder, handleDelete, handleSearch, handleSelect, hasIcon = true, selectedOption, isValidOptionList = true, }) => {
    const theme = useTheme();
    const searchTermRef = useRef();
    const [optionList, setOptionList] = useState(options);
    const [showOptionList, setShowOptionList] = useState(false);
    useEffect(() => {
        if (!options?.length)
            return;
        const searchTerm = searchTermRef.current;
        if (searchTerm) {
            const searchResult = performSearch(searchTerm);
            setOptionList(searchResult);
        }
        else {
            setOptionList(options);
        }
    }, [options]);
    const borderColor = isValidOptionList
        ? theme.palette.info[600]
        : theme.palette.warning.main;
    const onSearch = useCallback((event) => {
        // perform default search
        const searchTerm = event.target.value;
        const searchResult = performSearch(searchTerm);
        setOptionList(searchResult);
        /**
         * Returns search term and search result (optional).
         *
         * @param {string} searchTerm term to search.
         * @param {T[]} searchResult search result based on the given search term.
         */
        handleSearch && handleSearch(searchTerm, searchResult);
    }, [options]);
    const performSearch = useCallback((searchTerm) => {
        searchTermRef.current = searchTerm;
        const searchResult = options?.filter((option) => {
            const formattedOption = option?._Display.toLowerCase();
            const formattedSearchTerm = searchTerm.toLowerCase();
            return formattedOption.includes(formattedSearchTerm);
        });
        return searchResult;
    }, [options]);
    const renderSelectedOption = () => {
        // if no selected option, render the placeholder.
        if (!selectedOption) {
            return placeholder;
        }
        // if the selected option is a string
        if (typeof selectedOption === 'string') {
            return jsx(Typography, { variant: 'body2', children: selectedOption });
        }
        // render a custom element for the selected option.
        return selectedOption;
    };
    return (jsx(Box, { width: '25rem', background: 'none', style: { marginBottom: '0.75rem', marginTop: '0.5rem' }, children: jsxs(Box, { background: 'none', children: [jsx(Button, { color: 'info', variant: 'outlined', endIcon: jsxs(Box, { background: 'none', direction: 'row', alignItems: 'center', children: [selectedOption && !!handleDelete ? (jsx(DeleteButton, { onClick: (e) => {
                                    e.stopPropagation();
                                    handleDelete();
                                }, size: 'medium', sx: {
                                    width: '1.25rem',
                                    height: '1.25rem',
                                    marginRight: '0.25rem',
                                } })) : null, showOptionList ? (jsx(ChevronUp20Regular, { color: theme.palette.primary.main })) : (jsx(ChevronDown20Regular, { color: theme.palette.primary.main }))] }), sx: {
                        borderColor,
                        width: '25rem',
                        height: '3rem',
                        fontFamily: 'Avenir Roman',
                        justifyContent: 'space-between',
                    }, onClick: () => {
                        setShowOptionList((prevState) => !prevState);
                    }, children: renderSelectedOption() }), jsxs(Box, { background: 'none', style: {
                        position: 'absolute',
                        background: 'white',
                        marginTop: '3.313rem',
                        borderRadius: '0.5rem',
                        display: showOptionList ? 'unset' : 'none',
                        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.15)',
                        border: `0.063rem solid ${theme.palette.info[600]}`,
                    }, direction: 'column', children: [jsx(TextField, { size: 'small', sx: {
                                height: '2.5rem',
                                width: '23.4rem',
                                margin: '0.5rem 0.75rem',
                            }, onChange: (event) => onSearch(event), placeholder: 'Search' }), optionList?.length ? (jsx(List, { sx: {
                                padding: 0,
                                width: '25rem',
                                overflow: 'auto',
                                maxHeight: '11rem',
                                borderRadius: '0.5rem',
                            }, children: optionList.map((option, index) => (jsx(OptionSelectItem, { option: option, hasIcon: hasIcon, itemIcon: itemIcon, handleSelect: handleSelect }, `${option?.ID}${index}`))) })) : (jsx(Typography, { variant: 'body2', ml: 2, mb: 2, children: "No records to display" }))] })] }) }));
});

export { OptionSelect };
