import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import '../../../services/requestApi.js';
import '../../../services/userApi.js';
import { useGetFailureDetailQuery } from '../../../services/itemApi.js';
import '../../../services/requestDetailsApi.js';
import { Box } from '../../Box.js';
import { Tooltip } from '../../Tooltip.js';
import { Typography } from '@mui/material';
import { palette } from '../../../themes/light.theme.js';
import { memo } from 'react';

const DestructionFailureTemplate = ({ item }) => {
    const { data } = useGetFailureDetailQuery({});
    const _lookup = data?.value[0]?.Members?.value;
    const failureDetails = _lookup
        ?.map((x) => {
        const failureDetail = item?.DisposalDetails?.value?.find((y) => x?.Name === y?.FailureDetail);
        if (!!failureDetail)
            return { ...x, ...failureDetail };
    })
        .filter((z) => !!z);
    return (jsx(Fragment, { children: failureDetails?.map((detail, index) => (jsx(Box, { background: 'none', style: {
                minHeight: '3.25rem',
            }, children: jsx(Tooltip, { title: jsxs(Box, { background: 'none', children: [jsx(Typography, { variant: 'body2', sx: { color: palette.purewhite }, children: detail?.Caption }), jsx(Typography, { variant: 'body2', sx: { color: palette.purewhite }, children: item?.Repository?.DisplayName })] }), arrow: true, placement: 'top', children: jsx("div", { children: jsxs(Box, { background: 'none', children: [jsx(Typography, { variant: 'body2', sx: { color: palette.juice[900] }, children: detail?.Caption }), jsx(Typography, { variant: 'body2', sx: { color: palette.bley[700] }, children: item?.Repository?.DisplayName })] }) }) }) }, `${index}-${detail.Caption}`))) }));
};
const MemoizedDestructionFailureTemplate = memo(DestructionFailureTemplate);

export { DestructionFailureTemplate, MemoizedDestructionFailureTemplate };
