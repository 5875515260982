import { jsxs, jsx } from 'react/jsx-runtime';
import { Typography } from '@mui/material';
import 'react';
import '../config.js';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '@pdftron/webviewer';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import 'lodash';
import { useTooltip } from '../hooks/useTooltip.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import { Box } from './Box.js';
import { Tooltip } from './Tooltip.js';

const InfoPanel = ({ title, children, inset = 0, style, ...props }) => {
    const { dataRef, title: tooltipTitle } = useTooltip(title);
    return (jsxs(Box, { padding: 'large', background: 'none', style: {
            paddingLeft: inset,
            overflow: 'visible',
            ...style,
        }, ...props, children: [title && (jsx(Tooltip, { title: tooltipTitle, arrow: true, children: jsx(Typography, { variant: 'h2', sx: {
                        margin: '2rem 0 2rem 0',
                    }, ref: dataRef, children: title }) })), jsx(Box, { background: 'none', style: {
                    overflow: 'visible',
                }, children: children })] }));
};

export { InfoPanel };
