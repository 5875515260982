import { jsx } from 'react/jsx-runtime';
import { memo } from 'react';
import { Box } from '../../Box.js';
import { CommentText } from '../../CommentText.js';

const CommentTemplate = ({ comment, variant = 'body1', numberOfLines = 4, isURL, onClick, ...boxprops }) => {
    return (jsx(Box, { background: 'none', style: {
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        }, ...boxprops, children: jsx(CommentText, { comment: comment, variant: variant, numberOfLines: numberOfLines, isURL: isURL, onClick: onClick }) }));
};
const MemoizedCommentTemplate = memo(CommentTemplate);

export { CommentTemplate, MemoizedCommentTemplate };
