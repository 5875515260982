import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from './Box.js';
import { Typography } from '@mui/material';
import { EditButton } from './button/CustomButton.js';
import { useTooltip } from '../hooks/useTooltip.js';
import { Tooltip } from './Tooltip.js';

const DetailsRow = ({ label, data, alignData = false, size = 'large', onEdit, style }) => {
    const labelWidth = size === 'small' ? '8rem' : '14rem';
    const { dataRef: labelRef, title: tooltipTitleForLabel } = useTooltip(label);
    const { dataRef, title: tooltipTitleForData } = useTooltip(data);
    return (jsxs(Box, { background: 'none', direction: 'row', gap: 'small', rounded: 'round', style: {
            justifyContent: 'start !important',
            overflow: 'visible',
            margin: '1.25rem 0',
            minHeight: '2.5rem',
            ...style
        }, children: [jsx(Tooltip, { title: tooltipTitleForLabel, arrow: true, children: jsx(Typography, { variant: 'h5', ref: labelRef, sx: {
                        width: labelWidth,
                        minWidth: labelWidth,
                        maxWidth: labelWidth,
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        display: '-webkit-box',
                    }, children: label }) }), jsx(Box, { alignItems: 'start', background: 'none', style: {
                    marginTop: alignData ? '-0.375rem' : 0,
                    overflow: 'hidden',
                }, children: typeof data === 'string' ? (jsx(Tooltip, { title: tooltipTitleForData, arrow: true, children: jsx(Typography, { variant: 'body2', sx: {
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            display: '-webkit-box',
                            overflow: 'hidden',
                        }, ref: dataRef, children: data }) })) : (data) }), jsx(Box, { background: 'none', style: {
                    marginTop: '-0.5625rem',
                }, shrink: true, children: !!onEdit && jsx(EditButton, { onClick: onEdit }) })] }));
};

export { DetailsRow };
