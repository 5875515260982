import { jsx, jsxs } from 'react/jsx-runtime';
import { ChevronUpFilled, ChevronDownFilled } from '@fluentui/react-icons';
import { useTheme, TextField, InputAdornment, IconButton } from '@mui/material';

const NumberTextField = ({ value, onChange, handleIncrement, handleDecrement, allowSinglePeriod = true }) => {
    const theme = useTheme();
    const color = theme.palette.primary.main;
    const handleChange = (event) => {
        const currentValue = event.target.value;
        const newValue = allowSinglePeriod ? String(parseFloat(currentValue)) : String(parseFloat(currentValue)).split('.')[0];
        const isValid = allowSinglePeriod ? !isNaN(Number(newValue)) : Number.isInteger(Number(newValue));
        // Makes sure that (1) the value is a number, (2) an empty value is allowed, (3) the user can type in a - for a negative number, and (4) the user can type in a . for a decimal number
        if (isValid)
            onChange(newValue);
        else if (currentValue === '' || currentValue?.[0] === '-' || currentValue?.[0] === '.') {
            onChange(currentValue);
        }
    };
    return (jsx(TextField, { sx: {
            width: '100%',
            maxWidth: '21.75rem',
            height: '3rem',
            '& input[type=number]': {
                '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '-moz-appearance': 'textfield', // Firefox
            },
        }, value: value, onChange: handleChange, InputProps: {
            endAdornment: (jsxs(InputAdornment, { position: 'end', children: [jsx(IconButton, { edge: 'end', onClick: handleIncrement, children: jsx(ChevronUpFilled, { style: { color } }) }), jsx(IconButton, { edge: 'end', onClick: handleDecrement, children: jsx(ChevronDownFilled, { style: { color } }) })] })),
        } }));
};

export { NumberTextField };
