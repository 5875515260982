import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from '../Box.js';
import { useTheme, Dialog, DialogTitle, DialogContent, Typography, FormControlLabel, Checkbox, DialogActions } from '@mui/material';
import { useState } from 'react';
import { Button } from '../button/Button.js';
import { Edit20Regular } from '@fluentui/react-icons';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import { useGetClassificationsQuery } from '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import { OptionMultiSelect } from '../OptionMultiSelect.js';
import { Type } from '../SortableOptionList.js';
import { isEqual } from 'lodash';
import { palette } from '../../themes/light.theme.js';

const EditRetentionClassesDialog = ({ open, title, onSave, onClose, fieldLabel, propertyName, propertyValue, }) => {
    const theme = useTheme();
    const [selectedOptions, setSelectedOptions] = useState(propertyValue ?? []);
    const [shouldReplaceRetentionClasses, setShouldReplaceRetentionClasses] = useState(false);
    const { data } = useGetClassificationsQuery({});
    // TODO: Currently there's an error on saving other retention classes with TypeDefId (186)
    // TODO: Update this implementation, temporary fix to exclude retention class that causing errors when saving.
    const retentionClasses = data?.value?.filter((obj) => obj.$type.includes('Encompaas.Enweb.Domain.BusinessTransaction'));
    const getSelectedOptions = (_selectedOptions) => {
        setSelectedOptions(_selectedOptions);
    };
    const handleReplaceRetentionClassesCheckbox = (e) => {
        setShouldReplaceRetentionClasses(e.target.checked);
    };
    const disabledSaveButton = isEqual(propertyValue?.map((x) => x?.ID), selectedOptions?.map((y) => y?.ID)) || selectedOptions === propertyValue;
    return (jsxs(Dialog, { open: open, PaperProps: {
            sx: {
                width: '100%',
                height: '100%',
                maxWidth: '47.625rem',
                maxHeight: '42.438rem',
            },
        }, children: [jsxs(DialogTitle, { children: [jsx(Edit20Regular, {}), title] }), jsx(DialogContent, { children: jsxs(Box, { background: 'none', children: [jsx(Typography, { mb: 1, variant: 'body1', sx: { color: theme.palette.info.dark }, children: fieldLabel }), jsx(OptionMultiSelect, { type: Type.RETENTION, options: retentionClasses, hasDraggableItems: false, propertyValue: propertyValue ?? [], placeholder: '- Add a retention class -', getSelectedOptions: getSelectedOptions, shouldReplaceRetentionClasses: shouldReplaceRetentionClasses }), jsx(FormControlLabel, { label: 'Replace retention classes in selected items', control: jsx(Checkbox, { checked: shouldReplaceRetentionClasses, onChange: handleReplaceRetentionClassesCheckbox, disableRipple: true, sx: {
                                    strokeWidth: 1,
                                    stroke: palette.purewhite,
                                    borderRadius: '4px',
                                    color: palette.navy[100],
                                    '&.Mui-checked': {
                                        stroke: palette.aqua[900],
                                        color: palette.aqua[900],
                                    },
                                } }) })] }) }), jsxs(DialogActions, { children: [jsx(Button, { variant: 'contained', disabled: disabledSaveButton, onClick: () => !!onSave && onSave(selectedOptions, propertyName), children: "Save" }), jsx(Button, { variant: 'outlined', onClick: onClose, children: "Cancel" })] })] }));
};

export { EditRetentionClassesDialog };
