import { useState, useRef, useEffect } from 'react';
import { useAppDispatch } from '../store/store.js';
import 'react/jsx-runtime';
import 'react-redux';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import { updateItem, setCurrentItem } from '../store/slices/itemModalSlice.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import { useGetTreeGridDataQuery, useGetItemTypePropertiesQuery, useGetBusinessTypePropertiesPanelValuesQuery, useGetEnumsQuery, useGetPerspectiveClassesQuery, useGetClassificationsQuery } from '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { useBusinessType } from './useBusinessType.js';
import { useIcon } from './useIcon.js';
import { useItemDetails } from './useItemDetails.js';
import { useNavigate } from 'react-router-dom';
import { useBusinessTypePropertiesPanel } from './useBusinessTypePropertiesPanel.js';
import { merge } from 'lodash';
import { useBusinessTypePanel } from './useBusinessTypePanel.js';

const useItemPanel = ({ id, initialTab, onSelectTab, disposalRequest, setId, levels }) => {
    const navigate = useNavigate();
    // -----------------------------
    // State Declarations
    // -----------------------------
    // State for tab management
    const [selectedTab, setSelectedTab] = useState(initialTab);
    // State for item and related images/types
    const [businessTypeImageId, setBusinessTypeImageId] = useState();
    const [businessTypeId, setBusinessTypeId] = useState();
    const [typeDefImageId, setTypeDefImageId] = useState();
    const [itemImageId, setItemImageId] = useState();
    const [typeDefId, setTypeDefId] = useState();
    const [itemId, setItemId] = useState();
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [fullScreenTab, setFullScreenTab] = useState(false);
    const [hasPreviewableContent, setHasPreviewableContent] = useState(false);
    const gridRef = useRef(null);
    // -----------------------------
    // Data Fetching Hooks
    // -----------------------------
    // Fetch item details based on the given ID
    const { itemDetails, itemCollectionCount } = useItemDetails({
        id,
    });
    const dispatch = useAppDispatch();
    // this is for gettng some of the tree grid data property name.
    const { data: treeGridProperties } = useGetTreeGridDataQuery({
        businessTypeId,
    }, { skip: !businessTypeId });
    // Fetch properties related to the item type
    const { data: propertyDetails } = useGetItemTypePropertiesQuery({ typeDefId, itemId }, { skip: !typeDefId || !itemId });
    // Fetch business type properties and icons for a given item
    const { businessTypeProperties, businessTypePropertyValues: propertyValues, businessTypeIconDetails, } = useBusinessType({ item: itemDetails });
    // Get the Business Object value.
    const { data: businessTypePropertyDetails } = useGetBusinessTypePropertiesPanelValuesQuery({ typeDefId, itemId }, { skip: !typeDefId || !itemId || !businessTypeId });
    // Icon shown is in order of availability and priority:
    // 1. Business Type Icon
    // 2. File Type Icon
    // 3. Type Def Icon
    const imageId = businessTypeImageId ?? itemImageId ?? typeDefImageId;
    // Fetch icon details based on various image IDs with a fallback
    const { iconDetails } = useIcon({
        imageId,
        fallbackObj: itemDetails,
    });
    // this is for gettng the business type properties
    const { businessTypeTreeGridData } = useBusinessTypePropertiesPanel({
        item: itemDetails,
        businessTypeProperties,
        businessTypePropertyValues: propertyValues,
        treeGridProperties,
    });
    const businessTypePropertyValues = merge({}, propertyValues, businessTypePropertyDetails?.BusinessObject);
    const { formDetails } = useBusinessTypePanel({
        item: itemDetails,
        businessTypeProperties,
        businessTypePropertyValues
    });
    // -----------------------------
    // Effects
    // -----------------------------
    // Effect to update state values when itemDetails changes
    useEffect(() => {
        setBusinessTypeImageId(itemDetails?.BusinessType?._ImageId);
        setTypeDefImageId(itemDetails?.TypeDef?._ImageId);
        setBusinessTypeId(itemDetails?.BusinessTypeId);
        setItemImageId(itemDetails?._ImageId);
        setTypeDefId(itemDetails?.TypeDefId);
        setItemId(itemDetails?.ID);
    }, [initialTab, itemDetails, id]);
    useEffect(() => {
        if (!initialTab)
            return;
        setSelectedTab(initialTab);
    }, [initialTab]);
    //Effect to manage layout of tabs
    useEffect(() => {
        setFullScreenTab(['items'].some((x) => x === selectedTab));
    }, [selectedTab, id]);
    useEffect(() => {
        if (levels[levels.length - 1]?.pageIndex)
            setSelectedTab('items');
    }, [levels]);
    // SERVICES
    const { data: enums } = useGetEnumsQuery({});
    const { data: perspectiveClasses } = useGetPerspectiveClassesQuery({});
    useGetClassificationsQuery({});
    // -----------------------------
    // Handlers
    // -----------------------------
    const setPreviewState = (state) => {
        setHasPreviewableContent(state);
    };
    // Handler to manage tab selection and prop callback
    const handleSelectTab = (tab) => {
        setSelectedTab(tab);
        onSelectTab && onSelectTab(tab);
    };
    const navigateBreadcrumbs = (item) => {
        !!setId && setId(item.ID);
        setItemId(item.ID);
        setTypeDefId(item.TypeDefId);
        if (gridRef.current) {
            const buttonsInPager = gridRef.current
                .getPager()
                .querySelectorAll('button');
            if (buttonsInPager.length > 1) {
                buttonsInPager[0].click();
            }
            gridRef.current.refresh();
        }
    };
    const handleOpen = (item, target, effect) => {
        !effect ? setSelectedTab('summary') : effect();
        if (levels?.length > 0) {
            dispatch(updateItem({
                ...levels[levels.length - 1],
                isItemCollection: true,
                pageIndex: item?.page,
                pageSize: item?.pageSize,
                rowIndex: item?.index,
                sortSettings: item?.sortSettings,
            }));
        }
        dispatch(setCurrentItem({
            id: item.ID,
            itemId: item.ID,
            name: item.DisplayName,
            isItemCollection: item?.Members?.['@odata.count'] > 0,
            prevPageIndex: item?.page,
            prevPageSize: item?.pageSize,
            prevRowIndex: item?.index,
            prevSortSettings: item?.sortSettings,
        }));
        const _target = target ?? 'modal';
        switch (_target) {
            case 'new tab':
            case 'new window':
                window.open(`/item/${item.ID}?requestId=${id}${(disposalRequest?.Name && '&requestName=' + disposalRequest.Name) ??
                    ''}`, '_blank');
                break;
            case 'chromeless page':
                window.open(`/itempanel/${item.ID}`, '_blank');
                break;
            case 'modal':
                navigateBreadcrumbs(item);
                break;
            case 'new page':
            default:
                navigate(`/item/${item.ID}?requestId=${id}${(disposalRequest?.Name && '&requestName=' + disposalRequest.Name) ??
                    ''}`);
                break;
        }
    };
    const handleOpenItems = (item, target) => {
        onSelectTab && onSelectTab('items');
        const newItem = {
            ...item,
            pageIndex: 1,
        };
        handleOpen(newItem, target, () => setSelectedTab('items'));
    };
    const handleOpenURL = (url) => {
        if (url)
            window.open(url, '_blank');
    };
    const handleCheckbox = (item) => {
        setSelectedCheckbox(item);
    };
    const handleChangeTab = (tab) => {
        setSelectedTab(tab);
    };
    const showColumnChooser = () => {
        if (gridRef?.current) {
            gridRef.current.openColumnChooser(0, 0);
        }
    };
    return {
        iconDetails,
        itemDetails,
        selectedTab,
        handleSelectTab,
        propertyDetails,
        treeGridProperties,
        businessTypeProperties,
        businessTypeIconDetails,
        businessTypePropertyValues,
        itemCollectionCount,
        fullScreenTab,
        handleCheckbox,
        handleOpen,
        handleOpenURL,
        showColumnChooser,
        handleOpenItems,
        setPreviewState,
        selectedCheckbox,
        gridRef,
        businessTypeTreeGridData,
        hasPreviewableContent,
        handleChangeTab,
        formDetails,
        enums,
        perspectiveClasses,
    };
};

export { useItemPanel };
