import { jsx } from 'react/jsx-runtime';
import RetentionClass24 from '../../icons/RetentionClass24.js';
import { Chip } from './Chip.js';

const RetentionClassChip = ({ classification, border = true, ...props }) => {
    return (jsx(Chip, { ...props, border: border, title: classification?.DisplayName ||
            (classification &&
                typeof classification === 'object' &&
                classification.hasOwnProperty('Name') &&
                classification.Name) ||
            '', icon: jsx(RetentionClass24, { color: classification?.RootParentColor ?? undefined }), subTitle: classification?.FullPath, sx: { ...(border && { paddingLeft: '0.75rem' }), ...props.sx } }));
};

export { RetentionClassChip };
