import { useState, useEffect } from 'react';
import { DataManager, Query, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { publicClientApplication } from '../../auth/AuthProvider.js';
import { loginRequest } from '../../auth/config.js';
import 'react/jsx-runtime';
import { config } from '../../config.js';
import '@azure/msal-react';
import { DisposalRequestStatus, DisposalRequestResponseStatus } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';

class DisposalRequestAdaptor extends ODataV4Adaptor {
    status;
    userId;
    adminMode;
    constructor(status, userId, adminMode) {
        super();
        this.status = status;
        this.userId = userId;
        this.adminMode = !!adminMode;
    }
    processQuery(dm, query) {
        let original = super.processQuery.apply(this, arguments);
        //removes the tolower function from the query as it is not supported by the OData implementation
        if (original && typeof original.url === 'string') {
            original = { ...original, url: original.url.replace(/tolower/g, '') };
        }
        //combines the filter strings into one string using regex so that filtering works with the disposal status
        //TODO: refactor code to use the ODataAdaptor methods
        const filterRegex = /(\$filter=([^&]+))/g;
        let matches = [];
        let match;
        while ((match = filterRegex.exec(original.url)) !== null) {
            const fullMatch = match[0];
            const secondGroup = match[2];
            matches.push({ fullMatch, secondGroup });
        }
        if (matches.length > 1) {
            const queryStringWithoutSecondFilter = original.url.replace(matches[1].fullMatch, '');
            const queryStringWithModifiedFirstFilter = queryStringWithoutSecondFilter.replace(matches[0].fullMatch, `${matches[0].fullMatch} and ${matches[1].secondGroup}`);
            const filterCriteriaHasNull = `${matches[1].secondGroup}`.includes('null');
            if (!filterCriteriaHasNull) {
                original = { ...original, url: queryStringWithModifiedFirstFilter };
            }
        }
        return original;
    }
    processResponse() {
        // calling base class processResponse function
        var original = super.processResponse.apply(this, arguments);
        // parse the response data in ApproverResponses (not for admin mode)
        !this.adminMode &&
            Array.isArray(original.result) &&
            original.result?.forEach((item) => {
                item.Response = item.ApproverResponses?.value?.find((toUser) => toUser.ApproverIdToStatus === `${this.userId}_${this.status}`);
                //find the delegatedTo record for delegated responses
                if (this.status === DisposalRequestResponseStatus.Delegated &&
                    !!item.Response) {
                    item.DelegatedTo = item.ApproverResponses?.value?.find((toUser) => toUser.ID === item.Response.DelegatedToId);
                }
            });
        return {
            result: Array.isArray(original.result) ? original.result : null,
            count: original.count,
        };
    }
}
const useData = ({ status, user, gridData, adminMode = false, }) => {
    const [dataManager, setDataManager] = useState();
    //could also add token as a property rather than acquiring it here
    const acquireToken = async () => {
        const accounts = publicClientApplication.getAllAccounts();
        publicClientApplication.setActiveAccount(accounts[0]);
        const silentRequest = {
            ...loginRequest,
            account: accounts[0],
        };
        const authResult = await publicClientApplication.acquireTokenSilent(silentRequest);
        return authResult.accessToken;
    };
    async function fetchData() {
        try {
            const token = await acquireToken();
            const adminModeFilterString = status == DisposalRequestStatus.InProgressOrComplete ? status.split('Or').map(status => `(Status eq '${status}')`).join(' or ') : `Status eq '${status}'`;
            const filterString = adminMode
                ? adminModeFilterString
                : `ApproverResponses/ApproverIdToStatus eq '${user.ID}_${status}'`;
            const selectString = adminMode
                ? 'Name,DateRequested,DateCreatedInEnc,ActionedById,DateCompleted,Status'
                : 'Name,DateRequested,RequestComments,Status';
            const data = new DataManager({
                url: config.API_BASE_URL +
                    `/DisposalRequest?$select=${selectString}&$expand=ActionedBy,RequestedBy,ApproverResponses($select=ApproverId,Status,DateResponded,ResponseComments,Order,DelegatedToId,ApproverIdToStatus;$expand=Approver,DelegatedTo),RetentionClass($select=DisplayName,FullPath)&$filter=${filterString}&$count=true&$inlinecount=allpages`,
                adaptor: new DisposalRequestAdaptor(status, user.ID),
                headers: [{ Authorization: `Bearer ${token}` }],
            });
            const query = adminMode
                ? new Query()
                    .from('DisposalRequest')
                    .select(selectString)
                    .expand('ActionedBy,RequestedBy,ApproverResponses($select=ApproverId,Status,DateResponded,ResponseComments,Order,DelegatedToId,ApproverIdToStatus;$expand=Approver,DelegatedTo)')
                    .where('Status', 'equal', status)
                    .take(10)
                : new Query()
                    .from('DisposalRequest')
                    .select(selectString)
                    .expand('ActionedBy,RequestedBy,ApproverResponses($select=ApproverId,Status,DateResponded,ResponseComments,Order,DelegatedToId,ApproverIdToStatus;$expand=Approver,DelegatedTo)')
                    .where('ApproverResponses/ApproverIdToStatus', 'equal', `${user.ID}_${status}`)
                    .take(10);
            data.executeQuery(query).then((a) => {
                if (a.result['@odata.count'] == 0) {
                    setDataManager(null);
                }
                else {
                    setDataManager(data);
                }
            });
        }
        catch (error) {
            setDataManager(null);
        }
    }
    useEffect(() => {
        if (!gridData?.length)
            fetchData();
    }, [status, gridData]);
    return { dataManager };
};

export { useData as default };
