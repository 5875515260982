import { jsxs, jsx } from 'react/jsx-runtime';
import { memo } from 'react';
import { Box } from './Box.js';
import { useTheme, List, TextField, Typography } from '@mui/material';
import { FixedSizeList } from 'react-window';
import { ChevronUp20Regular, ChevronDown20Regular } from '@fluentui/react-icons';
import { Button } from './button/Button.js';
import { OptionSelectItem } from './OptionSelectItem.js';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { SortableOptionList, Type } from './SortableOptionList.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CollapsibleGroupedList } from './CollapsibleGroupedList.js';
import { useOptionMultiSelect } from '../hooks/useOptionMultiSelect.js';

const OptionMultiSelect = memo(({ groupBy, options, itemIcon, required, placeholder, handleSearch, propertyValue, hasIcon = true, getSelectedOptions, type = Type.DEFAULT, hasDraggableItems = true, shouldReplaceRetentionClasses = false, }) => {
    const theme = useTheme();
    const { sensors, onSearch, optionList, showOptionList, selectedOptions, isValidOptionList, handleDragEnd, handleOnSelect, handleOnDelete, setShowOptionList, } = useOptionMultiSelect({
        options,
        propertyValue,
        handleSearch,
        getSelectedOptions,
        shouldReplaceRetentionClasses,
    });
    const borderColor = !isValidOptionList && required
        ? theme.palette.warning.main
        : theme.palette.info[600];
    const renderDropdownList = () => {
        if (!optionList?.length) {
            return (jsx(Typography, { variant: 'body2', ml: 2, mb: 2, children: "No records to display" }));
        }
        if (groupBy) {
            return (jsx(CollapsibleGroupedList, { groupBy: groupBy, optionList: optionList, handleSelect: handleOnSelect }));
        }
        return (jsx(AutoSizer, { style: { height: 'auto', paddingBottom: '0.5rem' }, children: () => (jsx(FixedSizeList, { height: 208, itemCount: optionList.length, itemSize: 48, width: 473, children: ({ index, style }) => {
                    const option = optionList[index];
                    const key = `${option?.ID}-${index}`;
                    return (jsx("div", { style: style, children: jsx(OptionSelectItem, { type: type, option: option, hasIcon: hasIcon, itemIcon: itemIcon, handleSelect: handleOnSelect }, key) }));
                } })) }));
    };
    return (jsxs(Box, { width: '29.563rem', background: 'none', style: { marginBottom: '0.75rem', marginTop: '0.5rem' }, children: [selectedOptions?.length ? (hasDraggableItems ? (jsx(DndContext, { collisionDetection: closestCenter, onDragEnd: handleDragEnd, sensors: sensors, children: jsx(List, { dense: true, sx: {
                        padding: 0,
                        overflow: 'auto',
                        maxHeight: '12rem',
                        borderRadius: '0.5rem',
                        marginBottom: '0.75rem',
                        width: 'calc(100% - 0.125rem)',
                        border: `1px solid ${theme.palette.background.paper}`,
                    }, children: jsx(SortableContext, { items: selectedOptions?.map((item) => item.ID), children: selectedOptions?.map((option, index) => (jsx(SortableOptionList, { type: type, index: index, option: option, displayIndex: false, onDelete: () => handleOnDelete(index, option) }, `sortable_${option.ID}`))) }) }) })) : (jsx(List, { dense: true, sx: {
                    padding: 0,
                    overflow: 'auto',
                    maxHeight: '12rem',
                    borderRadius: '0.5rem',
                    marginBottom: '0.75rem',
                    width: 'calc(100% - 0.125rem)',
                    border: `1px solid ${theme.palette.background.paper}`,
                }, children: selectedOptions?.map((option, index) => (jsx(SortableOptionList, { type: type, index: index, option: option, displayIndex: false, hasDraggableItems: hasDraggableItems, onDelete: () => handleOnDelete(index, option) }, `sortable_${option.ID}`))) }))) : null, jsxs(Box, { background: 'none', children: [jsx(Button, { color: 'info', variant: 'outlined', sx: {
                            borderColor,
                            width: '100%',
                            height: '3rem',
                            fontFamily: 'Avenir Roman',
                            justifyContent: 'space-between',
                        }, endIcon: jsx(Box, { background: 'none', direction: 'row', alignItems: 'center', children: showOptionList ? (jsx(ChevronUp20Regular, { color: theme.palette.primary.main })) : (jsx(ChevronDown20Regular, { color: theme.palette.primary.main })) }), onClick: () => {
                            setShowOptionList((prevState) => !prevState);
                        }, children: placeholder }), jsxs(Box, { background: 'none', style: {
                            height: 'auto',
                            maxHeight: '17rem',
                            width: '29.563rem',
                            position: 'absolute',
                            background: 'white',
                            marginTop: '3.313rem',
                            borderRadius: '0.5rem',
                            display: showOptionList ? 'inherit' : 'none',
                            boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.15)',
                            border: `0.063rem solid ${theme.palette.info[600]}`,
                        }, direction: 'column', children: [jsx(TextField, { size: 'small', sx: {
                                    height: '2.5rem',
                                    minHeight: '2.5rem',
                                    margin: '0.5rem 0.75rem',
                                    width: 'calc(100% - 1.55rem)',
                                }, placeholder: 'Search', onChange: (event) => onSearch(event) }), renderDropdownList()] })] })] }));
});

export { OptionMultiSelect };
