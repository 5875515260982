import { jsxs, jsx } from 'react/jsx-runtime';
import { Edit20Regular } from '@fluentui/react-icons';
import { useTheme, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material';
import { useState, useCallback } from 'react';
import '../../config.js';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import '@pdftron/webviewer';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import { useGetBusinessTypesQuery } from '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import { useIcon } from '../../hooks/useIcon.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { Box } from '../Box.js';
import { Button } from '../button/Button.js';
import { BusinessTypeField } from './components/item/BusinessTypeField.js';
import '@syncfusion/ej2-react-calendars';
import '../item/utils.js';

const EditBusinessTypeDialog = ({ open, title, onSave, onClose, fieldLabel, propertyName, propertyDetails, }) => {
    const theme = useTheme();
    const [selectedBusinessType, setSelectedBusinessType] = useState(propertyDetails);
    const imageId = selectedBusinessType?._ImageId;
    const { iconDetails } = useIcon({
        imageId,
    });
    const { data } = useGetBusinessTypesQuery({});
    const handleSelectBusinessType = useCallback((businessType) => {
        setSelectedBusinessType(businessType);
    }, [propertyDetails]);
    const handleDelete = useCallback(() => {
        setSelectedBusinessType(null);
    }, [propertyDetails]);
    const currentBusinessType = selectedBusinessType?.Caption;
    const businessTypes = data?.value?.filter((businessType) => businessType.Caption !== currentBusinessType);
    const disabledSaveButton = selectedBusinessType?.Name === propertyDetails?.Name ||
        selectedBusinessType === propertyDetails;
    return (jsxs(Dialog, { open: open, PaperProps: {
            sx: {
                width: '100%',
                height: '100%',
                maxWidth: '47.5rem',
                maxHeight: '42.438rem',
            },
        }, children: [jsxs(DialogTitle, { children: [jsx(Edit20Regular, {}), title] }), jsx(DialogContent, { children: jsxs(Box, { background: 'none', children: [jsx(Typography, { mb: 1, variant: 'body1', sx: { color: theme.palette.info.dark }, children: fieldLabel }), jsx(BusinessTypeField, { iconDetails: iconDetails, handleDelete: handleDelete, businessTypes: businessTypes, selectedBusinessType: selectedBusinessType, handleSelectBusinessType: handleSelectBusinessType })] }) }), jsxs(DialogActions, { children: [jsx(Button, { disabled: disabledSaveButton, variant: 'contained', onClick: () => !!onSave && onSave(selectedBusinessType, propertyName), children: "Save" }), jsx(Button, { variant: 'outlined', onClick: onClose, children: "Cancel" })] })] }));
};

export { EditBusinessTypeDialog };
