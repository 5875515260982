import { jsxs, jsx } from 'react/jsx-runtime';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material';
import { Button } from '../button/Button.js';
import '@fluentui/react-icons';

const CustomActionModal = ({ title, icon, open, children, actionButton, size = 'medium', onClose, style }) => {
    const _minHeights = {
        small: '20rem',
        medium: '30rem',
        large: '45rem',
    };
    return (jsxs(Dialog, { open: open, PaperProps: {
            sx: {
                minWidth: '37.125rem',
                maxWidth: '45rem',
                minHeight: _minHeights[size],
                maxHeight: '60rem',
                ...style,
            },
        }, children: [jsxs(DialogTitle, { children: [!!icon && icon, title] }), jsx(DialogContent, { children: typeof children === 'string' ? (jsx(Typography, { variant: 'body1', children: children })) : (children) }), jsxs(DialogActions, { children: [actionButton, jsx(Button, { variant: 'outlined', onClick: onClose, children: "Cancel" })] })] }));
};

export { CustomActionModal, CustomActionModal as default };
