import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { useTheme, Typography } from '@mui/material';
import SvgEncompaasLogo from '../images/EncompaasLogo.js';
import { Banner } from './Banner.js';
import { Box } from './Box.js';

const AppLogo = ({ colour = 'none', title, bannerColour = 'alt-dark', className, textColour }) => {
    const theme = useTheme();
    return (jsx(Fragment, { children: jsxs(Box, { direction: 'row', height: 3.75, background: colour, children: [jsx(SvgEncompaasLogo, { height: '3.75rem' }), jsxs(Box, { gap: 'xsmall', justifyContent: 'center', alignItems: 'start', background: colour, style: title ? { paddingTop: '0.25rem' } : {}, children: [jsx(Banner, { height: title ? '0.7rem' : '1.25rem', colour: bannerColour, style: { marginLeft: 1.5, fill: theme.palette.info[textColour] } }), title && (jsx(Typography, { variant: 'h2', fontSize: '1.25rem', children: title }))] })] }) }));
};

export { AppLogo };
