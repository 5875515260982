import { jsx, jsxs } from 'react/jsx-runtime';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { Box } from '../../../Box.js';
import { OptionSelect } from '../../../OptionSelect.js';

const StyledImage = styled('img') ``;
const BusinessTypeField = memo(({ iconDetails, handleDelete, businessTypes, selectedBusinessType, handleSelectBusinessType, }) => {
    return (jsx(Box, { width: '29rem', background: 'none', children: jsx(OptionSelect, { handleSelect: handleSelectBusinessType, selectedOption: selectedBusinessType ? (jsxs(Box, { background: 'none', alignItems: 'center', direction: 'row', children: [!!iconDetails ? (jsx(StyledImage, { alt: iconDetails?.AltText, style: { height: '1.5rem' }, src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}` })) : null, jsx(Typography, { variant: 'body2', ml: 1, children: selectedBusinessType?.Caption })] })) : null, placeholder: '- Select a business type -', options: businessTypes, handleDelete: handleDelete }) }));
});

export { BusinessTypeField };
