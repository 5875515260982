const TEST_CONSTANTS = {
    APP_CONTAINER: 'APP_CONTAINER',
    HOME_PAGE: 'HOME_PAGE',
    REQUEST_PAGE: 'REQUEST_PAGE',
    APP_BAR: 'APP_BAR',
    LOGIN_PAGE: 'LOGIN_PAGE',
    ENCOMPAAS_LOGO: 'ENCOMPAAS_LOGO',
    ENCOMPAAS_BANNER: 'ENCOMPAAS_BANNER',
    LOGOUT_BUTTON: 'LOGOUT_BUTTON',
    HOME_BUTTON: 'HOME_BUTTON',
    ITEM_PAGE: 'ITEM_PAGE',
    PARENT_BUTTON: 'PARENT_BUTTON',
    EMPTY_STATE: 'EMPTY_STATE',
    TAB_PANEL: 'TAB_PANEL',
};

export { TEST_CONSTANTS };
