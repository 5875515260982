import { jsx, jsxs } from 'react/jsx-runtime';
import { Box } from './Box.js';
import { Typography } from '@mui/material';
import { ApplicationButton } from './ApplicationButton.js';
import { getLogoForApp } from '../util/appLogos.js';

const AppsSection = ({ title, children, background = 'none', style, applications, ...props }) => {
    return (jsx(Box, { background: background, gap: 'xsmall', justifyContent: 'start', alignItems: 'start', style: { justifyContent: 'start', flexGrow: 0 }, ...props, children: jsxs(Box, { style: { display: 'flex', alignSelf: 'start', background: 'none' }, children: [jsx(Typography, { style: {
                        padding: '0 0 0.625rem 0',
                        fontSize: '0.875rem',
                        fontWeight: 'normal',
                    }, children: title }), jsx("div", { style: {
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'start',
                        alignSelf: 'start',
                        flexWrap: 'wrap',
                        gap: '1rem',
                    }, children: applications.map((app) => {
                        return (jsx(ApplicationButton, { name: app.DisplayName, size: 'small', Logo: getLogoForApp(app.DisplayName), url: app.Homepage }, app.DisplayName));
                    }) })] }) }));
};

export { AppsSection };
