import '../services/requestApi.js';
import '../services/userApi.js';
import { useGetItemImageQuery } from '../services/itemApi.js';
import '../services/requestDetailsApi.js';

const useItemImage = ({ id }) => {
    const { data: itemImage, isLoading: isFetchingItemImage } = useGetItemImageQuery({
        itemId: id,
    }, { skip: !id });
    return {
        itemImage,
        isFetching: isFetchingItemImage,
    };
};

export { useItemImage };
