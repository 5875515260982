import { jsx, jsxs } from 'react/jsx-runtime';
import styled from '@emotion/styled';
import { ListItem, ListItemButton } from '@mui/material';
import { memo } from 'react';
import '../config.js';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import RetentionClass24 from '../icons/RetentionClass24.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '@pdftron/webviewer';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { useIcon } from '../hooks/useIcon.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import { Box } from './Box.js';
import { Type } from './SortableOptionList.js';
import { Typography } from './Typography.js';

const StyledImage = styled('img') ``;
const OptionSelectItem = memo(({ option, hasIcon, itemIcon, type = Type.DEFAULT, handleSelect, }) => {
    const { _Display: displayName, FullPath, RootParentColor } = option;
    const imageId = hasIcon && !itemIcon && !RootParentColor ? option?._ImageId : null;
    const { iconDetails } = useIcon({
        imageId,
    });
    const getOptionIcon = () => {
        // check if the icon is enabled and has item icon
        if (hasIcon && itemIcon) {
            return itemIcon;
        }
        // check if the icon is enabled and has icon details
        else if (hasIcon && iconDetails) {
            return (jsx(StyledImage, { alt: iconDetails.AltText, style: { height: '1.5rem' }, src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails.Url}` }));
        }
        // no icon will be displayed
        else {
            return null;
        }
    };
    return (jsx(ListItem, { disablePadding: true, children: jsxs(ListItemButton, { onClick: () => handleSelect(option), children: [type === Type.DEFAULT || type === Type.PERSPECTIVE ? (jsxs(Box, { background: 'none', direction: 'row', alignItems: 'center', gap: 0.5, children: [getOptionIcon(), jsx(Typography, { variant: 'body2', children: displayName })] })) : null, type === Type.RETENTION ? (jsxs(Box, { background: 'none', direction: 'row', alignItems: 'start', gap: 0.5, children: [jsx(RetentionClass24, { color: RootParentColor ?? undefined, style: { flexShrink: 0 } }), jsxs(Box, { direction: 'column', background: 'none', children: [jsx(Typography, { variant: 'body2', children: displayName }), jsx(Typography, { variant: 'body3', children: FullPath })] })] })) : null] }) }));
});

export { OptionSelectItem };
