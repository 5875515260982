import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { config } from '../config.js';

const AuthErrorComponent = ({ error }) => {
    return (jsxs("div", { children: ["An Error Occurred: ", error ? error.errorCode : 'unknown error'] }));
};
const RedirectOnError = ({ children }) => {
    // TODO: including useGetCurrentUserQuery here breaks Storybook for some reason
    // commenting this out for now.
    // const userQuery = useGetCurrentUserQuery();
    // if (userQuery.isError && (userQuery.error as any).data) {
    //   // Thread through error message to landing app
    //   window.location.href =
    //     config.REACT_APP_ENC_LANDING_APP_URL +
    //     'error/' +
    //     (userQuery.error as any).data?.FriendlyMessage;
    //   return <></>;
    // }
    return jsx(Fragment, { children: children });
};
const RedirectOnLogout = ({ children }) => {
    const { pathname } = window.location;
    if (pathname == '/logout') {
        // Redirect to homepage
        window.location.href = config.REACT_APP_ENC_LANDING_APP_URL;
        return jsx(Fragment, {});
    }
    return jsx(Fragment, { children: children });
};

export { AuthErrorComponent, RedirectOnError, RedirectOnLogout };
