import { useGetIconQuery, useGetIconByTypeDefIdQuery } from '../services/itemApi.js';
import { getIcon } from './hookUtils.js';

const getIconByImageId = ({ imageId, skip }) => {
    const { data } = useGetIconQuery({ imageId }, { skip });
    return data || null;
};
const getIconByTypeDefId = ({ typeDefId, skip }) => {
    const { data } = useGetIconByTypeDefIdQuery({ typeDefId }, { skip });
    return data || null;
};
const useIcon = ({ imageId, typeDefId, fallbackObj }) => {
    const data = imageId
        ? getIconByImageId({ imageId, skip: !imageId })
        : getIconByTypeDefId({ typeDefId, skip: !typeDefId });
    const iconDetails = getIcon(data ?? fallbackObj);
    return { iconDetails };
};

export { useIcon };
