import { jsxs, jsx } from 'react/jsx-runtime';
import { useTheme, Typography } from '@mui/material';
import { Box } from './Box.js';

//create super component for div with layout
const UserTile = ({ user, showAvatar = false, padding, avatarBackground, direction, alignItems, justifyContent, gap, ...props }) => {
    const theme = useTheme();
    return (jsxs(Box, { direction: 'row', alignItems: 'center', justifyContent: 'start', gap: gap ?? 'medium', padding: padding ?? 'medium', ...props, children: [showAvatar && (jsx(Box, { height: '3rem', width: '3rem', rounded: 'round', background: avatarBackground, style: { fontSize: '1.125rem' }, children: jsx(Typography, { variant: 'h4', sx: { color: 'currentColor' }, children: user?.initials ?? user?.name?.[0] ?? 'U' }) })), jsxs(Box, { background: 'none', gap: 'xsmall', justifyContent: 'center', alignItems: 'start', children: [jsx(Typography, { variant: 'body1', sx: { color: theme.palette.info.dark }, children: user?.name ?? '' }), user?.username && (jsx(Typography, { variant: 'body2', children: user.username }))] })] }));
};

export { UserTile };
