import { jsx } from 'react/jsx-runtime';
import { DisposalRequestStatus, DisposalRequestStatusCaption } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import { Indicator } from './Indicator.js';

const DisposalRequestStatusIndicator = ({ status, ...props }) => {
    // can make these global if needed
    const _color = {
        [DisposalRequestStatus.New]: 'default',
        [DisposalRequestStatus.InApproval]: 'default',
        [DisposalRequestStatus.Approved]: 'primary',
        [DisposalRequestStatus.Rejected]: 'warning',
        [DisposalRequestStatus.InProgress]: 'default',
        [DisposalRequestStatus.Complete]: 'default',
        [DisposalRequestStatus.InProgressOrComplete]: 'default',
        [DisposalRequestStatus.Failed]: 'error',
        [DisposalRequestStatus.ItemDestroyInProgress]: 'primary',
        [DisposalRequestStatus.ItemDestroyComplete]: 'primary',
    };
    return (jsx(Indicator, { ...props, label: DisposalRequestStatusCaption[status], color: _color[status] }));
};

export { DisposalRequestStatusIndicator };
