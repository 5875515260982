import { jsxs, jsx } from 'react/jsx-runtime';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Box } from '../Box.js';
import { Button } from '../button/Button.js';
import { Edit20Regular } from '@fluentui/react-icons';
import { CustomTypeField } from './components/item/CustomTypeField.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import { useGetBusinessTypePropertiesPanelValuesQuery } from '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import { useState } from 'react';
import { merge, isEmpty } from 'lodash';
import { propGroupsMapping, displayPropsMapping, editablePropsMapping, transformPropertiesOfObject, createTree, transformSyncFusionPropertiesToJson } from '../item/ItemBusinessTypeUtil.js';

const EditCustomTypeDialog = ({ open, title, itemId, onSave, onClose, typeDefId, isLoading = false, propertyName, formValue, businessObject, propertyDetails, treeGridProperties, }) => {
    const [value, setValue] = useState({});
    const onChange = (args) => {
        const _data = args?.data;
        treeGridProperties?.TypeData?.value?.forEach((typeData) => {
            // Prop Groups
            if (typeData.PropLinkGroups && typeData.PropLinkGroups.value) {
                propGroupsMapping[typeData.TreeDataTypeDef.ID] =
                    typeData.PropLinkGroups.value;
            }
            else {
                propGroupsMapping[typeData.TreeDataTypeDef.ID] = [];
            }
            // Display Props
            if (typeData.DisplayProps && typeData.DisplayProps.value) {
                displayPropsMapping[typeData.TreeDataTypeDef.ID] =
                    typeData.DisplayProps.value;
            }
            else {
                displayPropsMapping[typeData.TreeDataTypeDef.ID] = [];
            }
            // Editable PropDefs (ordered by TypeKind and then Caption)
            const propsOrdered = [];
            if (typeData.EditablePropDefs &&
                typeData.EditablePropDefs.value &&
                typeData.EditablePropDefs.value.length > 0) {
                const editablePropDefs = typeData.EditablePropDefs.value;
                const primitiveProps = editablePropDefs.filter((prop) => prop.TypeKind === 'Primitive');
                primitiveProps.sort((a, b) => a.Caption.localeCompare(b.Caption));
                propsOrdered.push(...primitiveProps);
                const enumProps = editablePropDefs.filter((prop) => prop.TypeKind === 'Enum');
                enumProps.sort((a, b) => a.Caption.localeCompare(b.Caption));
                propsOrdered.push(...enumProps);
                const entityProps = editablePropDefs.filter((prop) => prop.TypeKind === 'Entity');
                entityProps.sort((a, b) => a.Caption.localeCompare(b.Caption));
                propsOrdered.push(...entityProps);
            }
            editablePropsMapping[typeData.TreeDataTypeDef.ID] = propsOrdered;
        });
        const flatArray = [];
        transformPropertiesOfObject(flatArray, businessObject, 0);
        const _treeGridData = createTree(flatArray);
        const propertyToUpdate = _treeGridData.find((item) => item.propDef.Name === args.propertyName);
        propertyToUpdate['Items'] = _data;
        const treeGridDataJSON = transformSyncFusionPropertiesToJson(_treeGridData, businessObject);
        setValue(treeGridDataJSON);
    };
    // Get the Business Object value.
    const { data: businessTypePropertyDetails } = useGetBusinessTypePropertiesPanelValuesQuery({ typeDefId, itemId }, { skip: !typeDefId || !itemId });
    // Get the property value
    const propertyValue = businessTypePropertyDetails?.BusinessObject?.[propertyName];
    // create a similar isEqual function of lodash, the difference is that the customDeepEqual will not check the data type of the value.
    const customDeepEqual = (objA, objB) => {
        if (objA == objB) {
            return true;
        }
        if (typeof objA === 'object' && typeof objB === 'object') {
            const keysA = Object.keys(objA ?? {});
            const keysB = Object.keys(objB ?? {});
            if (keysA.length !== keysB.length) {
                return false;
            }
            for (const key of keysA) {
                if (!objB.hasOwnProperty(key) ||
                    !customDeepEqual(objA[key], objB[key])) {
                    return false;
                }
            }
            return true;
        }
        return false;
    };
    const updatedFormValue = merge({}, formValue, value?.[propertyName]);
    return (jsxs(Dialog, { open: open, PaperProps: {
            sx: {
                width: '100%',
                height: '100%',
                maxWidth: '75rem',
                maxHeight: '56.188rem',
            },
        }, children: [jsxs(DialogTitle, { children: [jsx(Edit20Regular, {}), title] }), jsx(DialogContent, { sx: { overflow: 'hidden' }, children: jsx(Box, { background: 'none', height: '100%', children: jsx(CustomTypeField, { onChange: onChange, propertyValue: propertyValue, propertyDetails: propertyDetails, treeGridProperties: treeGridProperties, businessObject: businessTypePropertyDetails?.BusinessObject }) }) }), jsxs(DialogActions, { children: [jsx(Button, { variant: 'contained', disabled: customDeepEqual(updatedFormValue, formValue) ||
                            isEmpty(value) ||
                            isLoading ||
                            !value, onClick: () => !!onSave &&
                            onSave(value?.[propertyName]?.length ? value?.[propertyName] : value, propertyName), children: "Save" }), jsx(Button, { variant: 'outlined', onClick: onClose, children: "Cancel" })] })] }));
};

export { EditCustomTypeDialog };
