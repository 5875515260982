import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ui: {
        sideMenuCollapsed: false,
    },
};
const applicationSlice = createSlice({
    name: 'application',
    initialState: initialState,
    reducers: {
        setSideMenuCollapsed: (state, action) => {
            state.ui.sideMenuCollapsed = action.payload;
        },
        setSideMenuGroupCollapsedState: (state, action) => {
            state.ui.sideMenuGroupCollapsedState = action.payload;
        },
        setSelectedSideMenuItemValue: (state, action) => {
            state.ui.selectedSideMenuItemValue = action.payload;
        },
        reset: (state) => {
        },
    },
});
const { setSideMenuCollapsed, setSideMenuGroupCollapsedState, setSelectedSideMenuItemValue, reset, } = applicationSlice.actions;
var applicationReducer = applicationSlice.reducer;

export { applicationSlice, applicationReducer as default, initialState, reset, setSelectedSideMenuItemValue, setSideMenuCollapsed, setSideMenuGroupCollapsedState };
