import { jsxs, jsx } from 'react/jsx-runtime';
import { Edit20Regular } from '@fluentui/react-icons';
import { useTheme, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material';
import { useState } from 'react';
import { Box } from '../Box.js';
import { Button } from '../button/Button.js';
import './components/item/BusinessTypeField.js';
import '@syncfusion/ej2-react-calendars';
import 'dayjs';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import 'lodash';
import '../item/utils.js';
import { NumberTextField } from './components/item/NumberTextField.js';

const EditNumberDialog = ({ open, title, onSave, onClose, isLoading = false, fieldLabel, propertyName, propertyValue, numberType }) => {
    const theme = useTheme();
    const [value, setValue] = useState(propertyValue ?? '');
    const onChange = (val) => setValue(val);
    const handleIncrement = () => {
        const currentValue = ((!isNaN(parseInt(value)) ? parseInt(value) : -1) + 1).toString();
        setValue(currentValue);
    };
    const handleDecrement = () => {
        const currentValue = ((!isNaN(parseInt(value)) ? parseInt(value) : 1) - 1).toString();
        setValue(currentValue);
    };
    return (jsxs(Dialog, { open: open, PaperProps: {
            sx: {
                width: '100%',
                height: '100%',
                maxWidth: '47.5rem',
                maxHeight: '20.5rem',
            },
        }, children: [jsxs(DialogTitle, { children: [jsx(Edit20Regular, {}), title] }), jsx(DialogContent, { children: jsxs(Box, { background: 'none', children: [jsx(Typography, { mb: 1, variant: 'body1', sx: { color: theme.palette.info.dark }, children: fieldLabel }), jsx(NumberTextField, { value: value, onChange: onChange, handleIncrement: handleIncrement, handleDecrement: handleDecrement, allowSinglePeriod: numberType === 'decimal' })] }) }), jsxs(DialogActions, { children: [jsx(Button, { variant: 'contained', disabled: propertyValue === value || isLoading, onClick: () => onSave && onSave(value, propertyName), children: "Save" }), jsx(Button, { variant: 'outlined', onClick: onClose, children: "Cancel" })] })] }));
};

export { EditNumberDialog };
