import { jsx, Fragment } from 'react/jsx-runtime';
import { CompassNorthwest24Regular, Delete24Regular, AddCircle24Regular } from '@fluentui/react-icons';
import { memo } from 'react';
import RetentionClass24 from '../../icons/RetentionClass24.js';
import '../../types/requestTypes.js';
import { ItemManagementStatus } from '../../types/itemTypes.js';
import { Chip } from './Chip.js';

const ItemManagementStatusChip = ({ status, border = false, ...props }) => {
    // can make these global if needed
    const _icon = {
        [ItemManagementStatus.Discovered]: jsx(CompassNorthwest24Regular, {}),
        [ItemManagementStatus.Managed]: jsx(RetentionClass24, {}),
        [ItemManagementStatus.Disposed]: jsx(Delete24Regular, {}),
        [ItemManagementStatus.Destroyed]: jsx(Delete24Regular, {}),
        [ItemManagementStatus.Created]: jsx(AddCircle24Regular, {}),
        [ItemManagementStatus.None]: jsx(Fragment, {}),
    };
    return (jsx(Chip, { ...props, border: border, title: ItemManagementStatus[status], icon: _icon[status] }));
};
const MemoizedItemManagementStatusChip = memo(ItemManagementStatusChip);

export { ItemManagementStatusChip, MemoizedItemManagementStatusChip };
