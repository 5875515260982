import { jsx, jsxs } from 'react/jsx-runtime';
import { Box } from './Box.js';
import { Typography } from '@mui/material';
import { DisposalRequestResponseStatusText } from './DisposalRequestResponseStatusText.js';
import { useState, useEffect } from 'react';

const DisposalRequestTempApprovers = ({ approvers, startNumber = 0, width, }) => {
    const [currentApprovers, setCurrentApprovers] = useState(approvers);
    const [key, setKey] = useState('key');
    useEffect(() => {
        setCurrentApprovers(approvers);
        // setKey('new key');
    }, [approvers]);
    return (jsx(Box, { background: 'none', style: {
            overflow: 'visible',
            minWidth: '14rem',
            maxWidth: '20rem',
        }, width: width, gap: 'small', shrink: true, children: currentApprovers?.map((app, index) => {
            return (jsxs(Box, { direction: 'row', background: 'none', style: {
                    width: '25rem',
                }, children: [jsx(Typography, { variant: 'body2', sx: {
                            minWidth: '2.25rem',
                            maxWidth: '2.25rem',
                            fontWeight: '800',
                        }, children: (index + startNumber + 1).toString().padStart(2, '0') }), jsx(Box, { background: 'none', alignItems: 'start', style: { alignSelf: 'stretch' }, children: jsx(Typography, { variant: 'body2', noWrap: true, children: app._Display }) }), jsx(DisposalRequestResponseStatusText, { status: app.Status ?? '', width: '5rem' })] }, app._Display));
        }) }, key));
};

export { DisposalRequestTempApprovers };
