import { jsx } from 'react/jsx-runtime';
import SvgEncompaasBanner from '../images/EncompaasBanner.js';

//create super component for div with layout
const Banner = ({ colour = 'default', className, ...props }) => {
    let _className = `ecs-banner ecs-fill-${colour} `;
    //can apply custom className
    _className = _className + (className ?? '');
    return (jsx(SvgEncompaasBanner, { "data-testid": `encompaas-banner-${colour}`, className: _className, ...props }));
};

export { Banner };
