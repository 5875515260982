import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from './Box.js';
import { useTheme, Typography } from '@mui/material';

const ApplicationButton = ({ name, Logo, size = 'small', className, url, }) => {
    const theme = useTheme();
    let _className = `ecs-application`;
    //can apply custom className
    _className = _className + (className != null ? `-${className}` : '');
    const [width, height] = size == 'medium' ? [6.875, 8.75] : [5.5, 7];
    const [logoWidth, logoHeight] = size == 'medium' ? [4.375, 5] : [3.5, 4];
    // Handle onClick event
    const handleClick = () => {
        if (url) {
            window.open(url, '_blank'); // Opens the URL in a new window/tab
        }
    };
    return (
    //could also have used the Button component
    jsxs(Box, { className: _className, rounded: 'all', padding: 'medium', background: 'alt-light', alignItems: 'center', gap: 'small', justifyContent: 'space-between', height: height, width: width, onClick: handleClick, style: { cursor: url ? 'pointer' : 'default' }, children: [jsx(Box, { className: 'ecs-application-sub-pane', width: logoWidth, height: logoHeight, alignItems: 'center', justifyContent: 'center', children: jsx(Logo, {}) }), jsx(Box, { className: 'ecs-application-sub-pane', direction: 'column', justifyContent: 'center', children: jsx(Typography, { align: 'center', variant: 'body2', color: theme.palette.info.dark, fontSize: size == 'medium' ? '0.875rem' : '0.75rem', children: name }) })] }));
};

export { ApplicationButton };
