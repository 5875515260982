import { jsx, jsxs } from 'react/jsx-runtime';
import { ChevronDownFilled } from '@fluentui/react-icons';
import { Select, OutlinedInput, MenuItem, Typography } from '@mui/material';
import { Box } from '../../../Box.js';

const BooleanField = ({ value, onChange }) => {
    return (jsx(Box, { background: 'none', children: jsxs(Select, { value: value, variant: 'outlined', onChange: onChange, sx: {
                width: '100%',
                height: '100%',
                maxHeight: '3rem',
                maxWidth: '10.875rem',
                paddingLeft: '0.75rem',
            }, IconComponent: ChevronDownFilled, input: jsx(OutlinedInput, { notched: false }), MenuProps: { classes: { paper: 'filter-dropdown' } }, children: [jsx(MenuItem, { value: '', children: jsx(Typography, { variant: 'body2' }) }), jsx(MenuItem, { value: 'true', children: jsx(Typography, { variant: 'body2', children: "Yes" }) }), jsx(MenuItem, { value: 'false', children: jsx(Typography, { variant: 'body2', children: "No" }) })] }) }));
};

export { BooleanField };
