import { jsx } from 'react/jsx-runtime';
import { DismissFilled, ChevronLeftFilled, ChevronRightFilled, QuestionCircleRegular, Grid20Filled, Edit20Regular, DismissCircle20Regular, HomeRegular, ArrowCurveUpLeft20Filled } from '@fluentui/react-icons';
import { useTheme, IconButton } from '@mui/material';
import SvgCollapseLeft24 from '../../icons/CollapseLeft24.js';
import SvgExpandLeft24 from '../../icons/ExpandLeft24.js';
import SvgHistory24 from '../../icons/History24.js';
import { TextIconButton } from './TextIconButton.js';

const ShapedCloseButton = ({ shape, border, ...props }) => {
    const theme = useTheme();
    const borderColor = theme.palette.info[600];
    const buttonStyle = shape === 'square'
        ? { borderRadius: '8px', border: `1px solid ${borderColor}` }
        : {};
    return (jsx(IconButton, { ...props, sx: buttonStyle, color: 'info', children: jsx(DismissFilled, {}) }));
};
const CloseButton = (props) => {
    return (jsx(IconButton, { ...props, children: jsx(DismissFilled, {}) }));
};
const PreviousButton = (props) => {
    return (jsx(IconButton, { ...props, children: jsx(ChevronLeftFilled, {}) }));
};
const NextButton = (props) => {
    return (jsx(IconButton, { ...props, children: jsx(ChevronRightFilled, {}) }));
};
const AboutButton = (props) => {
    return (jsx(IconButton, { ...props, children: jsx(QuestionCircleRegular, {}) }));
};
const GridButton = (props) => {
    const theme = useTheme();
    return (jsx(IconButton, { size: 'medium', color: 'info', sx: {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info[600],
            borderRadius: '0.75rem',
            '&:hover': {
                bgcolor: theme.palette.common.white,
                color: '#57F7FE',
            },
        }, ...props, children: jsx(Grid20Filled, {}) }));
};
const MenuButton = (props) => {
    return (jsx(IconButton, { ...props, children: jsx("h2", { children: "\u22EE" }) }));
};
const EditButton = (props) => {
    return (jsx(IconButton, { color: 'primary', ...props, children: jsx(Edit20Regular, {}) }));
};
const DeleteButton = (props) => {
    return (jsx(IconButton, { ...props, children: jsx(DismissCircle20Regular, {}) }));
};
const CollapseButton = ({ direction = 'left', color = 'primary', ...props }) => {
    return (jsx(IconButton, { color: color, ...props, "aria-label": 'collapse', children: direction === 'left' ? jsx(SvgCollapseLeft24, {}) : jsx(SvgExpandLeft24, {}) }));
};
const RefreshButton = ({ indicator = false, color = 'primary', ...props }) => {
    //todo: add an indicator overlay
    return (jsx(IconButton, { color: color, ...props, "aria-label": 'refresh', children: jsx(SvgHistory24, {}) }));
};
const HomeButton = (props) => (jsx(TextIconButton, { startIcon: jsx(HomeRegular, {}), textVariant: 'body2', ...props }));
const ParentNavigationButton = (props) => (jsx(TextIconButton, { startIcon: jsx(ArrowCurveUpLeft20Filled, {}), textVariant: 'body2', ...props }));

export { AboutButton, CloseButton, CollapseButton, DeleteButton, EditButton, GridButton, HomeButton, MenuButton, NextButton, ParentNavigationButton, PreviousButton, RefreshButton, ShapedCloseButton };
