const getIcon = (itemDetails) => {
    if (typeof itemDetails === 'object' && itemDetails?.value?.length) {
        if (itemDetails?.value?.[0]?.hasOwnProperty('_Image'))
            return itemDetails?.value?.[0]?._Image;
        return itemDetails?.value?.[0];
    }
    return itemDetails?._Image;
};

export { getIcon };
