import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { itemApi } from '../services/itemApi.js';
import { requestApi } from '../services/requestApi.js';
import { userApi } from '../services/userApi.js';
import applicationReducer from './slices/applicationSlice.js';
import downloadFileReducer from './slices/downloadFileSlice.js';
import itemModalReducer from './slices/itemModalSlice.js';
import { disposalDetailsApi } from '../services/requestDetailsApi.js';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['application', 'downloadFile', 'itemModal', 'approvers'],
};
const rootReducer = combineReducers({
    application: applicationReducer,
    [requestApi.reducerPath]: requestApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [itemApi.reducerPath]: itemApi.reducer,
    [disposalDetailsApi.reducerPath]: disposalDetailsApi.reducer,
    downloadFile: downloadFileReducer,
    itemModal: itemModalReducer,
});
//convenience for testing
const setupStore = (preloadedState) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(userApi.middleware)
            .concat(requestApi.middleware)
            .concat(itemApi.middleware)
            .concat(disposalDetailsApi.middleware),
    });
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
// global store
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
        .concat(userApi.middleware)
        .concat(requestApi.middleware)
        .concat(itemApi.middleware)
        .concat(disposalDetailsApi.middleware),
});
const persistor = persistStore(store);
// hooks
const useAppDispatch = () => useDispatch();
const useAppSelector = useSelector;

export { persistor, setupStore, store, useAppDispatch, useAppSelector };
