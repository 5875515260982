const insertIf = (condition, ...elements) => {
    return condition ? elements : [];
};
//converts object to array of arrays
const convertObjectToArray = (obj) => {
    const arr = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            arr.push([key, obj[key]]);
        }
    }
    return arr;
};

export { convertObjectToArray, insertIf };
