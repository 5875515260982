import { useState, useRef, useEffect } from 'react';
import { constructTreeGridData, addCollection, deepMergeArrays, removeItem } from '../components/dialog/components/item/customTypeUtils.js';

var ActionType;
(function (ActionType) {
    ActionType["Delete"] = "DELETE";
})(ActionType || (ActionType = {}));
const useCustomTypeActions = ({ onChange, propertyValue, businessObject, propertyDetails, treeGridProperties }) => {
    const isCollection = propertyDetails?.IsCollection;
    const [treeGridData, setTreeGridData] = useState([]);
    const treeGridWithDeletedPropertyRef = useRef([]);
    useEffect(() => {
        if (!!treeGridProperties || !!propertyDetails) {
            const data = constructTreeGridData(propertyValue, businessObject, propertyDetails, treeGridProperties);
            // fixed re-rendering issue when saving.
            const storedData = localStorage.getItem('customTypeTreeGridData');
            const storedTreeGridData = storedData != "undefined" ? JSON.parse(localStorage.getItem('customTypeTreeGridData')) : [];
            const _treeGridData = storedTreeGridData?.length ? storedTreeGridData : data;
            setTreeGridData(_treeGridData);
            treeGridWithDeletedPropertyRef.current = data;
            localStorage.setItem('treeGridWithDeletedPropertyRef', JSON.stringify(treeGridWithDeletedPropertyRef.current));
        }
        return () => localStorage.removeItem("treeGridWithDeletedPropertyRef");
    }, [treeGridProperties, propertyDetails]);
    // Add button functionality
    const handleAdd = (selectedRow) => {
        // this is to add a new item if the selected element is a collection
        if (!!selectedRow && selectedRow?.fieldDetails?.IsCollection) {
            const { propertyName, fieldDetails, parentItem } = selectedRow;
            // set the selected id 
            const id = parentItem?.id ?? selectedRow?.id;
            const storedTreeGridData = JSON.parse(localStorage.getItem('customTypeTreeGridData'));
            const newTreeGridData = [...storedTreeGridData];
            addCollection(newTreeGridData, id, propertyName, fieldDetails, treeGridProperties, businessObject);
            setTreeGridData(newTreeGridData);
            const result = deepMergeArrays(treeGridWithDeletedPropertyRef.current, newTreeGridData, '');
            treeGridWithDeletedPropertyRef.current = result;
            localStorage.setItem('treeGridWithDeletedPropertyRef', JSON.stringify(treeGridWithDeletedPropertyRef.current));
            !!onChange && onChange({ data: treeGridWithDeletedPropertyRef.current, propertyName: propertyDetails?.Name });
        }
        // add a new parent item
        else {
            const storedTreeGridData = JSON.parse(localStorage.getItem('customTypeTreeGridData'));
            const newTreeGridData = [
                ...storedTreeGridData,
                ...constructTreeGridData('', businessObject, propertyDetails, treeGridProperties),
            ];
            setTreeGridData(newTreeGridData);
            const result = deepMergeArrays(treeGridWithDeletedPropertyRef.current, newTreeGridData, '');
            treeGridWithDeletedPropertyRef.current = result;
            localStorage.setItem('treeGridWithDeletedPropertyRef', JSON.stringify(treeGridWithDeletedPropertyRef.current));
            !!onChange && onChange({ data: treeGridWithDeletedPropertyRef.current, propertyName: propertyDetails?.Name });
        }
    };
    // Delete button functionality
    const handleDelete = (selectedRow) => {
        const id = selectedRow?.id;
        const targetDepth = selectedRow?.level;
        const recordsToDelete = [];
        selectedRow.childRecords?.forEach((item) => {
            recordsToDelete.push(item.data);
        });
        const treeDataWithDeletedProperty = [...treeGridWithDeletedPropertyRef.current];
        if (selectedRow?.parentItem) {
            const updateChildItems = (_treeGridData) => {
                _treeGridData.forEach((_item) => {
                    if (_item.id === id) {
                        if (!_item?.['childrenToDelete']) {
                            _item['childrenToDelete'] = [];
                        }
                        recordsToDelete?.forEach((item) => _item['childrenToDelete'].push(item));
                    }
                    else
                        updateChildItems(_item.Items);
                });
            };
            updateChildItems(treeDataWithDeletedProperty);
        }
        else {
            const recordsToDelete = { ...selectedRow?.data };
            const updateParentItems = (_treeGridData) => {
                _treeGridData.forEach((_item) => {
                    if (_item.id === id) {
                        if (!_item?.['childrenToDelete']) {
                            _item['childrenToDelete'] = [];
                        }
                        _item?.childrenToDelete.push(recordsToDelete);
                    }
                });
            };
            updateParentItems(treeDataWithDeletedProperty);
        }
        // remove the item from the tree grid data.
        const newTreeGridData = removeItem(treeGridData, targetDepth, 0, selectedRow, id);
        treeGridWithDeletedPropertyRef.current = treeDataWithDeletedProperty;
        setTreeGridData(newTreeGridData);
        localStorage.setItem('treeGridWithDeletedPropertyRef', JSON.stringify(treeGridWithDeletedPropertyRef.current));
        !!onChange && onChange({ data: treeGridWithDeletedPropertyRef.current, propertyName: propertyDetails?.Name });
    };
    // Edit button functionality
    const handleActionComplete = (args) => {
        if (args.type === 'save') {
            const id = args?.data?.id;
            const currentValue = args?.data?.value;
            // getting the data from the local storage.
            const _treeGridData = localStorage.getItem("customTypeTreeGridData");
            const _treeGridDataRef = localStorage.getItem("treeGridWithDeletedPropertyRef");
            if (_treeGridData) {
                const treeGridData = JSON.parse(_treeGridData);
                const treeGridDataRef = JSON.parse(_treeGridDataRef);
                let isUpdated = false;
                const updatePropertyValue = (data, propertyId, newValue) => {
                    for (const item of data) {
                        if (isUpdated)
                            return;
                        if (item.id === propertyId) {
                            item.value = newValue;
                            isUpdated = true;
                        }
                        if (item.Items && item.Items.length > 0) {
                            updatePropertyValue(item.Items, id, newValue);
                        }
                    }
                };
                updatePropertyValue(treeGridData, id, currentValue);
                localStorage.setItem('customTypeTreeGridData', JSON.stringify(treeGridData));
                const result = deepMergeArrays(treeGridData, treeGridDataRef, 'complete');
                treeGridWithDeletedPropertyRef.current = result;
                localStorage.setItem('treeGridWithDeletedPropertyRef', JSON.stringify(treeGridWithDeletedPropertyRef.current));
                !!onChange && onChange({ data: treeGridWithDeletedPropertyRef.current, propertyName: propertyDetails?.Name });
            }
        }
    };
    // Row Selected functionality
    return {
        treeGridData,
        isCollection,
        handleAdd,
        handleDelete,
        handleActionComplete,
    };
};

export { ActionType, useCustomTypeActions };
