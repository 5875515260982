import { useState, useEffect } from 'react';
import '../services/requestApi.js';
import '../services/userApi.js';
import { useGetItemDetailsFullDetailsQuery, useGetItemDetailsMemberOfQuery, useGetCollectionItemsCountQuery, useGetItemDetailsApplicableClassificationsQuery, useGetPropLinksQuery } from '../services/itemApi.js';
import '../services/requestDetailsApi.js';

const useFetchPropLinks = (itemType, itemId, skipQuery) => {
    const { data: propLinksData, isLoading: isFetchingPropLinks } = useGetPropLinksQuery({
        itemType: itemType,
        itemId: itemId,
    }, {
        skip: !itemId || !itemType || skipQuery
    });
    return { propLinksData, isFetchingPropLinks };
};
const useItemDetails = ({ id }) => {
    const [itemId, setItemId] = useState(id);
    const { data: itemDetailsWithFullDetails, isLoading: isFetchingFullDetails } = useGetItemDetailsFullDetailsQuery({
        itemId: itemId,
    }, { skip: !id });
    const [itemType, setItemType] = useState(null);
    useEffect(() => {
        setItemId(id);
    }, [id]);
    useEffect(() => {
        if (itemDetailsWithFullDetails && itemDetailsWithFullDetails.TypeDefId) {
            setItemType(itemDetailsWithFullDetails.TypeDefId);
        }
    }, [itemDetailsWithFullDetails]);
    const shouldSkip = itemId !== itemDetailsWithFullDetails?.ID || itemType !== itemDetailsWithFullDetails?.TypeDefId;
    const { propLinksData, isFetchingPropLinks } = useFetchPropLinks(itemType, itemId, shouldSkip);
    const { data: itemDetailsWithMemberOf, isLoading: isFetchingMemberOf } = useGetItemDetailsMemberOfQuery({
        itemId: itemId,
    }, { skip: !id });
    const { data: itemCollectionCount, isLoading: isFetchingItemCount } = useGetCollectionItemsCountQuery({
        collectionId: itemId,
    });
    const { data: itemDetailsWithApplicableClassifications, isLoading: isFetchingApplicableClassifications, } = useGetItemDetailsApplicableClassificationsQuery({
        itemId: itemId,
    }, { skip: !id });
    const itemDetails = {
        ...itemDetailsWithFullDetails,
        ['MemberOf']: itemDetailsWithMemberOf?.['MemberOf'],
        ['ApplicableClassifications']: itemDetailsWithApplicableClassifications?.['ApplicableClassifications'],
    };
    return {
        itemDetails,
        isFetching: isFetchingFullDetails ||
            isFetchingMemberOf ||
            isFetchingApplicableClassifications ||
            isFetchingItemCount ||
            isFetchingPropLinks,
        itemCollectionCount,
        propertyDetails: itemDetailsWithFullDetails,
        propLinksData
    };
};

export { useItemDetails };
