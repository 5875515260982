import { SideMenuItemGroup, config } from '@encompaas/common';
import {
    DataTreemap24Regular, Info24Regular
} from '@fluentui/react-icons';

const logiEndpoint = config.REACT_APP_ENC_LOGI_URL;
const enwebEndpoint = config.API_BASE_URL;
const inventoryEndpoint = `${logiEndpoint}api/inventory?includeItems=ALL&sort=NAME&sortOrder=ASC&type=DASHBOARD`;
const tokenRefreshEndpoint = `${enwebEndpoint}User/GetAnalyticsUserDetails`;
let dashboardConfig: any = undefined;

export let _menuItems: SideMenuItemGroup[] = [];

export function setDashboardData(data: any) {
    //Set initial dashboard to the first in the list.
    var inventoryData = data["content"];

    if (inventoryData.length === 0) {
        //Set the list items to empty.
        _menuItems = [
            {
                items: [
                    {
                        title: 'No Dashboards Available',
                        key: "0",
                        icon: <Info24Regular />,
                        value: 'No Dashboards Available',
                        caption: 'No Dashboards Available',
                    },
                ],
            },
        ];
    }
    else {
        _menuItems = inventoryData.map(function (item: any) {
            return (
                {
                    items: [
                        {
                            title: item["name"],
                            key: item["inventoryItemId"],
                            icon: <DataTreemap24Regular />,
                            value: item["name"],
                            caption: item["description"],
                        },
                    ],
                }
            );
        })
    }
};

export function updateDashboard(dashboardId: any) {
    dashboardConfig = {
        "dashboardId": dashboardId, // To be passed in from the Analytics page as the first item in the returned inventory list.
        "interactivityProfileName": "interactive",
        "interactivityOverrides": {
            "name": "interactive",
            "type": "SYSTEM",
            "overrideVisualInteractivity": true,
            "settings": {
                "REFRESH": true,
                "CHANGE_LAYOUT": true,
                "RENAME": false,
                "SHARE_FILTER_SETS": true,
                "DASHBOARD_INTERACTIONS": true,
                "ADD_TO_FAVORITES": true,
                "DELETE": false,
                "FILTER": true,
                "EXPORT_PNG_PDF": true,
                "ADD_VISUALS": false,
                "EXPORT_CONFIGURATION": false,
                "DASHBOARD_LINKS": true,
                "SAVE": false
            },
            "visualSettings": {
                "ACTIONS_ACTION": true,
                "RULERS": true,
                "ZOOM_ACTION": true,
                "FILTER_ACTION": true,
                "COLORS": true,
                "METRICS": true,
                "ACTIONS": true,
                "TREND_ACTION": true,
                "VISUAL_STYLE": true,
                "KEYSET_ACTION": true,
                "KEYSET": undefined,
                "COPY": true,
                "SETTINGS": true,
                "EXPORT": true,
                "TIMEBAR_PANEL": true,
                "DETAILS_ACTION": true,
                "MAXIMIZE": true,
                "LINK_ACTION": true,
                "FILTER": true,
                "REMOVE": true,
                "GROUPING": true,
                "RENAME": false,
                "SORT": true,
                "TIMEBAR_FIELD": true
            }
        },
        "theme": "encompaas",
        "editor": { "placement": "modals" },
        "header": {
            "showActions": true,
            "showTitle": true,
            "visible": true
        }
    }

    return dashboardConfig;
}

export function setDashboardConfig(initialId: any) {
    dashboardConfig = {
        "dashboardId": initialId, // To be passed in from the Analytics page as the first item in the returned inventory list.
        "interactivityProfileName": "interactive",
        "interactivityOverrides": {
            "name": "interactive",
            "type": "SYSTEM",
            "overrideVisualInteractivity": true,
            "settings": {
                "REFRESH": true,
                "CHANGE_LAYOUT": true,
                "RENAME": false,
                "SHARE_FILTER_SETS": true,
                "DASHBOARD_INTERACTIONS": true,
                "ADD_TO_FAVORITES": true,
                "DELETE": false,
                "FILTER": true,
                "EXPORT_PNG_PDF": true,
                "ADD_VISUALS": false,
                "EXPORT_CONFIGURATION": false,
                "DASHBOARD_LINKS": true,
                "SAVE": false
            },
            "visualSettings": {
                "ACTIONS_ACTION": true,
                "RULERS": true,
                "ZOOM_ACTION": true,
                "FILTER_ACTION": true,
                "COLORS": true,
                "METRICS": true,
                "ACTIONS": true,
                "TREND_ACTION": true,
                "VISUAL_STYLE": true,
                "KEYSET_ACTION": true,
                "KEYSET": undefined,
                "COPY": true,
                "SETTINGS": true,
                "EXPORT": true,
                "TIMEBAR_PANEL": true,
                "DETAILS_ACTION": true,
                "MAXIMIZE": true,
                "LINK_ACTION": true,
                "FILTER": true,
                "REMOVE": true,
                "GROUPING": true,
                "RENAME": false,
                "SORT": true,
                "TIMEBAR_FIELD": true
            }
        },
        "theme": "encompaas",
        "editor": { "placement": "modals" },
        "header": {
            "showActions": true,
            "showTitle": true,
            "visible": true
        }
    }

    return dashboardConfig;
}

export function getLogiEndpoint() {
    return logiEndpoint;
}

export function getInventoryEndpoint() {
    return inventoryEndpoint;
}

export function getTokenRefreshEndpoint() {
    return tokenRefreshEndpoint;
}
