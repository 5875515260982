import { jsx } from 'react/jsx-runtime';
import { memo } from 'react';
import { BusinessTypeChip } from '../../chip/BusinessTypeChip.js';

const BusinessTypeTemplate = ({ title, information, imageId, }) => {
    return (jsx(BusinessTypeChip, { title: title, subTitle: information, imageId: imageId }));
};
const MemoizedBusinessTypeTemplate = memo(BusinessTypeTemplate);

export { BusinessTypeTemplate, MemoizedBusinessTypeTemplate };
