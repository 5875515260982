import { jsx } from 'react/jsx-runtime';
import { Tooltip as Tooltip$1 } from '@mui/material';

const Tooltip = ({ title, children, ...props }) => {
    return (jsx(Tooltip$1, { title: typeof title === 'string' && title ? (jsx("span", { className: 'tooltip-title', style: {
                whiteSpace: 'pre-line',
                overflow: title == 'Additional Information' ? 'scroll' : undefined,
                textOverflow: 'clip',
            }, children: title })) : (title), ...props, children: children }));
};

export { Tooltip };
