import { jsx, jsxs } from 'react/jsx-runtime';
import { DisposalRequestResponseStatus, DisposalRequestStatus } from '../types/requestTypes.js';
import '../types/itemTypes.js';
import { Box } from './Box.js';
import { useTheme, Typography } from '@mui/material';
import { DisposalRequestResponseStatusText } from './DisposalRequestResponseStatusText.js';
import { DisposalRequestTempApprovers } from './DisposalRequestTempApprovers.js';
import { useState, useEffect } from 'react';

const DisposalRequestApproversPanel = ({ approvers, disposalRequest, }) => {
    const [displayedApprovers, setDisplayedApprovers] = useState([]);
    const typeOrder = {
        [DisposalRequestResponseStatus.Approved]: 1,
        [DisposalRequestResponseStatus.Delegated]: 2,
        [DisposalRequestResponseStatus.Pending]: 2,
        [DisposalRequestResponseStatus.Waiting]: 2,
    };
    useEffect(() => {
        const approvedRequestsCount = approvers?.filter((approver) => approver.Status === DisposalRequestResponseStatus.Approved).length;
        let sortedApprovers = approvers;
        // Custom sorting is only done when DR is New or is Rejected, where the user can
        // update the Approvers before moving the DR to InApproval
        // Else, we follow the Order that is applied to the Approver list from the DR request value
        if (disposalRequest.Status === DisposalRequestStatus.New ||
            disposalRequest.Status === DisposalRequestStatus.Rejected) {
            sortedApprovers = approvers?.sort((a, b) => {
                const typeOrderA = typeOrder[a.Status];
                const typeOrderB = typeOrder[b.Status];
                if (typeOrderA < typeOrderB)
                    return -1;
                if (typeOrderA > typeOrderB)
                    return 1;
                if (a.Order < b.Order)
                    return -1;
                if (a.Order > b.Order)
                    return 1;
                return 0;
            }).map((approver, index) => {
                if (index === approvedRequestsCount) {
                    return {
                        ...approver,
                        Status: DisposalRequestResponseStatus.Pending,
                    };
                }
                if (approver.Status !== DisposalRequestResponseStatus.Approved &&
                    approver.Status !== DisposalRequestResponseStatus.Rejected &&
                    approver.Status !== DisposalRequestResponseStatus.Delegated) {
                    return {
                        ...approver,
                        Status: DisposalRequestResponseStatus.Waiting,
                    };
                }
                return approver;
            });
        }
        else {
            sortedApprovers = approvers.sort((a, b) => {
                return a.Order - b.Order;
            });
        }
        setDisplayedApprovers(sortedApprovers);
    }, [approvers]);
    return jsx(DisposalRequestTempApprovers, { approvers: displayedApprovers });
};
const DisposalRequestApprovers = ({ responses, tempSelectedApprovers, width, }) => {
    const theme = useTheme();
    return (jsxs(Box, { background: 'none', style: {
            overflow: 'visible',
            minWidth: '14rem',
            maxWidth: '20rem',
        }, width: width, gap: 'small', shrink: true, children: [responses
                ?.slice()
                .sort((a, b) => {
                return a.Order - b.Order;
            })
                .map((response, index) => {
                let pendingRequest = response.Status === DisposalRequestResponseStatus.Pending;
                const sx = {
                    fontWeight: pendingRequest ? '800' : '400',
                    color: pendingRequest
                        ? theme.palette.info.dark
                        : theme.palette.info.main,
                };
                return (jsxs(Box, { background: 'none', direction: 'row', children: [jsx(Typography, { variant: 'body2', sx: {
                                minWidth: '2.25rem',
                                maxWidth: '2.25rem',
                                ...sx,
                            }, children: (index + 1).toString().padStart(2, '0') }), jsx(Box, { background: 'none', alignItems: 'start', style: { alignSelf: 'stretch' }, children: jsx(Typography, { variant: 'body2', sx: sx, noWrap: true, children: response.Approver?.DisplayName }) }), jsx(DisposalRequestResponseStatusText, { status: response.Status, width: '5rem' })] }, response.ID));
            }), !!tempSelectedApprovers && (tempSelectedApprovers.length ?? 0) > 0 && (jsx(DisposalRequestTempApprovers, { approvers: tempSelectedApprovers, startNumber: responses.length ?? 0 }))] }));
};

export { DisposalRequestApprovers, DisposalRequestApproversPanel };
