import { jsxs, jsx } from 'react/jsx-runtime';
import { Edit24Regular, ErrorCircle16Regular } from '@fluentui/react-icons';
import { useTheme, Typography, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { Box } from '../Box.js';
import { Button } from '../button/Button.js';
import { CustomActionModal } from './CustomActionModal.js';

const EditTextDialog = ({ title, label, description, text = '', open, minLength = 0, multiline = false, rows, onClose, onSave, style, propertyName, }) => {
    const theme = useTheme();
    const [editText, setEditText] = useState(text);
    const [hasError, setHasError] = useState(false);
    const [className, setClassName] = useState('');
    useEffect(() => {
        if (open) {
            setEditText(text);
            setHasError(false);
            setClassName('');
        }
    }, [open]);
    return (jsxs(CustomActionModal, { title: title ?? `Edit ${label}`, onClose: onClose, icon: jsx(Edit24Regular, {}), open: open, actionButton: jsx(Button, { variant: 'contained', onClick: () => onSave && onSave(editText, propertyName), disabled: editText?.length < minLength || editText === text || editText?.trim() === '', children: "Save" }), size: multiline ? 'medium' : 'small', style: style, children: [label && (jsx(Typography, { variant: 'body1', sx: {
                    color: theme.palette.info.dark,
                }, noWrap: true, children: label })), description && (jsx(Typography, { variant: 'body2', sx: {
                    color: theme.palette.info.main,
                }, noWrap: true, children: description })), jsx(TextField, { sx: {
                    marginTop: label || description ? '0.75rem' : '0',
                    width: '100%',
                    ...(!multiline && { height: '3rem' }),
                }, placeholder: label && `Enter ${label}`, onChange: (event) => {
                    const textValue = (event.target.value);
                    setEditText(textValue);
                    setHasError(textValue?.trim().length < minLength);
                    setClassName(textValue?.trim().length < minLength ? '' : 'Mui-typing');
                }, error: hasError, value: editText, multiline: multiline, rows: multiline ? rows ?? 9 : undefined, InputProps: {
                    className,
                }, onBlur: () => setClassName('') }), hasError && (jsxs(Box, { background: 'none', direction: 'row', gap: 'small', style: {
                    color: theme.palette.warning.main,
                    marginTop: '0.5rem',
                }, children: [jsx(ErrorCircle16Regular, { color: 'inherit' }), jsx(Typography, { variant: 'body3', color: 'inherit', noWrap: true, children: `Please enter a ${label.toLowerCase()}` })] }))] }));
};

export { EditTextDialog };
