import { jsx, jsxs } from 'react/jsx-runtime';
import { Menu, Grow, MenuItem } from '@mui/material';

/**
 * OpenInContextMenu - context menu for showing open options
 * @param anchor anchor element for showing menu
 * @event onOpen open event handler
 * @event onClose close event handler
 */
const OpenInContextMenu = ({ anchor, options = ['modal', 'new page', 'new tab', 'new window', 'chromeless page'], onOpen, onClose, }) => (jsx(Menu, { open: Boolean(anchor), anchorEl: anchor, anchorOrigin: { vertical: 'center', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'right' }, onClose: () => {
        onClose && onClose();
    }, TransitionComponent: Grow, MenuListProps: {
        className: 'MuiMenu-list-withArrow',
    }, children: options.map((target, index) => (jsxs(MenuItem, { onClick: () => {
            onOpen && onOpen(target);
            onClose && onClose();
        }, children: ["Open in ", target] }, index))) }));

export { OpenInContextMenu };
