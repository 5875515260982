import { jsx, Fragment } from 'react/jsx-runtime';
import 'react';
import { config } from '../../config.js';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '@mui/material';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import '@pdftron/webviewer';
import { useUser } from '../../hooks/useUser.js';
import '../../services/itemApi.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/requestDetailsApi.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';

const ProtectedRoute = ({ homepageUrl, children }) => {
    const user = useUser();
    let isUserAllowedAccess = true;
    if (user?.localId && !user?.isUserApiFetching && user?.ConfiguredApps) {
        const currApp = user?.ConfiguredApps
            .find((app) => app.DisplayName === config.TITLE);
        isUserAllowedAccess = currApp?.IsAuthorised;
        if (isUserAllowedAccess)
            return jsx(Fragment, { children: children });
        else {
            window.location.href = homepageUrl;
            return jsx(Fragment, {});
        }
    }
    else if (user?.localId && !user?.isUserApiFetching && !user?.ConfiguredApps) {
        window.location.href = homepageUrl;
        return jsx(Fragment, {});
    }
    else
        return jsx(Fragment, {});
};

export { ProtectedRoute };
