import {
    Box, SideMenu, SideMenuItem, InformationDialog,
} from '@encompaas/common/components';
import PageWithAppBar from '../components/PageWithAppBar';
import { useUser } from '@encompaas/common/hooks';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@encompaas/common/store';
import { AnalyticsUser } from '@encompaas/common';
import { useNavigate } from 'react-router-dom';
import {
    setSelectedSideMenuItemValue,
    setSideMenuCollapsed,
} from '@encompaas/common/store';
import { setDashboardData, setDashboardConfig, getLogiEndpoint, getInventoryEndpoint, _menuItems, updateDashboard } from '../utils/AnalyticsHelper';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useGetAnalyticsUserQuery } from "@encompaas/common/services";

const HomePage = () => {
    const user = useUser(true) as AnalyticsUser;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState(true);
    const [isScriptLoading, setScriptLoading] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const _errorTitle = "We've encountered an error.";
    const [errorValue, setErrorValue] = useState("");
    const currentComponentId = useRef(0);
    const result = useGetAnalyticsUserQuery(user);

    ///// Functional methods
    const {
        sideMenuCollapsed,
    } = useAppSelector((state) => state.application?.ui ?? {});

    const [selectedSideMenuItem, setSelectedSideMenuItem] = useState<
        SideMenuItem | undefined
    >(_menuItems?.[0]?.items?.[0]);

    const [menuItems, setMenuItems] = useState(_menuItems);

    const handleCollapse = (value: boolean) => {
        dispatch(setSideMenuCollapsed(value));
    };

    const handleSelect = (item?: SideMenuItem) => {
        dispatch(
            setSelectedSideMenuItemValue(item?.value)
        );
        setSelectedSideMenuItem(item);

        //Render a new dashboard, if the item has a key.
        if (item?.key === "0") return;

        try {
            result.refetch();
            let userToken = user.access_token;

            // Check for the updated token.
            if (result.data?.access_token !== undefined &&
                result.data.access_token !== user.access_token) {
                userToken = result.data.access_token;
            }

            /////////////// COMPONENT EMBEDDING //////////////////////
            // @ts-ignore
            const embedManagerPromise = window.initComposerEmbedManager({ initialToken: userToken });

            //Remove the old component.
            // @ts-ignore
            const removeEmbedComponent = (embedManager) => {
                // @ts-ignore
                return embedManager.removeComponent(currentComponentId.current);
            }
            // @ts-ignore
            embedManagerPromise.then(embedManager => {
                removeEmbedComponent(embedManager);
            }).catch((error: string) => {
                //Raise an error on the ui.
                setErrorValue(error);
                setErrorDialogOpen(true);
            });

            // @ts-ignore
            const createEmbedComponent = (embedManager, config, containerElementId = 'dashboard-widget') => {
                // @ts-ignore
                return embedManager.createComponent("dashboard", config).then(component => {
                    component.render(document.getElementById(containerElementId), { width: "100%", height: "100%" });
                    currentComponentId.current = component.componentInstanceId;
                    component.addEventListener("composer-dashboard-failed", () => {
                        //Raise an error on the ui.
                        setErrorValue("Failed to load the Dashboard. You may not have permissions to view the dashboard.");
                        setErrorDialogOpen(true);
                    });
                })
            }
            // @ts-ignore
            embedManagerPromise.then(embedManager => {
                createEmbedComponent(embedManager, updateDashboard(item?.key), 'dashboard-widget');
            }).catch((error: string) => {
                //Raise an error on the ui.
                setErrorValue(error);
                setErrorDialogOpen(true);
            });
        }
        catch (ex) {
            setErrorValue("Failed to initialise the Analytics system. This could be due to a system outage, please confirm with your Administrators that the Analytics system is functioning as expected.");
            setErrorDialogOpen(true);
        }
    };

    useEffect(() => {
        var isScriptPresent = document.getElementById("composer-embed-manager") ? true : false;

        if (!isScriptLoading && !isScriptPresent) {
            setScriptLoading(true);
            /// Create the script header object and render on load.
            const $script = document.createElement("script");
            const $attr = document.createAttribute("data-name");
            $attr.value = "composer-embed-manager";
            const $attr2 = document.createAttribute("id");
            $attr2.value = "composer-embed-manager";
            $script.src = `${getLogiEndpoint()}embed/embed.js`;
            $script.attributes.setNamedItem($attr);
            $script.attributes.setNamedItem($attr2);
            document.head.appendChild($script);

            /// Create the script header object and render on load for the CustomJS
            const $customJS = document.createElement("script");
            $customJS.src = `${getLogiEndpoint()}api/branding/customJs.js`;
            document.head.appendChild($customJS);

            $script.onload = () => {
                ///////////////////// EVENT HANDLERS /////////////////////////////
                $script.addEventListener("composer-init-failed", () => {
                    //Raise an error here
                    setErrorValue("Failed to initialise the Analytics system. This could be due to a system outage, please confirm with your Administrators that the Analytics system is functioning as expected.");
                    setErrorDialogOpen(true);
                });

                window.addEventListener("load", async () => {
                    // @ts-ignore
                    window.replaceIcons();
                })

                setScriptLoading(false);
            }
        }
        else {
            return;
        }
    }, [isScriptLoading, isLoading])

    /*
This hook requires the users data to be populated, if the user doesnt have a token it should throw an error.
*/
    useEffect(() => {
        /// If the data isnt available, skip render.
        if (!(user === undefined) && !(user.access_token === undefined) && !isScriptLoading) {
            // Trigger a refetch of the user, to ensure the token hasnt timed out.
            result.refetch();

            let userToken = user.access_token;

            // Check for the updated token.
            if (result.data?.access_token !== undefined &&
                result.data.access_token !== user.access_token) {
                userToken = result.data.access_token;
            }

            //////////////////////////// EMBED MANAGER ///////////////////////////
            // @ts-ignore
            const embedManagerPromise = window.initComposerEmbedManager({ initialToken: atob(userToken) });

            /////////////// COMPONENT EMBEDDING //////////////////////
            // @ts-ignore
            const createEmbedComponent = (embedManager, config, containerElementId = 'dashboard-widget') => {
                // @ts-ignore
                return embedManager.createComponent("dashboard", config).then(component => {
                    component.render(document.getElementById(containerElementId), { width: "100%", height: "100%" });
                    currentComponentId.current = component.componentInstanceId;
                    component.addEventListener("composer-dashboard-failed", () => {
                        //Raise an error on the ui.
                        setErrorValue("Failed to load the Dashboard. You may not have permissions to view the dashboard.");
                        setErrorDialogOpen(true);
                    });
                }).catch((error: string) => {
                    //Raise an error on the ui.
                    setErrorValue(error);
                    setErrorDialogOpen(true);
                });
            }

            // @ts-ignore
            embedManagerPromise.then(embedManager => {
                ///////////////////// INVENTORY ///////////////////////////////////
                const auth_token = `Bearer ${atob(userToken)}`;

                fetch(getInventoryEndpoint(), {
                    method: "GET",
                    headers: {
                        "accept": "application/vnd.composer.v3+json,application/vnd.composer+json",
                        "authorization": auth_token,
                    }
                }).then(response => response.json())
                    .then(data => setDashboardData(data))
                    .catch((error) => {
                        //Raise an error on the ui.
                        setErrorValue(error);
                        setErrorDialogOpen(true);
                    })
                    .finally(() => {
                        setMenuItems(_menuItems);
                        if (_menuItems?.[0]?.items?.[0].key !== "0") {
                            setSelectedSideMenuItem(_menuItems?.[0]?.items?.[0]);
                            createEmbedComponent(embedManager, setDashboardConfig(_menuItems?.[0]?.items?.[0].key), 'dashboard-widget');
                        }

                        // Create the script header object and render on load for the CustomCSS
                        // Do this last to ensure we get these applied to the UI
                        var cssId = 'customCss';
                        if (!document.getElementById(cssId)) {
                            var head = document.getElementsByTagName('head')[0];
                            var link = document.createElement('link');
                            link.id = cssId;
                            link.rel = 'stylesheet';
                            link.type = 'text/css';
                            link.href = `${getLogiEndpoint()}api/branding/customCss.css`;
                            link.media = 'all';
                            head.appendChild(link);
                        }

                    });

            }).catch((error: string) => {
                //Raise an error on the ui.
                setErrorValue(error);
                setErrorDialogOpen(true);
            });
        }
    }, [user, isScriptLoading]);

    return (
        <PageWithAppBar data-testid={TEST_CONSTANTS.HOME_PAGE}>
            <Box direction='row' background='none' gap='medium'>
                <SideMenu
                    collapsed={sideMenuCollapsed}
                    selected={selectedSideMenuItem}
                    collapsedSize='xlarge'
                    onSelect={handleSelect}
                    onCollapse={handleCollapse}
                    items={menuItems}
                    blur
                />
                <Box id="zd-root">
                    <div
                        className="dashboard-widget ecs-box ecs-background-light ecs-rounded-top ecs-box-column ecs-padding-none ecs-gap-none ecs-border-none ecs-border-color-dark ecs-box-blur"
                        id="dashboard-widget"
                        style={{ height: '-webkit-fill-available', width: '-webkit-fill-available' }}
                    >
                    </div>
                </Box>
            </Box>
            <InformationDialog
                open={errorDialogOpen}
                onClose={() => setErrorDialogOpen(false)}
                title={_errorTitle}
                variant={'error'}
                information={errorValue}
            />
        </PageWithAppBar>
    );
};

export default HomePage;