import { Routes, Route } from 'react-router-dom';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { config, ProtectedRoute } from '@encompaas/common';
import {
    HomePage,
    LoginPage,
    PageNotFound,
} from './pages';

function App() {
    return (
        <div className='App' data-testid={TEST_CONSTANTS.APP_CONTAINER}>
            <Routes>
                <Route
                    path='/'
                    element={
                        <ProtectedRoute homepageUrl={config.REACT_APP_ENC_LANDING_APP_URL}>
                            <HomePage />
                        </ProtectedRoute>
                    }
                />
                <Route path='login' element={<LoginPage />} />
                <Route path='*' element={<PageNotFound />} />
            </Routes>
        </div>
    );
}

export default App;
