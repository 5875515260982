import { jsx } from 'react/jsx-runtime';
import { useTheme, Typography } from '@mui/material';
import { Button } from './Button.js';

/**
 * TextIconButton - general purpose texticon button
 * @param text text to display
 * @param textVariant variant of Typography text component that displays the text
 * @param hoverVariant variant of Typography text component that displays the text
 * @param textFontSize fontSize of Typography text component that displays the text, set to undefined to use variant fontSize
 * @param color color variant of the text
 * @param hoverColor hover color variant of the text, overrides MUI hover
 * @extends Button
 */
const TextIconButton = ({ text, textVariant = 'button', textFontSize, color, hoverVariant = 'highlight', hoverColor, startIcon, endIcon, sx, onClick, ...props }) => {
    const theme = useTheme();
    return (jsx(Button, { color: color === 'white' ? 'info' : color, variant: 'text', shape: text || (startIcon && endIcon) ? 'default' : 'square', startIcon: startIcon && startIcon, endIcon: endIcon && endIcon, onClick: onClick && onClick, disableRipple: !onClick, sx: {
            background: 'none',
            cursor: onClick ? 'pointer' : 'default',
            ...(color === 'white' && {
                color: theme.palette.common.white,
                '&:hover': hoverVariant == 'highlight'
                    ? {
                        background: 'rgba(255,255,255,0.2)',
                    }
                    : {
                        background: 'none',
                        textDecoration: 'underline',
                    },
            }),
            ...(!onClick && { '&:hover': { background: 'none' } }),
            ...(onClick &&
                hoverColor && {
                '&:hover': hoverVariant == 'highlight'
                    ? {
                        background: 'none',
                        color: theme.palette[hoverColor]?.main ??
                            theme.palette.primary.main,
                    }
                    : {
                        background: 'none',
                        textDecoration: 'underline',
                    },
            }),
            ...sx,
        }, ...props, children: text && (jsx(Typography, { variant: textVariant, color: 'inherit', noWrap: true, fontSize: textFontSize, textTransform: 'none', children: text })) }));
};

export { TextIconButton };
