import { jsx } from 'react/jsx-runtime';
import { useTheme } from '@mui/material';
import { MultilineTypography } from './MultilineTypography.js';

const CommentText = ({ comment, variant = 'body2', numberOfLines = 15, isURL, onClick, }) => {
    const theme = useTheme();
    return (jsx(MultilineTypography, { variant: variant, sx: {
            display: '-webkit-box',
            WebkitLineClamp: numberOfLines ? numberOfLines : 'none',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
            ...(isURL && {
                textDecoration: 'underline',
                color: theme.palette.primary.main,
            }),
            ...(onClick && {
                '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: theme.palette.primary.main,
                },
            }),
        }, onClick: onClick &&
            ((e) => {
                if (comment?.length) {
                    e.preventDefault();
                    e.stopPropagation();
                    onClick(comment);
                }
            }), children: comment }));
};

export { CommentText };
