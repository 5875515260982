import { jsxs, jsx } from 'react/jsx-runtime';
import { Highlight20Regular } from '@fluentui/react-icons';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Edit, Sort, Filter, Resize } from '@syncfusion/ej2-react-treegrid';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { palette } from '../../themes/light.theme.js';
import '@mui/material';
import { Chip } from '../chip/Chip.js';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../chip/ItemManagementStatusChip.js';
import '../chip/PerspectiveClassChip.js';
import { Typography } from '../Typography.js';
import { getParentNameFromObject } from '../item/ItemPropertiesUtil.js';
import { PropertyValueTemplate } from '../item/template/PropertyValueTemplate.js';

const ItemPropertiesGrid = ({ gridRef, menuItem, formDetails, editOptions, itemTypeName, editTemplate, hasEditPermission, handleRowSelection, handleActionComplete, handleRowSelecting, handleRecordDoubleClick, }) => {
    const hoverAttributes = { class: 'editable-column' };
    return (jsxs(TreeGridComponent, { ref: gridRef, treeColumnIndex: 0, allowSorting: true, width: '100%', enablePersistence: true, childMapping: 'Items', id: 'item-details-properties-grid', className: 'item-details-properties-grid', loadingIndicator: { indicatorType: 'Shimmer' }, allowResizing: true, editSettings: editOptions, rowSelected: handleRowSelection, rowSelecting: handleRowSelecting, actionComplete: handleActionComplete, recordDoubleClick: handleRecordDoubleClick, children: [jsxs(ColumnsDirective, { children: [jsx(ColumnDirective, { allowEditing: false, field: 'propertyName', headerText: 'PROPERTY NAME', width: '18.5rem', template: (item) => {
                            const propertyName = item?.propertyName;
                            return (jsx(Typography, { variant: 'body1', sx: {
                                    color: palette.navy[900],
                                    WebkitLineClamp: 3,
                                }, children: propertyName }));
                        } }), jsx(ColumnDirective, { allowEditing: true, field: 'value', headerText: 'VALUE', width: 'auto', type: 'string', edit: editTemplate, template: (item) => {
                            const parentName = getParentNameFromObject(item);
                            const _isAllowEditing = !item?.isCollection &&
                                !item?.childRecords?.length &&
                                (parentName in formDetails?.BusinessObject ||
                                    [
                                        'Business Type',
                                        'Perspective Classes',
                                        'Retention Classes',
                                    ].includes(item?.propertyName));
                            return (jsx(PropertyValueTemplate, { item: item.value, isEditable: _isAllowEditing }));
                        }, customAttributes: hasEditPermission ? hoverAttributes : null }), menuItem !== itemTypeName && (jsx(ColumnDirective, { allowEditing: false, field: 'highlights', headerText: 'HIGHLIGHTS', width: 'auto', template: (item) => item.highlights ? (jsx(Chip, { border: false, title: item.highlights, icon: jsx(Highlight20Regular, {}), color: 'success', sx: {
                                backgroundColor: palette.aqua[50],
                                color: palette.aqua[900],
                            } })) : null }))] }), jsx(Inject, { services: [Edit, Sort, Filter, Resize] })] }));
};
const MemoizedItemPropertiesGrid = memo(ItemPropertiesGrid, (prevState, nextState) => prevState.menuItem === nextState.menuItem &&
    isEqual(prevState.formDetails, nextState.formDetails));

export { ItemPropertiesGrid, MemoizedItemPropertiesGrid };
