import { jsxs, jsx } from 'react/jsx-runtime';
import { ChevronUp24Regular, ChevronDown24Regular } from '@fluentui/react-icons';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { useState, useEffect, Fragment } from 'react';
import { Box } from './Box.js';
import { Button } from './button/Button.js';
import { RefreshButton, CollapseButton } from './button/CustomButton.js';

const SideMenu = ({ width, collapsed, collapsedSize = 'medium', selected, items, refreshIndicator, blur, shadow, groupState, canCollapseGroups, onCollapse, onRefresh, onSelect, onGroupCollapse, }) => {
    const theme = useTheme();
    //selected can be controlled by parent
    const [_selected, setSelected] = useState(
    //need to allow for empty items
    'items' in (items?.[0] ?? {})
        ? items?.[0]?.items?.[0]
        : items?.[0]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [_itemGroups, setItemGroups] = useState([]);
    const [_groupState, setGroupState] = useState(
    //need to allow for empty items
    new Array('items' in (items?.[0] ?? {}) ? items?.length ?? 1 : 1).fill(false));
    //collapsed state can be controlled by parent
    const [_collapsed, setCollapsed] = useState(false);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const [cssClass, setCssClass] = useState('');
    const handleSelect = (item) => {
        setSelected(item);
        onSelect && onSelect(item);
    };
    const handleCollapse = (c) => {
        setCollapsed(c);
        onCollapse && onCollapse(c);
    };
    const handleGroupCollapse = (index) => {
        if (canCollapseGroups) {
            const _state = [..._groupState];
            _state[index] = !(_state[index] ?? true);
            onGroupCollapse && onGroupCollapse(index, _state[index] ?? false);
            setGroupState(_state);
        }
    };
    useEffect(() => {
        //set the selectedIndex
        let itemIndex = -1;
        const groupIndex = _itemGroups?.findIndex((g) => {
            itemIndex = g.items?.findIndex((i) => i.value === (selected?.value ?? _selected?.value));
            return itemIndex > -1;
        });
        setSelectedIndex(itemIndex > -1 ? groupIndex * 100 + itemIndex : -1);
    }, [selected, _selected, _itemGroups]);
    useEffect(() => {
        setMenuCollapsed(collapsed ?? _collapsed);
        let _cssClass = `ecs-sidemenu`;
        if (collapsed ?? _collapsed)
            _cssClass += ` ecs-sidemenu-collapsed ecs-sidemenu-collapsed-${collapsedSize}`;
        setCssClass(_cssClass);
    }, [collapsed, _collapsed]);
    useEffect(() => {
        //can't use a straight index in groups
        if ('items' in (items?.[0] ?? {})) {
            // item groups
            setItemGroups(items);
        }
        else {
            //items
            setItemGroups([{ items }]);
        }
    }, [items]);
    useEffect(() => {
        setGroupState(groupState ?? new Array(_itemGroups?.length ?? 1).fill(false));
    }, [groupState, _itemGroups]);
    return (jsxs(Box, { width: menuCollapsed ? undefined : width ?? 20, direction: 'column', background: 'light', rounded: 'top', blur: blur, shadow: shadow, className: cssClass, children: [jsx(Box, { background: 'none', gap: 'none', padding: 'small', justifyContent: 'start', alignItems: 'stretch', style: { overflow: 'auto' }, children: _itemGroups?.map((itemGroup, groupindex) => (jsxs(Fragment, { children: [itemGroup.title && (jsx(Button, { color: 'info', shape: menuCollapsed ? 'square' : 'default', variant: 'text', size: 'large', disabled: !canCollapseGroups, tooltip: menuCollapsed ? (jsx(Typography, { variant: 'h4', sx: { color: 'inherit' }, children: itemGroup.title })) : undefined, tooltipPlacement: 'right', style: {
                                marginTop: groupindex === 0 ? '1rem' : '1.5rem',
                                marginBottom: '0.125rem',
                                gap: 0,
                                justifyContent: menuCollapsed ? 'center' : 'space-between',
                                ...(!menuCollapsed && {
                                    paddingLeft: '1.35rem',
                                    paddingRight: '1.5rem',
                                }),
                                ...(menuCollapsed && { padding: 0 }),
                            }, endIcon: _groupState[groupindex] ? (jsx(ChevronUp24Regular, { color: theme.palette.info.main })) : (jsx(ChevronDown24Regular, { color: theme.palette.info.main })), onClick: () => canCollapseGroups && handleGroupCollapse(groupindex), children: !menuCollapsed && (jsx(Typography, { variant: 'h3', children: itemGroup.title })) })), !_groupState[groupindex] &&
                            itemGroup.items?.map((item, index) => (jsx(SideMenuItemButton, { item: item, selected: groupindex * 100 + index === selectedIndex, showDivider: !itemGroup.title &&
                                    index !== selectedIndex &&
                                    index !== selectedIndex - 1 &&
                                    index !== items.length - 1, collapsed: menuCollapsed, size: itemGroup.title ? 'small' : 'large', onClick: (item) => handleSelect(item) }, index)))] }, groupindex))) }), jsxs(Box, { background: 'none', gap: 'small', justifyContent: 'end', alignItems: menuCollapsed ? 'center' : 'start', style: { flex: 0, overflow: 'unset' }, children: [onRefresh && (jsx(RefreshButton, { size: collapsedSize, indicator: refreshIndicator, onClick: () => onRefresh() })), onRefresh && onCollapse && jsx(SideMenuDivider, { size: collapsedSize }), onCollapse && (jsx(CollapseButton, { size: collapsedSize, direction: menuCollapsed ? 'right' : 'left', onClick: () => handleCollapse(!menuCollapsed) }))] })] }));
};
const SideMenuItemButton = ({ item, selected = false, showDivider = false, collapsed = false, size = 'small', onClick, }) => {
    const theme = useTheme();
    return (jsxs(Fragment, { children: [jsx(Button, { startIcon: item.icon, onClick: () => onClick && onClick(item), color: 'info', variant: 'text', size: 'large', shape: collapsed ? 'square' : 'default', tooltip: collapsed &&
                    `${item.title}${item.count !== undefined ? ' (' + item.count + ')' : ''}`, tooltipPlacement: 'right', 
                //todo: refine the following values for other menu sizes
                sx: {
                    height: size === 'large' ? '6.25rem' : '4rem',
                    justifyContent: collapsed ? 'center' : 'start',
                    gap: collapsed ? 0 : '0.875rem',
                    ...(collapsed && { padding: 0 }),
                    ...(!collapsed && {
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem',
                    }),
                    ...(selected && {
                        color: theme.palette.info.dark,
                        backgroundColor: theme.palette.background.paper,
                    }),
                }, children: !collapsed && (jsxs(Box, { background: 'none', direction: 'row', justifyContent: 'space-between', children: [jsx(Typography, { variant: 'h4', children: item.title }), item.count !== undefined && (jsx(Typography, { variant: 'h4', sx: {
                                color: theme.palette.info[600],
                            }, children: item.count }))] })) }), showDivider && jsx(SideMenuDivider, { size: 'full' })] }));
};
const SideMenuDivider = ({ size = 'medium', }) => {
    return (jsx("div", { className: `ecs-sidemenu-divider ecs-sidemenu-divider-${size}`, children: jsx("div", {}) }));
};

export { SideMenu };
