import { jsx, jsxs } from 'react/jsx-runtime';
import { ListItemButton, Typography } from '@mui/material';
import { memo } from 'react';
import '../config.js';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '@pdftron/webviewer';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { useIcon } from '../hooks/useIcon.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import { Box } from './Box.js';
import { StyledImage } from './SortableOptionList.js';

const getOptionIcon = (iconDetails) => {
    // check if the icon is enabled and has icon details
    if (iconDetails) {
        return (jsx(StyledImage, { alt: iconDetails.AltText, style: { height: '1.5rem' }, src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails.Url}` }));
    }
    // no icon will be displayed
    return null;
};
const CollapsibleGroupedItem = memo(({ idx, filteredOption, handleSelect, }) => {
    const { _ImageId: imageId } = filteredOption;
    const { iconDetails } = useIcon({
        imageId,
    });
    return (jsx(ListItemButton, { sx: { paddingLeft: '4rem' }, onClick: () => handleSelect(filteredOption), children: jsxs(Box, { background: 'none', direction: 'row', alignItems: 'center', children: [getOptionIcon(iconDetails), jsx(Typography, { variant: 'body2', ml: 1, children: filteredOption._Display })] }) }, idx));
});

export { CollapsibleGroupedItem, getOptionIcon };
