import { jsx, jsxs } from 'react/jsx-runtime';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { memo, useState } from 'react';
import { ListItem, ListItemButton } from '@mui/material';
import { ArrowMove20Regular } from '@fluentui/react-icons';
import { Box } from './Box.js';
import { DeleteButton } from './button/CustomButton.js';
import '../config.js';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import RetentionClass24 from '../icons/RetentionClass24.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '@pdftron/webviewer';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { useIcon } from '../hooks/useIcon.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '../store/thunks/downloadFileThunk.js';
import styled from '@emotion/styled';
import { Typography } from './Typography.js';

const StyledImage = styled('img') ``;
var Type;
(function (Type) {
    Type["RETENTION"] = "retention";
    Type["PERSPECTIVE"] = "perspective";
    Type["DEFAULT"] = "default";
})(Type || (Type = {}));
const SortableOptionList = memo(({ type, index, option, onDelete, displayIndex = true, hasDraggableItems = true, }) => {
    const { ID: id, _Display: displayName, FullPath, _ImageId: imageId, } = option;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const [isHovered, setIsHovered] = useState(false);
    const { iconDetails } = useIcon({
        imageId,
    });
    const handleMouseEnter = () => {
        !!hasDraggableItems && setIsHovered(true);
    };
    const handleMouseLeave = () => {
        !!hasDraggableItems && setIsHovered(false);
    };
    const handleOnDelete = () => {
        onDelete && onDelete();
    };
    const getOptionIcon = () => {
        // check if the icon is enabled and has icon details
        if (iconDetails) {
            return (jsx(StyledImage, { alt: iconDetails.AltText, style: { height: '1.25rem' }, src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails.Url}` }));
        }
        // no icon will be displayed
        return null;
    };
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (jsx(ListItem, { disablePadding: true, ref: setNodeRef, style: style, ...attributes, ...listeners, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: jsxs(ListItemButton, { sx: {
                padding: '0.75rem 0.25rem 0.75rem 1rem',
                height: '100%',
                maxHeight: type === Type.RETENTION ? '4.375rem' : '2.75rem',
            }, children: [jsxs(Box, { direction: 'row', background: 'none', alignItems: 'center', children: [displayIndex ? (jsx(Typography, { variant: 'body2', mr: 1, children: (index + 1).toString().padStart(2, '0') })) : null, type === Type.DEFAULT || type === Type.PERSPECTIVE ? (jsxs(Box, { direction: 'row', background: 'none', gap: 0.25, alignItems: 'center', children: [getOptionIcon(), jsx(Typography, { variant: 'body2', children: displayName })] })) : null, type === Type.RETENTION ? (jsxs(Box, { direction: 'row', background: 'none', gap: 0.5, children: [jsx(RetentionClass24, { color: option?.RootParentColor ?? undefined, style: { flexShrink: 0 } }), jsxs(Box, { direction: 'column', background: 'none', children: [jsx(Typography, { variant: 'body2', children: displayName }), jsx(Typography, { variant: 'body3', children: FullPath })] })] })) : null] }), jsxs(Box, { direction: 'row', background: 'none', justifyContent: 'end', style: { flexShrink: 0 }, children: [!!hasDraggableItems && (jsx(ArrowMove20Regular, { style: {
                                visibility: isHovered ? 'visible' : 'hidden',
                                alignSelf: 'center',
                            } })), jsx(DeleteButton, { onClick: handleOnDelete, size: 'medium' })] })] }) }));
});

export { SortableOptionList, StyledImage, Type };
