import { jsx, jsxs } from 'react/jsx-runtime';
import { CheckmarkSquare20Regular, DismissSquare20Regular, PeopleSwap20Regular, ErrorCircle20Regular, ArchiveMultiple24Regular, CompassNorthwest24Regular, PeopleQueue20Regular, New20Regular } from '@fluentui/react-icons';
import { useTheme, Button } from '@mui/material';
import { DisposalRequestResponseStatus, DisposalRequestStatus } from '../../types/requestTypes.js';
import { ItemManagementStatus } from '../../types/itemTypes.js';
import { Box } from '../Box.js';
import { Typography } from '../Typography.js';

const RequestStatusChip = ({ title, subTitle, color, iconColor, icon, variant, border = true, ...rest }) => {
    const theme = useTheme();
    let _icon = icon ?? null;
    const _textColor = color ?? theme.palette.info.main;
    const _iconColor = iconColor ?? theme.palette.info.main;
    if (variant === DisposalRequestResponseStatus.Approved) {
        _icon = jsx(CheckmarkSquare20Regular, { color: _iconColor });
    }
    else if (variant === DisposalRequestResponseStatus.Rejected) {
        _icon = jsx(DismissSquare20Regular, { color: _iconColor });
    }
    else if (variant === DisposalRequestResponseStatus.Delegated) {
        _icon = jsx(PeopleSwap20Regular, { color: _iconColor });
    }
    else if (variant === DisposalRequestResponseStatus.Pending) {
        _icon = jsx(ErrorCircle20Regular, { color: _iconColor });
    }
    else if (variant === ItemManagementStatus.Managed) {
        _icon = jsx(ArchiveMultiple24Regular, { color: _iconColor });
    }
    else if (variant === ItemManagementStatus.Discovered) {
        _icon = jsx(CompassNorthwest24Regular, { color: _iconColor });
    }
    else if (variant === DisposalRequestStatus.InApproval) {
        _icon = jsx(PeopleQueue20Regular, { color: _iconColor });
    }
    else {
        _icon = !!_icon
            ? _icon
            : (_icon = jsx(New20Regular, { color: theme.palette.info.main }));
    }
    return (jsx(Button, { disableRipple: true, startIcon: _icon, variant: 'outlined', sx: {
            background: 'none',
            color: { _textColor },
            padding: '0 0.75rem',
            minHeight: subTitle ? '3.2rem' : '2rem',
            height: 'auto',
            fontSize: '0.875rem !important',
            borderColor: border ? theme.palette.info.light : 'transparent',
            borderRadius: '0.25rem !important',
            cursor: 'default',
            '&:hover': {
                backgroundColor: 'transparent',
                borderColor: border ? theme.palette.info.light : 'transparent',
            },
        }, size: 'small', "data-testid": 'info-chip', ...rest, children: jsxs("div", { style: { fontSize: '0.875rem !important', color: _textColor }, children: [jsx(Box, { background: 'none', children: jsx(Typography, { variant: 'body2', sx: {
                            width: 'fit-content',
                        }, children: title }) }), subTitle && (jsx(Box, { background: 'none', children: jsx(Typography, { variant: 'body3', sx: {
                            fontSize: '0.775rem !important',
                            color: theme.palette.info.main,
                            width: 'fit-content',
                        }, children: subTitle }) }))] }) }));
};

export { RequestStatusChip };
