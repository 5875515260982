import { useState, useCallback, useEffect } from 'react';
import { sortPropertiesByName, getItemTypeProperties } from '../components/item/ItemPropertiesUtil.js';
import { isEqual } from 'lodash';

const useItemPropertiesMenuItem = ({ item, businessTypeTreeGridData, businessTypeName, itemTypeName, gridRef, selectedTab, propLinksData, }) => {
    const [menuItem, setMenuItem] = useState('');
    const storeTreeGridData = (data) => localStorage.setItem("treeGridResultProperties", JSON.stringify(data));
    const getTreeGridData = useCallback((filterValue) => {
        if (!gridRef.current)
            return [];
        switch (filterValue) {
            case businessTypeName:
                return sortPropertiesByName(businessTypeTreeGridData ?? []);
            case itemTypeName:
                return sortPropertiesByName(getItemTypeProperties(item, propLinksData));
            default:
                return sortPropertiesByName([
                    ...(businessTypeTreeGridData ?? []),
                    ...getItemTypeProperties(item, propLinksData),
                ]);
        }
    }, [businessTypeName, itemTypeName, item, businessTypeTreeGridData, propLinksData]);
    useEffect(() => {
        let _menuItem = '';
        if (!!businessTypeName)
            _menuItem = businessTypeName;
        else if (!!itemTypeName)
            _menuItem = itemTypeName;
        else
            _menuItem = 'All Properties';
        setMenuItem(_menuItem);
    }, [businessTypeName, itemTypeName, selectedTab]);
    useEffect(() => {
        if (!menuItem)
            return;
        const gridResult = getTreeGridData(menuItem);
        storeTreeGridData(gridResult);
        gridRef.current.dataSource = gridResult;
        gridRef.current.refresh();
    }, [menuItem]);
    useEffect(() => {
        if (!menuItem)
            return;
        const gridResult = getTreeGridData(menuItem);
        if (!isEqual(gridRef.current.dataSource, gridResult)) {
            storeTreeGridData(gridResult);
            gridRef.current.dataSource = gridResult;
            gridRef.current.refreshColumns();
            gridRef.current.hideSpinner();
        }
    }, [item, businessTypeTreeGridData]);
    const onChange = useCallback((option) => setMenuItem(option.target.value), []);
    return { menuItem, onChange, treeGridData: getTreeGridData(menuItem) };
};

export { useItemPropertiesMenuItem };
