import { jsxs, jsx } from 'react/jsx-runtime';
import { ArrowRight20Regular } from '@fluentui/react-icons';
import { useTheme, Link, Typography } from '@mui/material';

const LinkWithArrow = ({ title, onClick, onContextMenu, variant = 'body1', sx, }) => {
    const theme = useTheme();
    return (jsxs(Link, { sx: {
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'hidden',
            '&>#title': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: variant == 'body2'
                    ? theme.palette.info.main
                    : `${theme.palette.info.dark} !important`,
            },
            '&>#arrow': { opacity: 0, flexShrink: 0 },
            '&:hover': {
                '&>#title': {
                    cursor: 'pointer',
                    color: `${theme.palette.primary.main} !important`,
                },
                '&>#arrow': { opacity: 1 },
            },
            ...sx,
        }, onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick && onClick();
        }, onContextMenu: (e) => onContextMenu && onContextMenu(e), children: [jsxs(Typography, { id: 'title', noWrap: true, variant: variant, children: [title, "\u00A0"] }), jsx(ArrowRight20Regular, { id: 'arrow' })] }));
};

export { LinkWithArrow };
