import { jsx, jsxs } from 'react/jsx-runtime';
import { DisposalRequestResponseStatus, DisposalRequestResponseStatusCaption } from '../types/requestTypes.js';
import '../types/itemTypes.js';
import { useTheme, Typography } from '@mui/material';

const DisposalRequestResponseStatusText = ({ status, width, }) => {
    const theme = useTheme();
    let style = {
        width: width,
        minWidth: width,
        maxWidth: width,
        color: theme.palette.info.main,
    };
    if (status === DisposalRequestResponseStatus.Delegated) {
        style = { ...style };
    }
    else if (status === DisposalRequestResponseStatus.Approved) {
        style = { ...style, color: theme.palette.primary.main };
    }
    else if (status === DisposalRequestResponseStatus.Rejected) {
        style = { ...style, color: theme.palette.warning.main };
    }
    else if (status === DisposalRequestResponseStatus.Pending) {
        style = {
            ...style,
            color: theme.palette.info.dark,
            fontWeight: '800',
        };
    }
    else if (status === DisposalRequestResponseStatus.Waiting) {
        return jsx(Typography, { style: style });
    }
    return (jsxs(Typography, { sx: style, variant: 'body2', children: [DisposalRequestResponseStatusCaption[status], status === DisposalRequestResponseStatus.Pending && '...'] }));
};

export { DisposalRequestResponseStatusText };
