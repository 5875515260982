import { jsx } from 'react/jsx-runtime';
import { memo, useState, useEffect } from 'react';
import { Box } from '../../Box.js';
import { ItemDisposalStatusChip } from '../../chip/ItemDisposalStatusChip.js';
import { DisposalRequestStatus } from '../../../types/requestTypes.js';
import '../../../types/itemTypes.js';

const DisposalStatusTemplate = ({ item, disposalRequest, }) => {
    const [retentionClassLookup, setRetentionClassLookup] = useState({});
    const renderDisposalStatusChip = () => {
        let disposalRequestIndex = 0;
        return (item.DisposalStates?.value ?? []).map((disposalState, i) => {
            let status = '';
            // If DisposalStates' DisposalStatus is AssignedToRequest,
            // DisposalRequest' Status will be checked. If Status is Approved or Rejected,
            // this value will be used
            if (disposalState?.DisposalStatus === 'AssignedToRequest') {
                if (item?.DisposalRequests?.value[disposalRequestIndex]?.Status ===
                    DisposalRequestStatus.Approved ||
                    item?.DisposalRequests?.value[disposalRequestIndex]?.Status ===
                        DisposalRequestStatus.Rejected) {
                    status = item?.DisposalRequests?.value[disposalRequestIndex]?.Status;
                }
                else
                    status = disposalState?.DisposalStatus;
                disposalRequestIndex++;
            }
            else
                status = disposalState?.DisposalStatus;
            return (jsx(ItemDisposalStatusChip, { status: status, subTitle: retentionClassLookup[disposalState.RetentionClassId ?? 'unknown']
                    ?.DisplayName ?? '-' }, i));
        });
    };
    useEffect(() => {
        const _lookup = {};
        item.Classifications?.value?.forEach((rc) => (_lookup[rc.ID] = rc));
        setRetentionClassLookup(_lookup);
    }, [item.Classifications?.value]);
    return (jsx(Box, { background: 'none', alignItems: 'start', children: renderDisposalStatusChip() }));
};
const MemoizedDisposalStatusTemplate = memo(DisposalStatusTemplate);

export { DisposalStatusTemplate, MemoizedDisposalStatusTemplate };
