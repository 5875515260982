import { jsx } from 'react/jsx-runtime';
import { Clock24Regular, Alert24Regular, Layer24Regular, CheckmarkSquare24Regular, DismissSquare24Regular, ArrowClockwiseDashes24Regular, Delete24Regular, ErrorCircle24Regular, Square24Regular } from '@fluentui/react-icons';
import SvgReadyToDestroy24 from '../../icons/ReadyToDestroy24.js';
import { DisposalRequestStatus } from '../../types/requestTypes.js';
import { ItemDisposalStatus, ItemDisposalStatusCaption } from '../../types/itemTypes.js';
import { Chip } from './Chip.js';

//TODO: this will need more work to handle an unknown status value
const ItemDisposalStatusChip = ({ status, border = false, ...props }) => {
    // can make these global if needed
    const _icon = {
        [ItemDisposalStatus.Pending]: jsx(Clock24Regular, {}),
        [ItemDisposalStatus.Triggered]: jsx(Alert24Regular, {}),
        [ItemDisposalStatus.AssignedToRequest]: jsx(Layer24Regular, {}),
        [ItemDisposalStatus.DisposalApproved]: jsx(CheckmarkSquare24Regular, {}),
        [ItemDisposalStatus.DisposalRejected]: jsx(DismissSquare24Regular, {}),
        [ItemDisposalStatus.ReadyToTransfer]: jsx(SvgReadyToDestroy24, {}),
        [ItemDisposalStatus.ReadyToDestroy]: jsx(SvgReadyToDestroy24, {}),
        [ItemDisposalStatus.DestructionInProgress]: (jsx(ArrowClockwiseDashes24Regular, {})),
        [ItemDisposalStatus.Destroyed]: jsx(Delete24Regular, {}),
        [ItemDisposalStatus.Transferred]: jsx(Delete24Regular, {}),
        [ItemDisposalStatus.DisposalFailed]: jsx(ErrorCircle24Regular, {}),
        [ItemDisposalStatus.DestructionActioned]: jsx(SvgReadyToDestroy24, {}),
        [DisposalRequestStatus.Approved]: jsx(CheckmarkSquare24Regular, {}),
        [DisposalRequestStatus.Rejected]: jsx(DismissSquare24Regular, {}),
    };
    const _color = {
        [ItemDisposalStatus.Pending]: 'default',
        [ItemDisposalStatus.Triggered]: 'default',
        [ItemDisposalStatus.AssignedToRequest]: 'default',
        [ItemDisposalStatus.DisposalApproved]: 'primary',
        [ItemDisposalStatus.DisposalRejected]: 'warning',
        [ItemDisposalStatus.ReadyToTransfer]: 'default',
        [ItemDisposalStatus.ReadyToDestroy]: 'default',
        [ItemDisposalStatus.DestructionInProgress]: 'default',
        [ItemDisposalStatus.Destroyed]: 'default',
        [ItemDisposalStatus.Transferred]: 'default',
        [ItemDisposalStatus.DisposalFailed]: 'error',
        [ItemDisposalStatus.DestructionActioned]: 'default',
        [DisposalRequestStatus.Approved]: 'primary',
        [DisposalRequestStatus.Rejected]: 'warning',
    };
    return (jsx(Chip, { ...props, border: border, title: ItemDisposalStatusCaption[status] ?? status, icon: _icon[status] ?? jsx(Square24Regular, {}), color: _color[status] ?? 'default' }));
};

export { ItemDisposalStatusChip };
