import { jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { Search20Regular } from '@fluentui/react-icons';
import { palette } from '../themes/light.theme.js';
import { debounce } from 'lodash';

const SearchField = ({ height, width, onHandleSearch, }) => {
    const [searchText, setSearchText] = useState('');
    const handleSearchTextChange = (event) => {
        const { value } = event.target;
        setSearchText(value);
    };
    const debouncedOnHandleSearch = debounce((value) => {
        onHandleSearch && onHandleSearch(value);
    }, 500);
    useEffect(() => {
        debouncedOnHandleSearch(searchText);
        return () => debouncedOnHandleSearch.cancel();
    }, [searchText]);
    return (jsx(TextField, { placeholder: 'Search', variant: 'outlined', value: searchText, onChange: handleSearchTextChange, InputProps: {
            className: searchText ? 'Mui-typing' : '',
            endAdornment: (jsx(InputAdornment, { position: 'start', children: jsx(Search20Regular, { style: { color: palette.primary } }) })),
        }, sx: {
            width,
            height,
        } }));
};

export { SearchField };
