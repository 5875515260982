import { jsx } from 'react/jsx-runtime';
import { Chip } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { Tooltip } from '../Tooltip.js';
import { palette } from '../../themes/light.theme.js';

const Indicator = ({ label, size, variant, color = 'default', sx, isRejected = false, ...props }) => {
    const dataRef = useRef(null);
    const [hasTooltip, setHasTooltip] = useState(false);
    const toggleTooltip = () => {
        if (!dataRef.current)
            return;
        const labelElement = dataRef.current.querySelector('.MuiChip-label');
        const clientWidth = labelElement.clientWidth;
        const scrollWidth = labelElement.scrollWidth;
        if (scrollWidth > clientWidth)
            setHasTooltip(true);
        else
            setHasTooltip(false);
    };
    useEffect(() => {
        toggleTooltip();
        window.addEventListener('resize', toggleTooltip);
        return () => window.removeEventListener('resize', toggleTooltip);
    }, [label]);
    const getIndicatorStyling = () => {
        if (color === 'primary')
            return {
                ...sx,
                backgroundColor: palette.aqua[800],
                color: palette.purewhite,
            };
        else if (color === 'warning' && isRejected)
            return {
                ...sx,
                backgroundColor: palette.juice[900],
                color: palette.purewhite,
            };
        else
            return {
                ...sx,
            };
    };
    return (jsx(Tooltip, { title: hasTooltip ? label : '', arrow: true, children: jsx(Chip, { ...props, sx: getIndicatorStyling(), clickable: !!props.onClick, onDelete: undefined, label: label, color: color, variant: 'filled', size: 'small', ref: dataRef }) }));
};

export { Indicator };
