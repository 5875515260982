import { getLogoForApp } from './appLogos.js';

// gets the authorised apps for the specified user
const getAuthorisedApps = (user) => {
    const applications = user && user['ConfiguredApps']
        ? user['ConfiguredApps']
            .filter((app) => app.IsAuthorised) // Only show apps we are authorised to use
            .map((app) => {
            return {
                DisplayName: formatDisplayName(app.DisplayName),
                Logo: getLogoForApp[app.DisplayName],
                Homepage: app.Homepage,
                IsAuthorised: app.IsAuthorised,
            };
        })
        : [];
    return applications;
};
// abbreviates the display name if the display name has more than
// 2 words in it
const formatDisplayName = (name) => {
    let formattedName = '';
    let components = name.split(' ');
    if (components.length > 2) {
        for (let index in components) {
            formattedName = `${formattedName}${components[index].charAt(0)}`;
        }
    }
    else {
        formattedName = name;
    }
    return formattedName;
};
///
/// Groups the user applications by purpose
///
const groupUserAppsByPurpose = (user) => {
    let apps = getAuthorisedApps(user);
    let appGroups = JSON.parse('[]');
    // group the apps by its purpose
    const groupByPurpose = apps.reduce((group, app) => {
        // Temporarily grouping all the Apps by the "Apps" purpose, until we have more apps to display
        const Purpose = 'Apps';
        let purposeForDisplay = Purpose;
        group[purposeForDisplay] =
            group[Purpose] == null ? [] : group[purposeForDisplay];
        group[purposeForDisplay].push(app);
        return group;
    }, {});
    for (let key in groupByPurpose) {
        let appGroup = JSON.parse('{}');
        appGroup.purpose = key;
        appGroup.applications = groupByPurpose[key];
        appGroups.push(appGroup);
    }
    //   sort the groups by Purpose alphabetically
    let sortedGroups = appGroups.sort((g1, g2) => g1.Purpose < g2.Purpose ? -1 : g1.Purpose > g2.Purpose ? 1 : 0);
    return sortedGroups;
};

export { formatDisplayName, getAuthorisedApps, groupUserAppsByPurpose };
