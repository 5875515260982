import { jsx } from 'react/jsx-runtime';
import { DocumentHeaderFooter24Filled } from '@fluentui/react-icons';
import { Avatar } from '@mui/material';

const ImageWithFallback = ({ variant = 'square', children, size = 'default', color = 'none', sx, ...props }) => {
    return (jsx(Avatar, { variant: variant, ...props, className: 'MuiAvatar-item', sx: {
            color: 'unset',
            background: 'none',
            minWidth: '1.5rem',
            maxWidth: '1.5rem',
            minHeight: '1.5rem',
            maxHeight: '1.5rem',
            ...(size === 'large' && {
                minWidth: '2rem',
                maxWidth: '2rem',
                minHeight: '2rem',
                maxHeight: '2rem',
            }),
            ...sx,
        }, children: children ?? jsx(DocumentHeaderFooter24Filled, {}) }));
};

export { ImageWithFallback };
