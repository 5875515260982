import { jsx, jsxs } from 'react/jsx-runtime';
import { MsalProvider, MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfiguration, loginRequest } from './config.js';
import { RedirectOnLogout, AuthErrorComponent, RedirectOnError } from './AuthComponents.js';

// Todo: create singleton to make sure there is always one instance
const publicClientApplication = new PublicClientApplication(msalConfiguration);
const AuthProvider = ({ children }) => {
    return (jsx(RedirectOnLogout, { children: jsx(MsalProvider, { instance: publicClientApplication, children: jsx(MsalAuthenticationTemplate, { interactionType: InteractionType.Redirect, authenticationRequest: loginRequest, errorComponent: AuthErrorComponent, children: jsx(RedirectOnError, { children: children }) }) }) }));
};
const AuthProviderWithLogoutComponent = ({ children, }) => {
    return (jsxs(MsalProvider, { instance: publicClientApplication, children: [jsx(AuthenticatedTemplate, { children: children }), jsx(UnauthenticatedTemplate, {})] }));
};
// This auth provider is to be used with the AuthenticatedChildren and Unauthenticated children
//    View the HOME application for an example of its use
const AuthProviderBasic = ({ children }) => {
    return (jsx(MsalProvider, { instance: publicClientApplication, children: children }));
};
const AuthenticatedChildren = ({ children }) => {
    return jsx(AuthenticatedTemplate, { children: children });
};
const UnauthenticatedChildren = ({ children }) => {
    return jsx(UnauthenticatedTemplate, { children: children });
};

export { AuthProvider, AuthProviderBasic, AuthProviderWithLogoutComponent, AuthenticatedChildren, UnauthenticatedChildren, publicClientApplication };
