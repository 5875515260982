var DisposalRequestAction;
(function (DisposalRequestAction) {
    DisposalRequestAction["None"] = "None";
    DisposalRequestAction["Destroy"] = "Destroy";
    DisposalRequestAction["Transfer"] = "Transfer";
    DisposalRequestAction["Retain"] = "Retain";
})(DisposalRequestAction || (DisposalRequestAction = {}));
const DisposalRequestActionCaption = {
    [DisposalRequestAction.None]: 'None',
    [DisposalRequestAction.Destroy]: 'Destroy',
    [DisposalRequestAction.Transfer]: 'Transfer',
    [DisposalRequestAction.Retain]: 'Retain',
};
//can support conjugation of the actions for future use
const DisposalRequestActionDoingCaption = {
    [DisposalRequestAction.None]: 'None',
    [DisposalRequestAction.Destroy]: 'Destruction in Progress',
    [DisposalRequestAction.Transfer]: 'Transfer in Progress',
    [DisposalRequestAction.Retain]: 'Retention in Progress',
};
const DisposalRequestActionDoneCaption = {
    [DisposalRequestAction.None]: 'None',
    [DisposalRequestAction.Destroy]: 'Destroyed',
    [DisposalRequestAction.Transfer]: 'Transferred',
    [DisposalRequestAction.Retain]: 'Retained',
};
//these values will change
var DisposalRequestStatus;
(function (DisposalRequestStatus) {
    DisposalRequestStatus["New"] = "New";
    DisposalRequestStatus["InApproval"] = "ApprovalPending";
    DisposalRequestStatus["Approved"] = "Approved";
    DisposalRequestStatus["Rejected"] = "Rejected";
    DisposalRequestStatus["InProgress"] = "InProgress";
    DisposalRequestStatus["ItemDestroyInProgress"] = "ItemDestroyInProgress";
    DisposalRequestStatus["Complete"] = "Complete";
    DisposalRequestStatus["Failed"] = "Abandoned";
    DisposalRequestStatus["ItemDestroyComplete"] = "ItemDestroyComplete";
    DisposalRequestStatus["InProgressOrComplete"] = "InProgressOrComplete"; // compound status for filtering
})(DisposalRequestStatus || (DisposalRequestStatus = {}));
const DisposalRequestStatusCaption = {
    [DisposalRequestStatus.New]: 'New',
    [DisposalRequestStatus.InApproval]: 'In Approval',
    [DisposalRequestStatus.Approved]: 'Approved',
    [DisposalRequestStatus.Rejected]: 'Rejected',
    [DisposalRequestStatus.InProgress]: 'Destruction Actioned',
    [DisposalRequestStatus.ItemDestroyInProgress]: DisposalRequestActionDoingCaption[DisposalRequestAction.Destroy],
    [DisposalRequestStatus.Complete]: 'Destruction Complete',
    [DisposalRequestStatus.InProgressOrComplete]: 'Destruction Actioned',
    [DisposalRequestStatus.Failed]: 'Failed to ' + DisposalRequestActionCaption[DisposalRequestAction.Destroy],
    [DisposalRequestStatus.ItemDestroyComplete]: DisposalRequestActionDoneCaption[DisposalRequestAction.Destroy],
};
//separate enum for responses
var DisposalRequestResponseStatus;
(function (DisposalRequestResponseStatus) {
    DisposalRequestResponseStatus["Pending"] = "PendingApproval";
    DisposalRequestResponseStatus["Waiting"] = "Waiting";
    DisposalRequestResponseStatus["Approved"] = "Approved";
    DisposalRequestResponseStatus["Delegated"] = "Delegated";
    DisposalRequestResponseStatus["Rejected"] = "Rejected";
})(DisposalRequestResponseStatus || (DisposalRequestResponseStatus = {}));
const DisposalRequestResponseStatusCaption = {
    [DisposalRequestResponseStatus.Pending]: 'Pending',
    [DisposalRequestResponseStatus.Waiting]: 'Waiting',
    [DisposalRequestResponseStatus.Approved]: 'Approved',
    [DisposalRequestResponseStatus.Delegated]: 'Delegated',
    [DisposalRequestResponseStatus.Rejected]: 'Rejected',
};

export { DisposalRequestAction, DisposalRequestActionCaption, DisposalRequestActionDoingCaption, DisposalRequestActionDoneCaption, DisposalRequestResponseStatus, DisposalRequestResponseStatusCaption, DisposalRequestStatus, DisposalRequestStatusCaption };
