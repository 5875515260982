import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from '../Box.js';
import { useTheme, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material';
import { useState } from 'react';
import { Button } from '../button/Button.js';
import { Edit20Regular } from '@fluentui/react-icons';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import { useGetPerspectiveClassesQuery } from '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import { OptionMultiSelect } from '../OptionMultiSelect.js';
import { Type } from '../SortableOptionList.js';
import { isEqual } from 'lodash';

const EditPerspectiveClassesDialog = ({ open, title, onSave, onClose, fieldLabel, propertyName, propertyValue, }) => {
    const theme = useTheme();
    const [selectedOptions, setSelectedOptions] = useState(propertyValue ?? []);
    const { data } = useGetPerspectiveClassesQuery({});
    const perspectiveClasses = data?.value;
    const getSelectedOptions = (_selectedOptions) => {
        setSelectedOptions(_selectedOptions);
    };
    const disabledSaveButton = isEqual(propertyValue?.map((x) => x?.ID), selectedOptions?.map((y) => y?.ID)) || selectedOptions === propertyValue;
    return (jsxs(Dialog, { open: open, PaperProps: {
            sx: {
                width: '100%',
                height: '100%',
                maxWidth: '47.625rem',
                maxHeight: '42.438rem',
            },
        }, children: [jsxs(DialogTitle, { children: [jsx(Edit20Regular, {}), title] }), jsx(DialogContent, { children: jsxs(Box, { background: 'none', children: [jsx(Typography, { mb: 1, variant: 'body1', sx: { color: theme.palette.info.dark }, children: fieldLabel }), jsx(OptionMultiSelect, { groupBy: 'FullPath', type: Type.PERSPECTIVE, hasDraggableItems: false, options: perspectiveClasses, propertyValue: propertyValue, placeholder: '- Add a perspective class -', getSelectedOptions: getSelectedOptions })] }) }), jsxs(DialogActions, { children: [jsx(Button, { variant: 'contained', disabled: disabledSaveButton, onClick: () => !!onSave && onSave(selectedOptions, propertyName), children: "Save" }), jsx(Button, { variant: 'outlined', onClick: onClose, children: "Cancel" })] })] }));
};

export { EditPerspectiveClassesDialog };
