import { jsxs, jsx } from 'react/jsx-runtime';
import { Info24Regular } from '@fluentui/react-icons';
import { useTheme, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material';
import { Button } from '../button/Button.js';

const InformationDialog = ({ title, variant = 'default', icon, open, information, onClose, }) => {
    const theme = useTheme();
    return (jsxs(Dialog, { open: !!open, PaperProps: {
            sx: {
                minWidth: '26rem',
                maxWidth: '42rem',
                minHeight: '4rem',
                maxHeight: '30rem',
            },
        }, children: [jsxs(DialogTitle, { sx: {
                    ...(variant === 'error' && {
                        backgroundColor: theme.palette.warning[50],
                    }),
                }, children: [icon ?? jsx(Info24Regular, {}), title ?? (variant === 'error' ? 'Error' : 'Information')] }), jsx(DialogContent, { children: jsx(Typography, { variant: 'body1', children: information }) }), jsx(DialogActions, { children: jsx(Button, { variant: 'contained', onClick: onClose, children: "OK" }) })] }));
};

export { InformationDialog, InformationDialog as default };
