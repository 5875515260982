import '../services/requestApi.js';
import { useGetUserServiceAccessQuery } from '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';

const useGetUserServiceAccess = () => {
    const { data, error, isLoading } = useGetUserServiceAccessQuery();
    if (error) {
        console.error('Failed to fetch user service access:', error);
        return { error: 'Failed to fetch user service access', isFetching: isLoading };
    }
    return { data: data, isFetching: isLoading };
};

export { useGetUserServiceAccess };
