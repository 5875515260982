import { jsx } from 'react/jsx-runtime';

//create super component for div with layout
const Box = ({ children, background = 'default', rounded = 'none', direction = 'column', border = 'none', borderColor = 'dark', borderStyle, padding = 'none', gap = 'none', width, height, alignItems, justifyContent, shrink, className, style, blur, shadow, ...props }) => {
    let _className = `ecs-box ecs-background-${background} ecs-rounded-${rounded} ecs-box-${direction} ecs-padding-${padding} ecs-gap-${gap} ecs-border-${border} ecs-border-color-${borderColor} `;
    if (blur)
        _className += 'ecs-box-blur ';
    if (shadow)
        _className += 'ecs-box-shadow ';
    //can apply custom className
    _className = _className + (className ?? '');
    const _width = width && (typeof width === 'number' ? `${width}rem` : width);
    const _height = height && (typeof height === 'number' ? `${height}rem` : height);
    let _style = {
        ...(alignItems && { alignItems }),
        ...(justifyContent && { justifyContent }),
        ...(width && { width: _width }),
        ...(width && { flex: `0 0 auto` }),
        ...(height && { height: _height }),
        ...(height && { flex: `0 0 auto` }),
        ...(width && height && { flex: 'none' }),
        ...(style && style),
        ...(shrink && { flex: `0 0 auto` }),
        ...(typeof gap === 'number' && { gap: `${gap}rem` }),
        ...(typeof border === 'number' && { borderWidth: `${border}rem` }),
        ...(border && { borderStyle: borderStyle ?? 'solid' }),
    };
    return (jsx("div", { className: _className, ...props, style: _style, children: children }));
};

export { Box };
