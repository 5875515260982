import { Banner, Box, Button, Page } from '@encompaas/common/components';
import { EncompaasLogo } from '@encompaas/common/images';
import { useNavigate } from 'react-router-dom';
import { TEST_CONSTANTS } from '@encompaas/common/constants';

const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <Page>
      <Box alignItems='center' justifyContent='center'>
        <EncompaasLogo
          style={{ height: '30rem' }}
          data-testid={TEST_CONSTANTS.ENCOMPAAS_LOGO}
        />
        <Banner
          height={40}
          colour='dark'
          data-testid={TEST_CONSTANTS.ENCOMPAAS_BANNER}
        />
        <Box height={'5rem'} />
        <Button color='secondary' size='large' onClick={() => navigate('/')}>
          Login
        </Button>
      </Box>
    </Page>
  );
};

export default LoginPage;
