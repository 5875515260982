import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import '../auth/config.js';
import 'react/jsx-runtime';
import { config } from '../config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'react';
import { DisposalRequestStatus } from '../types/requestTypes.js';
import '../types/itemTypes.js';
import { prepareHeaders } from '../auth/prepareHeaders.js';

const ENDPOINT = 'DisposalRequest';
const requestApi = createApi({
    reducerPath: 'requestApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.API_BASE_URL,
        prepareHeaders,
    }),
    tagTypes: ['DisposalRequest'],
    endpoints: (builder) => ({
        /** This function doesn't seem to be used */
        getRequestsByApprovalStatus: builder.query({
            query: ({ userId, status }) => {
                return {
                    /* When this is used, please limit the properties requested in $select=* */
                    url: `${ENDPOINT}?$select=*&$expand=RequestedBy,ApproverResponses($select=ApproverId,Status,DateResponded,ResponseComments,Order,DelegatedToId,ApproverIdToStatus;$expand=Approver,DelegatedTo)&$filter=ApproverResponses/ApproverIdToStatus eq '${userId}_${status}'`,
                    method: 'GET',
                };
            },
        }),
        /** This function doesn't seem to be used */
        getRequestsByStatus: builder.query({
            query: ({ status }) => {
                return {
                    /* When this is used, please limit the properties requested in $select=* */
                    url: `${ENDPOINT}?$select=*&$expand=RequestedBy,ApproverResponses($select=ApproverId,Status,DateResponded,ResponseComments,Order,DelegatedToId,ApproverIdToStatus;$expand=Approver,DelegatedTo)&$filter=status eq '${status}'`,
                    method: 'GET',
                };
            },
        }),
        getRequestCountsByApprovalStatus: builder.query({
            query: ({ userId }) => {
                return {
                    url: `${ENDPOINT}?$expand=ApproverResponses&$filter=ApproverResponses/ApproverId eq ${userId}&$apply=groupby((ApproverResponses/ApproverIdToStatus), aggregate($count as 'DRs'))`,
                    method: 'GET',
                };
            },
            transformResponse: ({ value: requestCountList, }, meta, args) => {
                const counts = [];
                requestCountList?.forEach((count) => {
                    const [_userId, _status] = count['GroupBy_ApproverResponses.ApproverIdToStatus']?.split('_') ?? ['unkown', 'unknown'];
                    if (_userId === args.userId) {
                        counts.push({
                            Count: count.$count,
                            UserId: _userId,
                            Status: _status,
                        });
                    }
                });
                return counts;
            },
            providesTags: ['DisposalRequest'],
        }),
        getRequestCountsByStatus: builder.query({
            query: () => {
                return {
                    url: `${ENDPOINT}?$apply=groupby((Status), aggregate($count as 'DRs'))`,
                    method: 'GET',
                };
            },
            transformResponse: ({ value: requestCountList, }, meta, args) => {
                const counts = [];
                requestCountList?.forEach((count) => {
                    counts.push({
                        Count: count.$count,
                        Status: count['GroupBy_Status'],
                    });
                });
                return counts;
            },
            providesTags: ['DisposalRequest'],
        }),
        getRequestDetails: builder.query({
            query: ({ requestId }) => {
                const selectQuery = 'Name,DisposalAction,Status,DateRequested,DateCreatedInEnc,RequestComments,EncompaasUrl';
                return {
                    url: `${ENDPOINT}('${requestId}')?$select=${selectQuery}&$expand=RequestedBy,ApproverResponses($select=ApproverId,Status,DateResponded,ResponseComments,Order,DelegatedToId,ApproverIdToStatus;$expand=Approver,DelegatedTo),RetentionClass($select=ID,DisplayName,FullPath)`,
                    method: 'GET',
                };
            },
            providesTags: ['DisposalRequest'],
        }),
        getRequestItems: builder.query({
            query: ({ requestId }) => {
                const BASE_URL = `${config.API_BASE_URL}/Enc.Item?`;
                const selectQuery = 'ID';
                return {
                    url: `${BASE_URL}$filter=DisposalRequests/ID eq ${requestId}&$select=${selectQuery}&$expand=DisposalStates($select=DisposalStatus,RetentionClassId)&$inlinecount=allpages`,
                    method: 'GET',
                };
            },
        }),
        getApproversList: builder.query({
            // TODO: set filter=IsActive eq true to allow users with access only
            query: () => {
                return {
                    url: `User?$top=50&$count=true&$select=ID,_Display,Department,Mail&$orderby=department`,
                    method: 'GET',
                };
            },
        }),
        getApproversListWithParams: builder.query({
            query: ({ searchFor }) => {
                if (!!searchFor) {
                    return {
                        url: `User?$top=50&$count=true&$select=ID,_Display,Department,Mail&$filter=contains(_Display, '${searchFor}') or contains(Department, '${searchFor}')&$orderby=department`,
                        method: 'GET',
                    };
                }
                return {
                    url: `User?$top=50&$count=true&$select=ID,_Display,Department,Mail&$orderby=department`,
                    method: 'GET',
                };
            },
        }),
        getDestructionInProgressItemsCount: builder.query({
            query: () => {
                return {
                    url: `Item?$count=true&$filter=DisposalStates/Any(dr:dr/DisposalStatus eq 'DestructionActioned') AND DisposalDetails/Any(ds:ds/FailureDetail in ('None','Src_DeletionSuccessful') AND ds/CompletedDate eq null)`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                return { count: response['@odata.count'] };
            },
            providesTags: ['DisposalRequest'],
        }),
        getFailedToDestroyItemsCount: builder.query({
            query: () => {
                return {
                    url: `Item?$count=true&$filter=(DisposalDetails/Any(dd:startswith(dd/FailureDetail,'Src_') or startswith(dd/FailureDetail,'Enc_')))`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                return { count: response['@odata.count'] };
            },
            providesTags: ['DisposalRequest'],
        }),
        getDestroyedItemsCount: builder.query({
            query: () => {
                return {
                    url: `Item?$count=true&$filter=Status eq 'Destroyed'`,
                    method: 'GET',
                };
            },
            transformResponse: (response) => {
                return { count: response['@odata.count'] };
            },
            providesTags: ['DisposalRequest'],
        }),
        approveDisposalRequest: builder.mutation({
            query: ({ comment, requestId }) => ({
                url: `/${ENDPOINT}('${requestId}')/Approve?`,
                method: 'POST',
                body: { comments: comment },
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['DisposalRequest'],
        }),
        rejectDisposalRequest: builder.mutation({
            query: ({ comment, requestId }) => ({
                url: `/${ENDPOINT}('${requestId}')/Reject?`,
                method: 'POST',
                body: { comments: comment },
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['DisposalRequest'],
        }),
        delegateDisposalRequest: builder.mutation({
            query: ({ comment, delegateToUserId, requestId }) => ({
                url: `/${ENDPOINT}('${requestId}')/Delegate`,
                method: 'POST',
                body: { comments: comment, delegatedToUserId: delegateToUserId },
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['DisposalRequest'],
        }),
        updateDisposalRequest: builder.mutation({
            query: ({ name, comment, requestId, approversList, requestedBy }) => ({
                url: `/DisposalRequest('${requestId}')`,
                method: 'PATCH',
                body: {
                    Status: DisposalRequestStatus.InApproval,
                    RequestedBy: { ID: requestedBy },
                    Name: name,
                    RequestComments: comment,
                    ApproverResponses: {
                        value: approversList,
                    },
                },
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['DisposalRequest'],
        }),
        updateDisposalRequestApprovers: builder.mutation({
            query: ({ requestId, approversList, requestedById }) => ({
                url: `/DisposalRequest('${requestId}')`,
                method: 'PATCH',
                body: {
                    ApproverResponses: {
                        value: approversList,
                    },
                    ...(requestedById && { RequestedBy: { ID: requestedById } }),
                },
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['DisposalRequest'],
        }),
        updateDisposalRequestProps: builder.mutation({
            query: ({ requestId, body }) => ({
                url: `/DisposalRequest('${requestId}')`,
                method: 'PATCH',
                body: body,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['DisposalRequest'],
        }),
        appendNotes: builder.mutation({
            query: ({ body }) => ({
                url: `/DisposalRequest/AppendNotes`,
                method: 'POST',
                body: body,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['DisposalRequest'],
        }),
        startDisposalJob: builder.mutation({
            query: ({ requestId }) => {
                const BASE_URL = `${config.API_BASE_URL}`;
                return {
                    url: `${BASE_URL}/DisposalRequest('${requestId}')/StartDisposalJob`,
                    method: 'GET',
                };
            },
        }),
        closeDisposal: builder.mutation({
            query: ({ requestId }) => {
                const BASE_URL = `${config.API_BASE_URL}`;
                return {
                    url: `${BASE_URL}/DisposalRequest('${requestId}')/Close`,
                    method: 'GET',
                };
            },
        }),
    }),
});
const { useGetRequestsByApprovalStatusQuery, useGetRequestsByStatusQuery, useGetRequestCountsByApprovalStatusQuery, useGetRequestCountsByStatusQuery, useGetRequestDetailsQuery, useApproveDisposalRequestMutation, useRejectDisposalRequestMutation, useDelegateDisposalRequestMutation, useGetApproversListQuery, useUpdateDisposalRequestMutation, useUpdateDisposalRequestApproversMutation, useUpdateDisposalRequestPropsMutation, useAppendNotesMutation, useGetRequestItemsQuery, useStartDisposalJobMutation, useCloseDisposalMutation, useLazyGetApproversListWithParamsQuery, useGetDestructionInProgressItemsCountQuery, useGetDestroyedItemsCountQuery, useGetFailedToDestroyItemsCountQuery, } = requestApi;

export { requestApi, useAppendNotesMutation, useApproveDisposalRequestMutation, useCloseDisposalMutation, useDelegateDisposalRequestMutation, useGetApproversListQuery, useGetDestroyedItemsCountQuery, useGetDestructionInProgressItemsCountQuery, useGetFailedToDestroyItemsCountQuery, useGetRequestCountsByApprovalStatusQuery, useGetRequestCountsByStatusQuery, useGetRequestDetailsQuery, useGetRequestItemsQuery, useGetRequestsByApprovalStatusQuery, useGetRequestsByStatusQuery, useLazyGetApproversListWithParamsQuery, useRejectDisposalRequestMutation, useStartDisposalJobMutation, useUpdateDisposalRequestApproversMutation, useUpdateDisposalRequestMutation, useUpdateDisposalRequestPropsMutation };
